import React from 'react';
import { GridOverlay } from '@material-ui/data-grid';
import { LinearProgressPurple } from '../Global';

export const LoadGrid = () => {
  return (
    <GridOverlay>
      <div
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <LinearProgressPurple />
      </div>
    </GridOverlay>
  );
};
