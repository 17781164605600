import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import PrintIcon from '@material-ui/icons/Print';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormGroup from '@material-ui/core/FormGroup';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  DataGrid,
  ColDef,
  GridOverlay,
  ValueFormatterParams,
  CellValue,
} from '@material-ui/data-grid';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

import ImpressaoFechamentoCaixa from '../../../components/ImpressaoFechamentoCaixa';
import { KeyboardDatePickerPurple } from '../../../components/Global';
import { LoadGrid } from '../../../components/LoadGrid';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtro: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(1) },
    root: {
      flexDirection: 'column',
      '& .ant-empty-img-1': {
        fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
      },
      '& .ant-empty-img-2': {
        fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
      },
      '& .ant-empty-img-3': {
        fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
      },
      '& .ant-empty-img-4': {
        fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
      },
      '& .ant-empty-img-5': {
        fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
        fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },
    table: {
      minWidth: 650,
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    button: {
      // color: blue[900],
      margin: 10,
    },
    input: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    title: {
      flex: '1 1 100%',
    },
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>Não foi encontrado dados</div>
    </GridOverlay>
  );
}

interface IPedidoVenda {
  id: number;
  id_pedido_venda_externo: number;
  idCliente: number | undefined;
  idEndereco: number | undefined;
  idTipoPagamento: number;
  count: string;
  idMotoboy: number;
  observacao: string;
  observacao_entrega: string;
  idCupomPromocional: number;
  idRecompensa: number;
  status: string;
  data: Date;
  tipo_entrega: string;
  valor_entrega: string;
  valor_total: string;
  valor_total_itens: string;
  valor_desconto: string;
  pr_desconto: string;
  cliente?: {
    nome: string;
    telefone: string;
  };
  tipoPagamento: {
    descricao: string;
  };
}

interface ITotalMotoboy {
  count: number;
  id: number;
  idMotoboy: number;
  nome: string;
  valor_total: string;
  valor_entrega: string;
}

interface ITotalMotoboyImpresso {
  valorTotal: number;
  data: ITotalMotoboy[];
}

export const TotalByMotoboys = () => {
  const [totalMotoboy, setTotalMotoboy] = useState<Array<ITotalMotoboy>>([]);
  const [
    totalMotoboyImpresso,
    setTotalMotoboyImpresso,
  ] = useState<ITotalMotoboyImpresso | null>();
  const [exibePedidos, setExibePedidos] = useState(false);
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idMotoboy, setIdMotoboy] = useState('');
  const [nome, setNome] = useState('');
  const [valorTotal, setValorTotal] = useState(0);
  const [load, setLoad] = useState(true);
  const [pedidoVendas, setPedidoVendas] = useState<Array<IPedidoVenda>>([]);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');
  const [imprimeTotalMotoboy, setImprimeTotalMotoboy] = useState(false);
  const [exibeMsgImprimir, setExibeMsgImprimir] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const token = localStorage.getItem('@PecaAquiDashboard:token');
  const { usuario } = useAuth();

  let valor;
  valor = 0;

  useEffect(() => {
    setLoad(true);
    api
      .get(
        `/pedidovendas/${
          usuario.idEstabelecimento
        }/fechamentocaixa/${moment(dataInicio).utc()?.toISOString()}&${moment(dataFim).utc()?.toISOString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        response.data.forEach((element) => {
          if (element.status !== 'C') {
            valor += Number(element.valor_total);
          }
        });

        setValorTotal(valor);

        setTotalMotoboy(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  }, [token, dataInicio, dataFim]);

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarErro(false);
  };

  const handleMontaObjetoPedido = (
    idMotoboy: string | CellValue,
    nome: string | CellValue,
  ): void => {
    if (idMotoboy && nome) {
      api
        .get(
          `/pedidovendas/${
            usuario.idEstabelecimento
          }/fechamentocaixamotoboy/${idMotoboy}&${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          setPedidoVendas(response.data);
          setIdMotoboy(idMotoboy?.toString());
          setNome(nome.toString());
          setExibePedidos(true);
          setLoad(false);
        })
        .catch((error) => {
          setSnackBarErro(true);
          setMsg(error.response.data.message);
        });
    }
  };

  const columns: ColDef[] = [
    { field: 'nome', headerName: 'Motoboy', width: 200 },
    { field: 'count', headerName: 'Quantidade', width: 130 },
    {
      field: 'valor_entrega',
      headerName: 'R$ Valor Entrega',
      width: 160,
      valueFormatter: (params: ValueFormatterParams) =>
        `R$ ${params.value?.toString().replace('.', ',')}`,
    },
    {
      field: 'valor_total',
      headerName: 'R$ Valor Total',
      width: 160,
      valueFormatter: (params: ValueFormatterParams) =>
        `R$ ${params.value?.toString().replace('.', ',')}`,
    },
    {
      field: 'idMotoboy',
      headerName: 'Ações',
      width: 150,
      sortable: false,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Button
            style={{ color: '#81259D' }}
            size="small"
            onClick={() =>
              handleMontaObjetoPedido(
                params?.getValue('idMotoboy'),
                params?.getValue('nome'),
              )}
          >
            Ver Pedidos
          </Button>
        </>
      ),
    },
  ];

  const handleDateChangeInicio = (date) => {
    setDataInicio(date);
  };

  const handleDateChangeFim = (date) => {
    setDataFim(date);
  };

  function CustomFooter() {
    return (
      <div
        style={{
          backgroundColor: '#81259D',
          color: 'white',
          borderRadius: '2px',
          textAlign: 'center',
        }}
      >
        <Typography>
          <Box fontSize={19}>
            Total: R${valorTotal.toFixed(2).replace('.', ',')}
          </Box>
        </Typography>
      </div>
    );
  }

  async function imprimir(): Promise<void> {
    setExibeMsgImprimir(true);
  }

  async function imprimirFechamento(): Promise<void> {
    try {
      const data = await { valorTotal, data: totalMotoboy };

      setExibeMsgImprimir(false);

      setImprimeTotalMotoboy(true);
      setTotalMotoboyImpresso(data);

      setImprimeTotalMotoboy(false);
      setTotalMotoboyImpresso(null);
    } catch (error) {}
  }

  return (
    <>
      {/* TELA */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Total por Motoboy
          </Typography>
        </Grid>
      </Grid>

      <Toolbar
        className={clsx(classes.filtro)}
        style={{
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
          marginBottom: '10px',
          marginTop: '24px',
        }}
      >
        <Typography className={classes.title} variant="h6" component="div">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePickerPurple
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              label="Data Início"
              value={dataInicio}
              onChange={handleDateChangeInicio}
              size="small"
              style={{ width: '25%', marginRight: '10px' }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePickerPurple
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              label="Data Fim"
              value={dataFim}
              onChange={handleDateChangeFim}
              size="small"
              style={{ width: '25%', marginLeft: '10px' }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Typography>
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          style={{
            width: '30%',
            backgroundColor: '#81259D',
            color: '#fff',
          }}
          onClick={() => imprimir()}
        >
          Imprimir
        </Button>
      </Toolbar>
      <div
        style={{
          height: 500,
          width: '100%',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        }}
      >
        <DataGrid
          rows={totalMotoboy}
          columns={columns}
          loading={load}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
            footer: CustomFooter,
            loadingOverlay: LoadGrid,
          }}
        />
      </div>
      {/* FIM TELA */}

      {/* EXIBE PEDIDOS */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={exibePedidos}
        onClose={() => setExibePedidos(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Pedidos por Motoboy {idMotoboy} {' - '} {nome}
        </DialogTitle>
        <FormGroup aria-label="position" row>
          <DialogContent>
            <Grid container spacing={3}>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Dessert (100g serving)</TableCell> */}
                      <StyledTableCell align="right">Pedido</StyledTableCell>
                      <StyledTableCell align="left">
                        Tipo Pagamento
                      </StyledTableCell>
                      <StyledTableCell align="center">Data</StyledTableCell>
                      <StyledTableCell align="right">
                        Valor Entrega
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Valor Total
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Obs. Entrega
                      </StyledTableCell>
                      <StyledTableCell align="left">Obs.</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pedidoVendas.map((pedidoVenda) => (
                      <StyledTableRow key={pedidoVenda.id}>
                        <StyledTableCell
                          component="th"
                          align="right"
                          scope="row"
                          style={{ width: '5%' }}
                        >
                          {pedidoVenda.id_pedido_venda_externo}
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ width: '5%' }}>
                          {pedidoVenda?.tipoPagamento?.descricao}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: '10%' }}
                        >
                          {new Date(pedidoVenda.data).toLocaleString('pt-BR')}
                        </StyledTableCell>
                        <StyledTableCell align="right" style={{ width: '15%' }}>
                          R$ {pedidoVenda.valor_entrega.replace('.', ',')}
                        </StyledTableCell>
                        <StyledTableCell align="right" style={{ width: '15%' }}>
                          R$ {pedidoVenda.valor_total.replace('.', ',')}
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ width: '30%' }}>
                          {pedidoVenda?.observacao_entrega}
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ width: '30%' }}>
                          {pedidoVenda?.observacao}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </DialogContent>
        </FormGroup>
        <DialogActions>
          <Button
            onClick={() => setExibePedidos(false)}
            variant="contained"
            autoFocus
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIM EXIBE PEDIDOS */}

      {imprimeTotalMotoboy && totalMotoboyImpresso && (
        <div style={{ marginTop: '200px' }}>
          <ImpressaoFechamentoCaixa totalMotoboy={totalMotoboyImpresso} />
        </div>
      )}

      {/* INICIO DESEJA IMPRIMIR */}
      {exibeMsgImprimir && (
        <Dialog
          open={exibeMsgImprimir}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setExibeMsgImprimir(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja imprimir?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExibeMsgImprimir(false)}
              variant="contained"
              color="secondary"
            >
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={() => imprimirFechamento()}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: '#4caf50',
                }}
              >
                Sim
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
