import api from '../../../services/api';

export const loadProducts = async (idEstablishment, token) => {
  try {
    const response = await api.get(`/produtos/${idEstablishment}/grid`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const getProductsAdditional = async (id) => {
  try {
    const response = await api.get(`/produtos/${id}/adicionais`);
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const getProductsSuggestion = async (id) => {
  try {
    const response = await api.get(`/produtos/${id}/sugestoes`);
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const addProduct = async (idEstablishment, data, token) => {
  try {
    const response = await api.post(`/produtos/${idEstablishment}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const editProduct = async (id, data, token) => {
  try {
    const response = await api.put(`/produtos/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const uploadImage = async (idProduct, data, idEstablishment, token) => {
  try {
    const response = await api.post(
      `/produtos/${idProduct}/estabelecimento/${idEstablishment}/uploadimage`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const deleteImage = async (id, token) => {
  try {
    const response = await api.delete(`/produtos/${id}/uploadimage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200 && response.status !== 204)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};
