import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { Box } from '@material-ui/core';

import { useAuth } from '../../hooks/auth';
import { telefoneMask } from '../../hooks/mask';

interface ImprimirPedidoProps {
  pedido: IPedidoVenda;
  vias: number;
}

interface IPedidoVenda {
  id: number;
  id_pedido_venda_externo: number;
  idCliente: number;
  idEndereco: number;
  idTipoPagamento: number;
  idMotoboy: number;
  nome: string;
  observacao: string;
  idCupomPromocional: number;
  idRecompensa: number;
  status: string;
  origem: string;
  data: Date;
  tipo_entrega: string;
  valor_entrega: string;
  valor_total: string;
  valor_total_itens: string;
  valor_desconto: string;
  valor_acrescimo: string;
  valor_troco: string;
  pr_desconto: string;
  observacao_entrega: string;
  cliente: {
    nome: string;
    telefone: string;
  };
  tipoPagamento: {
    descricao: string;
  };
  cupomPromocional: {
    nome: string;
  };
  recompensa: {
    descricao: string;
  };
  endereco: {
    rua: string;
    numero: number;
    complemento: string;
    ponto_referencia: string;
    idBairro: string;
    bairro_nome: string;
    cidade: {
      nome: string;
      valor_entrega: string;
    };
    bairro: {
      nome: string;
      valor_entrega: string;
    };
  };
  pedidoVendaItem: Array<IPedidoVendaItem>;
  pedidoVendaPizza: [
    {
      id: number;
      idBorda: number;
      idCarrinho: number;
      idTamanho: number;
      observacao: string;
      valor: string;
      valor_borda: string;
      tamanho: { id: number; descricao: string; qt_sabor: number };
      borda?: { id: number; descricao: string };
    },
  ];
}

interface IPedidoVendaItem {
  id: number;
  idProduto: number;
  observacao: string;
  quantidade: number;
  valor: string;
  idPedidoVendaPizza: number;
  produto: {
    nome: string;
    descricao: string;
    valor: string;
    codigoProduto: string;
    exibe_descricao_impressao: string;
  };
  pedidoVendaItemAdicional: Array<IPedidoVendaItemAdicional> | undefined;
  pedidoVendaItemOpcao: Array<IPedidoVendaItemOpcao> | undefined;
  pedidoVendaItemSugestao: Array<IPedidoVendaItemSugestao> | undefined;
}

interface IPedidoVendaItemAdicional {
  idProduto: number;
  idAdicional: number;
  valor: string;
  adicional: {
    descricao: string;
  };
}

interface IPedidoVendaItemSugestao {
  idProduto: number;
  idAdicional: number;
  valor: string;
  adicional: {
    descricao: string;
  };
}

interface IPedidoVendaItemOpcao {
  opcao: {
    descricao: string;
  };
  valor: string;
}

const ImpressaoPedido: React.FC<ImprimirPedidoProps> = ({ pedido, vias }) => {
  const { usuario } = useAuth();

  useEffect(() => {
    const mywindow = window.open('', '', 'width=10,height=10');

    mywindow?.document.write(
      `<div style="font-family:Arial;">
      ${document.getElementById('impressao')?.innerHTML}` || '' + '</div>',
    );

    mywindow?.document.close(); // necessary for IE >= 10
    if (vias === 2) {
      mywindow?.print();
    }
    mywindow?.print();
    mywindow?.close();
  });

  return (
    <div id="impressao">
      {/* {usuario?.estabelecimento?.imagem_url ? (
        <>
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <img
              alt="logo"
              src={usuario?.estabelecimento?.imagem_url}
              style={{
                maxHeight: '120px',
                maxWidth: '125px',
                height: '120px',
                width: '125px',
              }}
            />
          </div>
          <br />
        </>
      ) : ( */}
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '18px',
        }}
      >
        {usuario.estabelecimento.nome}
      </div>
      {/* )} */}
      {/* <br /> */}
      <div style={{ textAlign: 'center', fontSize: '15px' }}>
        {usuario.estabelecimento.endereco}
      </div>
      {/* <br /> */}
      <div style={{ textAlign: 'center', fontSize: '15px' }}>
        {telefoneMask(usuario.estabelecimento.celular)}
      </div>
      <hr />
      <div
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}
      >
        Pedido Nº {pedido?.id_pedido_venda_externo}
      </div>
      <div style={{ textAlign: 'center', fontSize: '14px' }}>
        Impresso em {new Date().toLocaleDateString('pt-BR')}{' '}
        {new Date().toLocaleTimeString('pt-BR')}
      </div>
      <hr />
      <>
        {pedido.cliente ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box>
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Cliente:
                </span>{' '}
                <span style={{ fontSize: '18px' }}>
                  {pedido?.cliente?.nome} -{' '}
                  {telefoneMask(pedido?.cliente?.telefone)}
                </span>
              </Box>
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        {pedido?.tipo_entrega === 'DEL' ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography component="div">
                <Box>
                  <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                    Endereço:
                  </span>{' '}
                  <span style={{ fontSize: '18px' }}>
                    {pedido?.observacao_entrega}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </>
        ) : (
          ''
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography component="div">
            <Box>
              <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Tipo de entrega:
              </span>{' '}
              <span style={{ fontSize: '18px' }}>
                {pedido?.tipo_entrega && pedido?.tipo_entrega === 'DEL'
                  ? 'Delivery'
                  : pedido?.tipo_entrega === 'REL'
                  ? 'Retirar no Estabelecimento'
                  : 'Consumir no Local'}
              </span>
            </Box>
          </Typography>
        </Grid>
        {pedido?.tipoPagamento ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box>
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Tipo pagamento:
                </span>{' '}
                <span style={{ fontSize: '18px' }}>
                  {pedido?.tipoPagamento?.descricao}
                </span>
              </Box>
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        {pedido?.cupomPromocional && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box>
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Cupom de desconto:
                </span>{' '}
                <span style={{ fontSize: '18px' }}>
                  {pedido?.cupomPromocional?.nome}
                </span>
              </Box>
            </Typography>
          </Grid>
        )}
        {pedido?.recompensa && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box>
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Recompensa:
                </span>{' '}
                <span style={{ fontSize: '18px' }}>
                  {pedido?.recompensa?.descricao}
                </span>
              </Box>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography component="div">
            <Box>
              <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Observação:
              </span>{' '}
              <span style={{ fontSize: '18px' }}>{pedido?.observacao}</span>
            </Box>
          </Typography>
        </Grid>
        <br />
        {pedido?.valor_troco ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box>
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Troco para quantos?
                </span>{' '}
                <span style={{ fontSize: '18px' }}>
                  {pedido?.valor_troco.replace('.', ',')}
                </span>
              </Box>
            </Typography>
          </Grid>
        ) : (
          ''
        )}
      </>
      <hr />
      <Table aria-label="simple table" style={{ fontSize: '18px' }}>
        <TableHead>
          <TableRow>
            <td align="left" style={{ fontWeight: 'bold', width: '50%' }}>
              Item
            </td>
            <td align="right" style={{ fontWeight: 'bold', width: '25%' }}>
              Unit.
            </td>
            <td align="right" style={{ fontWeight: 'bold', width: '25%' }}>
              Total
            </td>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            width: '100%',
          }}
        >
          {pedido.pedidoVendaItem.length > 0 ||
          pedido?.pedidoVendaPizza.length > 0 ? (
            <>
              {pedido.pedidoVendaItem.length > 0
                ? pedido?.pedidoVendaItem.map((pedidoVendaDetalheItem) => {
                    if (!pedidoVendaDetalheItem.idPedidoVendaPizza) {
                      return (
                        <>
                          <tr
                            style={{
                              height: '60px',
                              fontWeight: 'bold',
                            }}
                          >
                            {/* <span style={{ fontWeight: 'bold' }}> */}
                            <td
                              align="left"
                              style={{
                                width: '100%',
                              }}
                            >
                              <span>
                                <span style={{ fontSize: '20px' }}>
                                  {pedidoVendaDetalheItem.quantidade}
                                </span>
                                {' - '}
                                {pedidoVendaDetalheItem.produto.nome}
                                {pedidoVendaDetalheItem.produto
                                  .exibe_descricao_impressao === 'S' ? (
                                    <>
                                    <br />
                                    <small>
                                        {
                                        pedidoVendaDetalheItem.produto
                                          ?.descricao
                                      }
                                      </small>
                                  </>
                                ) : (
                                  ''
                                )}
                              </span>
                              {pedidoVendaDetalheItem &&
                              pedidoVendaDetalheItem.pedidoVendaItemOpcao &&
                              pedidoVendaDetalheItem.pedidoVendaItemOpcao
                                ?.length > 0 ? (
                                  <span>
                                  {pedidoVendaDetalheItem?.pedidoVendaItemOpcao?.map(
                                    (pedidoVendaItemOpcao) => (
                                      <>
                                        <br />
                                        <span style={{ fontSize: '16px' }}>
                                          {'= '}
                                          {pedidoVendaItemOpcao.opcao.descricao}
                                        </span>
                                      </>
                                    ),
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                              {pedidoVendaDetalheItem &&
                              pedidoVendaDetalheItem.pedidoVendaItemAdicional &&
                              pedidoVendaDetalheItem.pedidoVendaItemAdicional
                                ?.length > 0 ? (
                                  <span>
                                  {pedidoVendaDetalheItem?.pedidoVendaItemAdicional?.map(
                                    (pedidoVendaItemAd) => (
                                      <>
                                        <br />
                                        <span style={{ fontSize: '16px' }}>
                                          {' + '}
                                          {
                                            pedidoVendaItemAd.adicional
                                              .descricao
                                          }
                                        </span>
                                      </>
                                    ),
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                              {pedidoVendaDetalheItem.pedidoVendaItemSugestao &&
                              pedidoVendaDetalheItem.pedidoVendaItemSugestao
                                ?.length > 0 ? (
                                <>
                                    <br />
                                    <span style={{ fontSize: '16px' }}>
                                    Sugestão:{' '}
                                    {
                                      pedidoVendaDetalheItem
                                        .pedidoVendaItemSugestao[0].adicional
                                        .descricao
                                    }
                                  </span>
                                  </>
                              ) : (
                                ''
                              )}
                              {pedidoVendaDetalheItem.observacao ? (
                                <>
                                  <br />
                                  <span style={{ fontSize: '16px' }}>
                                    Observações:{' '}
                                    {pedidoVendaDetalheItem.observacao}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                            <td align="right" style={{ width: '100%' }}>
                              {pedidoVendaDetalheItem.produto.valor === '0.00'
                                ? '--'
                                : `${pedidoVendaDetalheItem.produto.valor.replace(
                                    '.',
                                    ',',
                                  )}`}
                            </td>
                            <td align="right" style={{ width: '100%' }}>
                              {pedidoVendaDetalheItem.valor.replace('.', ',')}{' '}
                            </td>
                          </tr>
                        </>
                      );
                    }
                  })
                : ''}
              {pedido.pedidoVendaPizza.length > 0
                ? pedido?.pedidoVendaPizza.map((pizzaPedidoVenda, index) => (
                  <>
                      <tr style={{ height: '60px', fontWeight: 'bold' }}>
                      <td align="left" style={{ width: '100%' }}>
                          <span style={{ fontSize: '20px' }}>1</span> - PIZZA{' '}
                          {pizzaPedidoVenda.tamanho.descricao}
                          {pedido &&
                            pedido?.pedidoVendaItem.map(
                              (produtoPedidoVenda) => {
                                if (
                                  produtoPedidoVenda.idPedidoVendaPizza ===
                                  pizzaPedidoVenda.id
                                ) {
                                  return (
                                    <>
                                      <br />
                                      <span style={{ fontSize: '16px' }}>
                                        {/* {produtoPedidoVenda.quantidade}/
                                              {
                                                pizzaPedidoVenda.tamanho
                                                  .qt_sabor
                                              }{' '} */}
                                        {' - '}
                                        {produtoPedidoVenda.produto.nome}
                                      </span>
                                    </>
                                  );
                                }
                              },
                            )}
                          {pizzaPedidoVenda.borda ? (
                          <>
                              <br />
                              <span style={{ fontSize: '16px' }}>
                              Borda: {pizzaPedidoVenda.borda.descricao}
                            </span>
                            </>
                          ) : (
                            ''
                          )}
                          {pizzaPedidoVenda.observacao ? (
                          <>
                              <br />{' '}
                              <span style={{ fontSize: '16px' }}>
                              Observações: {pizzaPedidoVenda.observacao}
                            </span>
                            </>
                          ) : (
                            ''
                          )}
                        </td>
                      <td align="right" style={{ width: '100%' }}>
                          {pizzaPedidoVenda.valor.replace('.', ',')}
                        </td>
                      <td align="right" style={{ width: '100%' }}>
                          {pizzaPedidoVenda.valor.replace('.', ',')}
                        </td>
                    </tr>
                    </>
                  ))
                : ''}
            </>
          ) : (
            ''
          )}
        </TableBody>
        <TableFooter>
          <tr>
            <td style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              <br />
              Valor do Itens
            </td>
            <td />
            <td
              align="right"
              style={{ width: '20%', color: 'rgba(0, 0, 0, 0.87)' }}
            >
              <br />

              {pedido?.valor_total_itens === null
                ? '0,00'
                : pedido?.valor_total_itens?.replace('.', ',')}
            </td>
          </tr>

          {Number(pedido?.valor_desconto) > 0 ? (
            <tr>
              <td style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Desconto</td>
              <td />
              <td
                align="right"
                style={{ width: '20%', color: 'rgba(0, 0, 0, 0.87)' }}
              >
                {pedido?.valor_desconto === null
                  ? '0,00'
                  : pedido?.valor_desconto?.replace('.', ',')}
              </td>
            </tr>
          ) : (
            ''
          )}
          {Number(pedido?.valor_acrescimo) > 0 ? (
            <tr>
              <td style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Acréscimo</td>
              <td />
              <td
                align="right"
                style={{ width: '20%', color: 'rgba(0, 0, 0, 0.87)' }}
              >
                {pedido?.valor_acrescimo === null
                  ? '0,00'
                  : pedido?.valor_acrescimo?.replace('.', ',')}
              </td>
            </tr>
          ) : (
            ''
          )}
          {/* {Number(pedido?.valor_desconto) > 0 &&
          Number(pedido?.valor_acrescimo) > 0 ? (
            <tr>
              <td style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                Valor (Itens - Desconto + Acréscimo)
              </td>
              <td />
              <td
                align="right"
                style={{ width: '20%', color: 'rgba(0, 0, 0, 0.87)' }}
              >
                {(
                  Number(pedido?.valor_total_itens) -
                  (Number(pedido?.valor_desconto) +
                    Number(pedido?.valor_acrescimo))
                )
                  .toFixed(2)
                  .replace('.', ',')}
              </td>
            </tr>
          ) : (
            ''
          )} */}
          <tr>
            <td style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Taxa de Entrega</td>
            <td />
            <td
              align="right"
              style={{ width: '20%', color: 'rgba(0, 0, 0, 0.87)' }}
            >
              {pedido?.valor_entrega?.replace('.', ',')}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'bold' }}>
              Total
            </td>
            <td />
            <td
              align="right"
              style={{
                width: '20%',
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 'bold',
              }}
            >
              {pedido?.valor_total?.replace('.', ',')}
            </td>
          </tr>
        </TableFooter>
      </Table>
      {/* </TableContainer>
      </Grid> */}

      {/* <Grid item xs={12} sm={12} md={4} lg={4}> */}
      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" fontSize="h4">
              {' '}
              Dados do cliente
            </Box>
          </Typography>
        </Grid> */}
      {/* {pedido?.cliente ? (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>Cliente:</span>{' '}
                {pedido?.cliente?.nome}
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>Tipo pagamento:</span>{' '}
                {pedido?.tipoPagamento.descricao}
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>Tipo de entrega:</span>{' '}
                {pedido?.tipo_entrega === 'D'
                  ? 'Delivery'
                  : 'Retirar no estabelecimento'}
              </Box>
            </Typography>
          </Grid>

          {pedido?.tipo_entrega === 'D' && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="div">
                  <Box fontSize={16}>
                    <span style={{ fontWeight: 'bold' }}>Cidade:</span>{' '}
                    {pedido?.endereco?.cidade?.nome}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="div">
                  <Box fontSize={16}>
                    <span style={{ fontWeight: 'bold' }}>Bairro:</span>{' '}
                    {pedido?.endereco?.bairro?.nome}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="div">
                  <Box fontSize={16}>
                    <span style={{ fontWeight: 'bold' }}>Endereço:</span>{' '}
                    {pedido?.endereco?.rua}, {pedido?.endereco?.numero}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="div">
                  <Box fontSize={16}>
                    <span style={{ fontWeight: 'bold' }}>Complemento:</span>{' '}
                    {pedido?.endereco?.complemento}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="div">
                  <Box fontSize={16}>
                    <span style={{ fontWeight: 'bold' }}>
                      Ponto de Referência:
                    </span>{' '}
                    {pedido?.endereco?.ponto_referencia}
                  </Box>
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>Celular:</span>{' '}
                {pedido?.cliente?.telefone}
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>Observação:</span>{' '}
                {pedido?.observacao}
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>Cupom de Desconto:</span>{' '}
                {pedido?.cupomPromocional?.nome}
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div">
              <Box fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>
                  Programa de Fidelidade:
                </span>{' '}
                {pedido?.recompensa?.descricao}
              </Box>
            </Typography>
          </Grid>
        </>
      ) : ( */}

      {/* )} */}
      {/* </Grid> */}
      <hr />
      <Typography component="div" style={{ textAlign: 'center' }}>
        <Box>
          <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
            www.pecaaquidelivery.com
          </span>
        </Box>
      </Typography>
    </div>
  );
};
export default ImpressaoPedido;
