import React, { useState, useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { DebounceInput } from 'react-debounce-input';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import {
  DataGrid,
  ColDef,
  ValueFormatterParams,
  CellValue,
} from '@material-ui/data-grid';
import { Form } from '@unform/web';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useStyles } from './styles';
import {
  loadProducts,
  getProductsAdditional,
  getProductsSuggestion,
  uploadImage,
  deleteImage,
  editProduct,
  addProduct,
} from './actions';
import { loadCategoriesSelect } from '../Categories/actions';
import { NoRows } from '../../../components/NoRows';
import {
  IAdicionais,
  IAdicionaisProd,
  IOpcoes,
  IOpcoesData,
  IOpcoesGrupoProd,
  IOpcoesProd,
  ISelectCategorias,
  ISelectSubcategorias,
  ISugestoes,
  ISugestoesProd,
  ITamanhos,
  ITamanhosProd,
} from './dtos';
import {
  ButtonNew,
  CheckboxPurple,
  SwitchPurple,
  TextFieldPurple,
} from '../../../components/Global';
import { LoadGrid } from '../../../components/LoadGrid';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      prefix="R$"
    />
  );
}

function CustomNoRowsOverlay() {
  return <NoRows />;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Products = () => {
  const [categorias, setCategorias] = useState<ISelectCategorias[]>();
  const [subcategorias, setSubcategorias] = useState<ISelectSubcategorias[]>();
  const [produtos, setProdutos] = useState([]);
  const [adicionaisProd, setAdicionaisProd] = useState<Array<IAdicionaisProd>>(
    [],
  );
  const [opcoesGrupoProd, setOpcoesGrupoProd] = useState<IOpcoesGrupoProd[]>(
    [],
  );
  const [opcoesProd, setOpcoesProd] = useState<IOpcoesProd[]>([]);
  const [opcoes, setOpcoes] = useState<IOpcoes[]>();
  const [sugestoesProd, setSugestoesProd] = useState<Array<ISugestoesProd>>([]);
  const [adicionais, setAdicionais] = useState<IAdicionais[]>();
  const [sugestoes, setSugestoes] = useState<ISugestoes[]>();
  const [tamanhosProd, setTamanhosProd] = useState<Array<ITamanhosProd>>([]);
  const [codigoProduto, setCodigoProduto] = useState('');
  const [tamanhos, setTamanhos] = useState<ITamanhos[]>();
  const [showNovo, setShowNovo] = useState(false);
  const [showEditar, setShowEditar] = useState(false);
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [valor, setValor] = useState('');
  const [valorCusto, setValorCusto] = useState('');
  const [descricao, setDescricao] = useState('');
  const [imagemUrl, setImagemUrl] = useState();
  const [ativoBool, setAtivoBool] = useState(true);
  const [ativo, setAtivo] = useState('S');
  const [exibeDescImpressaoBool, setExibeDescImpressaoBool] = useState(false);
  const [exibeDescImpressao, setExibeDescImpressao] = useState('N');
  const [idCategoria, setIdCategoria] = useState('');
  const [idSubcategoria, setIdSubcategoria] = useState('');
  const [filtroProduto, setFiltroProduto] = useState('');
  const [data, setData] = useState(new FormData());

  const [aba, setAba] = useState('1');
  const [exibeMsg, setExibeMsg] = useState(false);
  const [load, setLoad] = useState(false);
  const [carregandoBotao, setCarregandoBotao] = useState(false);
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const token = localStorage.getItem('@PecaAquiDashboard:token');

  const { usuario } = useAuth();

  useEffect(() => {
    setLoad(true);

    async function init() {
      try {
        const response = await loadProducts(usuario.idEstabelecimento, token);
        if (response?.data) {
          setProdutos(response.data);
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
        setMsg('Algum erro ocorreu, tente novamente mais tarde!');
        setSnackBarErro(true);
      }
    }

    init();
  }, [usuario.idEstabelecimento, token]);

  const handleAtualizar = async () => {
    setLoad(true);
    setProdutos([]);
    try {
      const response = await loadProducts(usuario.idEstabelecimento, token);
      if (response?.data) {
        setProdutos(response.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleToggle = (data: IOpcoesData) => () => {
    const newOpcoesGrupoProd = [...opcoesGrupoProd];

    const currentIndexOpcaoGrupo = opcoesGrupoProd.findIndex(
      (element) => element.idOpcaoGrupo === data.idOpcaoGrupo,
    );

    if (currentIndexOpcaoGrupo === -1) {
      newOpcoesGrupoProd.push({
        id: 0,
        idOpcaoGrupo: data.idOpcaoGrupo,
        idProduto: Number(id),
      });
      setOpcoesGrupoProd(newOpcoesGrupoProd);
    }

    const newOpcoesProd = [...opcoesProd];

    const currentIndexOpcao = opcoesProd.findIndex(
      (element) => element.idOpcao === data.id,
    );

    if (currentIndexOpcao === -1) {
      newOpcoesProd.push({
        idOpcao: data.id,
        valor: data.valor,
        idOpcaoGrupo: data.idOpcaoGrupo,
      });
    } else {
      newOpcoesProd.splice(currentIndexOpcao, 1);
    }
    setOpcoesProd(newOpcoesProd);
  };

  const setaCheckedSugestoes = (data): void => {
    sugestoesProd.forEach((element) => {
      const sugestoesChecked = data?.find(
        (elementSugestoes) => elementSugestoes.id === element.id,
      );

      if (sugestoesChecked) {
        sugestoesChecked.checked = true;
      }
    });

    setSugestoes(data);
  };

  const setaCheckedAdicionais = (data): void => {
    adicionaisProd.forEach((element) => {
      const adicionaisChecked = data?.find(
        (elementAdicionais) => elementAdicionais.id === element.id,
      );

      if (adicionaisChecked) {
        adicionaisChecked.checked = true;
      }
    });

    setAdicionais(data);
  };

  const setaCheckedTamanhos = (data): void => {
    tamanhosProd.forEach((element) => {
      const tamanhosChecked = data?.find(
        (elementTamanhos) => elementTamanhos.id === Number(element.id),
      );

      if (tamanhosChecked) {
        tamanhosChecked.checked = true;
        tamanhosChecked.valor_padrao =
          element.valor !== null ? element.valor : null;
      }
    });

    setTamanhos(data);
  };

  const handleShowEditar = async (produto): Promise<void> => {
    setAba('1');

    try {
      const responseCategories = await loadCategoriesSelect(
        usuario.idEstabelecimento,
        token,
      );
      setCategorias(responseCategories.data);

      const responseSubcategorias = await api.get(
        `/subcategorias/categoria/${produto.idCategoria}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setSubcategorias(responseSubcategorias.data);

      setId(produto.id);
      setNome(produto.nome);
      setValor(produto.valor);
      setDescricao(produto.descricao);
      setImagemUrl(produto.imagem_url);
      setAtivo(produto.ativo);
      setExibeDescImpressao(produto.exibe_descricao_impressao);
      setCodigoProduto(produto.codigoProduto);
      setIdCategoria(produto.idCategoria);
      setIdSubcategoria(produto.idSubcategoria);
      setValorCusto(produto.valorCusto);

      if (produto.ativo === 'S') {
        setAtivoBool(true);
      } else {
        setAtivoBool(false);
      }

      if (produto.exibe_descricao_impressao === 'S') {
        setExibeDescImpressaoBool(true);
      } else {
        setExibeDescImpressaoBool(false);
      }

      setShowEditar(true);

      const responseProdAdd = await getProductsAdditional(produto.id);
      responseProdAdd.data.forEach((element) => {
        adicionaisProd.push({ id: element.idAdicional });
      });

      const responseAdd = await api.get(
        `/adicionais/${usuario.idEstabelecimento}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setaCheckedAdicionais(responseAdd.data);

      const responseProdSug = await getProductsSuggestion(produto.id);
      responseProdSug.data.forEach((element) => {
        sugestoesProd.push({ id: element.idAdicional });
      });
      api
        .get(`/adicionais/${usuario.idEstabelecimento}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((responseSug) => {
          setaCheckedSugestoes(responseSug.data);
        })
        .catch((error) => {
          setSnackBarErro(true);
          setMsg(error.responseSug.data.message);
        });

      api
        .get(`/produtos/${produto.id}/tamanhos`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((responseProdTam) => {
          responseProdTam.data.forEach((element) => {
            tamanhosProd.push({
              id: String(element.idTamanho),
              valor: element.valor !== null ? element.valor : null,
            });
          });
          api
            .get(`/tamanhos/${usuario.idEstabelecimento}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((responseTamanhos) => {
              setaCheckedTamanhos(responseTamanhos.data);
            })
            .catch((error) => {
              setSnackBarErro(true);
              setMsg(error.responseTamanhos.data.message);
            });
        });

      api
        .get(`/opcoes/${usuario.idEstabelecimento}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setOpcoes(response.data);
        })
        .catch((error) => {
          setSnackBarErro(true);
          setMsg(error.response.data.message);
        });

      api
        .get(`/produtos/${produto.id}/opcoesgrupo`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((responseProdOpcoesGrupo) => {
          responseProdOpcoesGrupo.data.forEach(async (elementAdd) => {
            setOpcoesGrupoProd((old) => [
              ...old,
              {
                idOpcaoGrupo: elementAdd.idOpcaoGrupo,
                idProduto: elementAdd.idProduto,
                id: elementAdd.id,
              },
            ]);

            elementAdd.produtoOpcao.forEach(async (elementItem) => {
              setOpcoesProd((old) => [
                ...old,
                {
                  idOpcao: elementItem.idOpcao,
                  valor: elementItem.valor,
                  idOpcaoGrupo: elementItem.idOpcaoGrupo,
                },
              ]);
            });
          });
        });
    } catch (error) {
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleCloseEditar = (): void => {
    setShowEditar(false);
    setId('');
    setNome('');
    setValor('');
    setCodigoProduto('');
    setDescricao('');
    setImagemUrl('');
    setAtivo('S');
    setAtivoBool(true);
    setExibeDescImpressao('N');
    setExibeDescImpressaoBool(false);
    setIdCategoria('');
    setIdSubcategoria('');
    setValorCusto('');
    adicionaisProd.splice(0, Number.MAX_VALUE);
    setAdicionais([]);
    setSugestoes([]);
    tamanhosProd.splice(0, Number.MAX_VALUE);
    setTamanhos([]);
    setOpcoes([]);
    opcoesGrupoProd.splice(0, Number.MAX_VALUE);
    opcoesProd.splice(0, Number.MAX_VALUE);
    sugestoesProd.splice(0, Number.MAX_VALUE);
    setData(new FormData());
  };

  const handleEditar = async () => {
    setCarregandoBotao(true);
    if (tamanhosProd) {
      tamanhosProd.forEach((element) => {
        const tamanhoFind = tamanhos?.find((a) => a.id === Number(element.id));

        element.valor = tamanhoFind?.valor_padrao.replace(',', '.');
      });
    }

    const produto = {
      nome,
      valor: valor.replace(',', '.'),
      descricao,
      ativo,
      codigoProduto,
      idCategoria,
      idSubcategoria,
      exibe_descricao_impressao: exibeDescImpressao,
      adicionais: adicionaisProd,
      tamanhos: tamanhosProd,
      valor_custo: valorCusto.replace(',', '.'),
      opcoesGrupo: opcoesGrupoProd,
      opcoes: opcoesProd,
      sugestoes: sugestoesProd,
    };

    try {
      await editProduct(id, produto, token);

      if (!imagemUrl) {
        await deleteImage(id, token);
      } else if (imagemUrl?.indexOf('pecaaquiimage') === -1)
        await uploadImage(id, data, usuario.idEstabelecimento, token);

      setCarregandoBotao(false);
      handleCloseEditar();

      setSnackBarSucesso(true);
      setMsg('Cadastro editado com sucesso!');
      handleAtualizar();
    } catch (error) {
      setCarregandoBotao(false);
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleShowNovo = (): void => {
    setAba('1');
    api
      .get(`/categorias/${usuario.idEstabelecimento}/select`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCategorias(response.data);

        setShowNovo(true);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
    api
      .get(`/tamanhos/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTamanhos(response.data);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });

    api
      .get(`/adicionais/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAdicionais(response.data);
      })
      .catch((error) => {
        setMsg(error.response.data.message);
        setSnackBarErro(true);
      });

    api
      .get(`/adicionais/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSugestoes(response.data);
      })
      .catch((error) => {
        setMsg(error.response.data.message);
        setSnackBarErro(true);
      });

    api
      .get(`/opcoes/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setOpcoes(response.data);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleCloseNovo = (): void => {
    setShowNovo(false);

    setId('');
    setNome('');
    setValor('');
    setDescricao('');
    setImagemUrl('');
    setAtivo('S');
    setAtivoBool(true);
    setExibeDescImpressao('N');
    setExibeDescImpressaoBool(false);
    setCodigoProduto('');
    setIdCategoria('');
    setIdSubcategoria('');
    setValorCusto('');
    adicionaisProd.splice(0, Number.MAX_VALUE);
    setAdicionais([]);
    setSugestoes([]);
    tamanhosProd.splice(0, Number.MAX_VALUE);
    setTamanhos([]);
    setOpcoes([]);
    opcoesGrupoProd.splice(0, Number.MAX_VALUE);
    opcoesProd.splice(0, Number.MAX_VALUE);
    sugestoesProd.splice(0, Number.MAX_VALUE);
    setData(new FormData());
  };

  const handleNovo = async () => {
    setCarregandoBotao(true);
    if (tamanhosProd) {
      tamanhosProd.forEach((element) => {
        const tamanhoFind = tamanhos?.find((a) => a.id === Number(element.id));

        element.valor = tamanhoFind?.valor_padrao.replace(',', '.');
      });
    }

    if (opcoesProd) {
      opcoesProd.forEach((element) => {
        opcoes?.forEach((elementOpcoes) => {
          const opcaoFind = elementOpcoes.opcao.find(
            (a) => a.id === element.idOpcao,
          );

          if (opcaoFind) {
            element.valor = opcaoFind?.valor;
          }
        });
      });
    }

    const produto = {
      nome,
      valor: valor.replace(',', '.'),
      descricao,
      ativo,
      codigoProduto,
      idCategoria,
      idSubcategoria,
      exibe_descricao_impressao: exibeDescImpressao,
      valor_custo: valorCusto.replace(',', '.'),
      adicionais: adicionaisProd,
      tamanhos: tamanhosProd,
      opcoesGrupo: opcoesGrupoProd,
      opcoes: opcoesProd,
      sugestoes: sugestoesProd,
    };

    try {
      const response = await addProduct(
        usuario.idEstabelecimento,
        produto,
        token,
      );

      if (imagemUrl) {
        await uploadImage(
          response.data.id,
          data,
          usuario.idEstabelecimento,
          token,
        );
      }

      setCarregandoBotao(false);
      handleCloseNovo();
      setSnackBarSucesso(true);
      setMsg('Cadastro realizado com sucesso!');
      handleAtualizar();
    } catch (error) {
      setCarregandoBotao(false);
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleShowMsg = (idProduto: string | undefined | CellValue): void => {
    if (idProduto) {
      setId(idProduto?.toString());
      setExibeMsg(true);
    }
  };

  const handleBuscaProduto = (
    idProduto: string | undefined | CellValue,
  ): void => {
    api
      .get(`/produtos/edit/${idProduto}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const produto = {
          id: response.data.id,
          nome: response.data.nome,
          valor: response.data.valor,
          codigoProduto: response.data.codigoProduto,
          descricao: response.data.descricao,
          imagem_url: response.data.imagem_url,
          ativo: response.data.ativo,
          idCategoria: response.data.idCategoria,
          idSubcategoria: response.data.idSubcategoria,
          exibe_descricao_impressao: response.data.exibe_descricao_impressao,
          valorCusto: response.data.valor_custo,
        };

        handleShowEditar(produto);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarSucesso(false);
    setSnackBarErro(false);
  };
  const handleExcluir = (): void => {
    setCarregandoBotao(true);
    api
      .delete(`/produtos/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setCarregandoBotao(false);
        setExibeMsg(false);
        setSnackBarSucesso(true);
        setMsg('Produto excluido com sucesso!');
        handleAtualizar();
      })
      .catch((error) => {
        setCarregandoBotao(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  async function handleAtivo(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.value === 'S') {
      setAtivo('N');
      setAtivoBool(false);
    } else {
      setAtivo('S');
      setAtivoBool(true);
    }
  }

  async function handleExibeDescImp(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.value === 'S') {
      setExibeDescImpressao('N');
      setExibeDescImpressaoBool(false);
    } else {
      setExibeDescImpressao('S');
      setExibeDescImpressaoBool(true);
    }
  }
  async function handleImageChange(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.files) {
      const reader = new FileReader();
      const selectedFile = e.target.files[0];

      reader.onloadend = () => {
        setImagemUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      data.delete('file');
      data.append('file', selectedFile);
    }
  }

  async function handleSugestoes(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.checked) {
      sugestoesProd.push({ id: Number(e.target.value) });

      const sugestoesChecked = sugestoes?.find(
        (element) => String(element.id) === e.target.value,
      );

      const adicionalMap = sugestoes?.map((sugestao) => {
        if (sugestao.id === sugestoesChecked?.id) {
          sugestao.checked = true;
          return sugestao;
        }

        return sugestao;
      });

      if (adicionalMap !== undefined) {
        setSugestoes(adicionalMap);
      }
    } else {
      const retIndexOf = sugestoesProd.findIndex(
        (element) => element.id === Number(e.target.value),
      );
      sugestoesProd.splice(retIndexOf, 1);

      const sugestoesChecked = sugestoes?.find(
        (element) => String(element.id) === e.target.value,
      );

      const adicionalMap = sugestoes?.map((sugestao) => {
        if (sugestao.id === sugestoesChecked?.id) {
          sugestao.checked = false;
          return sugestao;
        }

        return sugestao;
      });

      if (adicionalMap !== undefined) {
        setSugestoes(adicionalMap);
      }
    }
  }

  async function handleAdicionais(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.checked) {
      adicionaisProd.push({ id: Number(e.target.value) });

      const adicionaisChecked = adicionais?.find(
        (element) => String(element.id) === e.target.value,
      );

      const adicionalMap = adicionais?.map((adicional) => {
        if (adicional.id === adicionaisChecked?.id) {
          adicional.checked = true;
          return adicional;
        }

        return adicional;
      });

      if (adicionalMap !== undefined) {
        setAdicionais(adicionalMap);
      }
    } else {
      const retIndexOf = adicionaisProd.findIndex(
        (element) => element.id === Number(e.target.value),
      );
      adicionaisProd.splice(retIndexOf, 1);

      const adicionaisChecked = adicionais?.find(
        (element) => String(element.id) === e.target.value,
      );

      const adicionalMap = adicionais?.map((adicional) => {
        if (adicional.id === adicionaisChecked?.id) {
          adicional.checked = false;
          return adicional;
        }

        return adicional;
      });

      if (adicionalMap !== undefined) {
        setAdicionais(adicionalMap);
      }
    }
  }

  async function handleValorPersonalizado(
    valorPersonalizado: string,
    idTamanho,
  ) {
    if (tamanhos) {
      const tamanhoMap = tamanhos?.map((element) => {
        if (element.id === idTamanho) {
          element.valor_padrao = valorPersonalizado;
          return element;
        }
        return element;
      });

      if (tamanhoMap !== undefined) {
        setTamanhos(tamanhoMap);
      }
    }

    if (tamanhosProd) {
      tamanhosProd.forEach((element) => {
        const tamanhoFind = tamanhos?.find((a) => a.id === Number(element.id));

        if (tamanhoFind?.id === Number(element.id)) {
          element.valor = valorPersonalizado;
        }
      });
    }
  }

  async function handleTamanhos(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.checked) {
      tamanhosProd.push({ id: e.target.value });

      const tamanhosChecked = tamanhos?.find(
        (element) => String(element.id) === e.target.value,
      );

      const tamanhoMap = tamanhos?.map((tamanho) => {
        if (tamanho.id === tamanhosChecked?.id) {
          tamanho.checked = true;
          return tamanho;
        }

        return tamanho;
      });

      if (tamanhoMap !== undefined) {
        setTamanhos(tamanhoMap);
      }
    } else {
      const retIndexOf = tamanhosProd.findIndex(
        (element) => element.id === e.target.value,
      );
      tamanhosProd.splice(retIndexOf, 1);

      const tamanhosChecked = tamanhos?.find(
        (element) => String(element.id) === e.target.value,
      );

      const tamanhoMap = tamanhos?.map((tamanho) => {
        if (tamanho.id === tamanhosChecked?.id) {
          tamanho.checked = false;
          return tamanho;
        }

        return tamanho;
      });

      if (tamanhoMap !== undefined) {
        setTamanhos(tamanhoMap);
      }
    }
  }
  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Ações',
      width: 150,
      sortable: false,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Button
            size="small"
            onClick={() => handleBuscaProduto(params?.getValue('id'))}
          >
            <EditIcon />
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={() => handleShowMsg(params?.getValue('id'))}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
    { field: 'codigoProduto', headerName: 'Cód.', width: 80 },
    { field: 'nome', headerName: 'Nome', width: 350 },
    { field: 'descricao_categoria', headerName: 'Categoria', width: 150 },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 90,
      valueFormatter: (params: ValueFormatterParams) =>
        `R$ ${params.value?.toString().replace('.', ',')}`,
    },
    {
      field: 'ativo',
      headerName: 'Ativo',
      width: 90,
      valueFormatter: (params: ValueFormatterParams) =>
        params.value === 'S' ? 'Sim' : 'Não',
    },
  ];

  const handleFiltroProduto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoad(true);

    setFiltroProduto(e.target.value.trim());

    if (e.target.value.trim() === '') {
      api
        .get(`/produtos/${usuario.idEstabelecimento}/grid`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setProdutos(response.data);
          setLoad(false);
        })
        .catch((error) => {
          setLoad(false);
          setSnackBarErro(true);
          setMsg(error.response.data.message);
        });
    } else {
      api
        .get(
          `produtos/${usuario.idEstabelecimento}/filtrovenda/${e.target.value}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          setProdutos(response.data);

          setLoad(false);
        })
        .catch((error) => {
          setLoad(false);
          setSnackBarErro(true);
          setMsg(error.response.data.message);
        });
    }
  };

  const handleBuscaSubcategoria = (idCategoria: string) => {
    setSubcategorias([]);
    setIdSubcategoria('');

    api
      .get(`/subcategorias/categoria/${idCategoria}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSubcategorias(response.data);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleChangeAba = (event: React.ChangeEvent<{}>, newValue: string) => {
    setAba(newValue);
  };

  const handleAtualizaValorOpcao = (idItem, valorPersonalizado) => {
    opcoesProd.find((element) => {
      if (element.idOpcao === idItem) {
        element.valor = valorPersonalizado;
      }
    });
  };

  return (
    <>
      {/* TELA */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Produtos
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'right' }}>
          <ButtonNew
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleShowNovo}
          >
            Novo
          </ButtonNew>
        </Grid>
      </Grid>
      <Toolbar
        className={clsx(classes.filtro)}
        style={{
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
          marginBottom: '10px',
          marginTop: '24px',
        }}
      >
        <Typography className={classes.title} variant="h6" component="div">
          <DebounceInput
            onChange={handleFiltroProduto}
            value={filtroProduto}
            debounceTimeout={300}
            minLength={1}
            placeholder="Pesquise pelo código ou nome"
            style={{
              marginBottom: '5px',
              width: '100%',
              fontSize: '16px',
            }}
          />
        </Typography>
      </Toolbar>
      <div
        style={{
          height: 500,
          width: '100%',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        }}
      >
        <DataGrid
          rows={produtos}
          columns={columns}
          loading={load}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: LoadGrid,
          }}
        />
      </div>
      {/* FIM TELA */}

      {/* NOVO PRODUTO */}
      <Dialog
        fullScreen={fullScreen}
        open={showNovo}
        onClose={handleCloseNovo}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleNovo}>
          <DialogTitle className={classes.headerDialog}>
            Novo Produto
          </DialogTitle>
          <FormGroup aria-label="position" row>
            <DialogContent>
              <TabContext value={aba}>
                <AppBar position="static" color="default">
                  <TabList
                    onChange={handleChangeAba}
                    aria-label="simple tabs example"
                    centered
                    variant="scrollable"
                    scrollButtons="on"
                  >
                    <Tab label="Geral" value="1" />
                    <Tab label="Tamanhos" value="2" />
                    <Tab label="Adicionais" value="3" />
                    <Tab label="Opções" value="4" />
                    <Tab label="Sugestões" value="5" />
                  </TabList>
                </AppBar>
                <TabPanel value="1">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        required
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        label="Nome"
                        fullWidth
                        autoComplete="nome_produto"
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        label="Descrição"
                        fullWidth
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        required
                        id="standard-select-currency"
                        select
                        fullWidth
                        label="Categoria"
                        value={idCategoria}
                        onChange={(e) => {
                          setIdCategoria(e.target.value);
                          handleBuscaSubcategoria(e.target.value);
                        }}
                        helperText="Selecione uma categoria"
                        variant="standard"
                        size="small"
                      >
                        {categorias &&
                          categorias.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.descricao}
                            </MenuItem>
                          ))}
                      </TextFieldPurple>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        id="standard-select-currency"
                        select
                        fullWidth
                        label="Subcategoria"
                        value={idSubcategoria}
                        onChange={(e) => setIdSubcategoria(e.target.value)}
                        helperText="Selecione uma subcategoria"
                        variant="standard"
                        size="small"
                      >
                        {subcategorias &&
                          subcategorias.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.descricao}
                            </MenuItem>
                          ))}
                      </TextFieldPurple>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        fullWidth
                        size="small"
                        label="Valor"
                        value={valor}
                        onChange={(event) => {
                          setValor(event.target.value);
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        fullWidth
                        size="small"
                        label="Valor Custo"
                        value={valorCusto}
                        onChange={(event) => {
                          setValorCusto(event.target.value);
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        value={codigoProduto}
                        onChange={(e) => setCodigoProduto(e.target.value)}
                        label="Código Produto(Caso Tenha)"
                        fullWidth
                        autoComplete="codigo_produto"
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControlLabel
                        value="start"
                        control={(
                          <SwitchPurple
                            checked={ativoBool === true}
                            onChange={handleAtivo}
                            color="primary"
                            value={ativo}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        )}
                        label="Ativo:"
                        labelPlacement="start"
                      />
                      <small>
                        {' '}
                        Quando marcado seu produto estará visível ao público
                        geral.
                      </small>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControlLabel
                        value="start"
                        control={(
                          <SwitchPurple
                            checked={exibeDescImpressaoBool === true}
                            onChange={handleExibeDescImp}
                            color="primary"
                            value={exibeDescImpressao}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        )}
                        label="Exibe descrição na comanda:"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <label htmlFor="contained-button-file">
                          <Fab component="span" className={classes.button}>
                            <AddPhotoAlternateIcon />
                          </Fab>
                        </label>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleImageChange}
                        />
                      </Grid>
                      <img src={imagemUrl} alt="" width={100} height={100} />
                      <div>
                        <Button
                          variant="outlined"
                          component="label"
                          color="secondary"
                          onClick={() => {
                            setImagemUrl('');
                            setData(new FormData());
                          }}
                        >
                          Remover imagem
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={3}>
                    {tamanhos &&
                      tamanhos.map((tamanho) => (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <CheckboxPurple
                            value={tamanho.id}
                            checked={tamanho.checked}
                            onChange={handleTamanhos}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />{' '}
                          {tamanho.descricao}
                          <Box mt={1}>
                            <TextFieldPurple
                              fullWidth
                              size="small"
                              label="Valor"
                              value={tamanho?.valor_padrao}
                              onChange={(e) =>
                                handleValorPersonalizado(
                                  e.target.value,
                                  tamanho.id,
                                )}
                              InputProps={{
                                inputComponent: NumberFormatCustom as any,
                              }}
                            />
                          </Box>
                        </Grid>
                      ))}{' '}
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  <Grid container spacing={3}>
                    {adicionais &&
                      adicionais.map((adicional) => (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <CheckboxPurple
                            value={adicional.id}
                            checked={adicional.checked}
                            onChange={handleAdicionais}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />{' '}
                          {adicional.descricao}{' '}
                          {adicional.valor_padrao > 0
                            ? `( R$ ${String(adicional.valor_padrao).replace(
                                '.',
                                ',',
                              )} )`
                            : ''}
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel value="4">
                  <Grid container spacing={3} style={{ display: 'block' }}>
                    <List>
                      {opcoes &&
                        opcoes.map((opcao) => {
                          const labelId = `checkbox-list-label-${opcao.id}`;
                          return (
                            <div
                              style={{
                                marginBottom: '10px',
                                boxShadow: '#81259D 0px 0px 15px',
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{ padding: '15px' }}
                              >
                                <h4>{opcao.descricao}</h4>
                                {opcao.opcao.map((opcaoItem) => (
                                  <ListItem
                                    key={opcaoItem.id}
                                    role={undefined}
                                    dense
                                    button
                                    onClick={handleToggle(opcaoItem)}
                                  >
                                    <ListItemIcon>
                                      <CheckboxPurple
                                        edge="start"
                                        checked={
                                          opcoesProd.findIndex(
                                            (element) =>
                                              element.idOpcao === opcaoItem.id,
                                          ) >= 0
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={labelId}
                                      primary={opcaoItem.descricao}
                                    />
                                    <ListItemSecondaryAction>
                                      {/* <TextFieldPurple
                                        fullWidth
                                        size="small"
                                        label="Valor"
                                        value={opcaoItem.valor}
                                        onBlur={(e) => {
                                          opcaoItem.valor = e.target.value;
                                        }}
                                        InputProps={{
                                          inputComponent: NumberFormatCustom as any,
                                        }}
                                      /> */}
                                      <CurrencyTextField
                                        variant="standard"
                                        fullWidth
                                        size="small"
                                        value={opcaoItem.valor}
                                        label="Valor"
                                        currencySymbol="R$"
                                        outputFormat="string"
                                        onChange={(event, value) => {
                                          opcaoItem.valor = value;
                                        }}
                                        maximumValue={999999.99}
                                      />
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                ))}
                              </Grid>
                            </div>
                          );
                        })}
                    </List>
                  </Grid>
                </TabPanel>
                <TabPanel value="5">
                  <Grid container spacing={3}>
                    {sugestoes &&
                      sugestoes.map((sugestao) => (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <CheckboxPurple
                            value={sugestao.id}
                            checked={sugestao.checked}
                            onChange={handleSugestoes}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />{' '}
                          {sugestao.descricao}{' '}
                          {sugestao.valor_padrao > 0
                            ? `( R$ ${String(sugestao.valor_padrao).replace(
                                '.',
                                ',',
                              )} )`
                            : ''}
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
              </TabContext>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={handleCloseNovo} variant="contained">
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Salvar
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      {/* FIM NOVO PRODUTO */}

      {/* EDITAR PRODUTO */}
      <Dialog
        fullScreen={fullScreen}
        open={showEditar}
        onClose={handleCloseEditar}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleEditar}>
          <DialogTitle id="form-dialog-title">Editar Produto</DialogTitle>
          <FormGroup aria-label="position" row>
            <DialogContent>
              <TabContext value={aba}>
                <AppBar position="static" color="default">
                  <TabList
                    onChange={handleChangeAba}
                    aria-label="simple tabs example"
                    centered
                    variant="scrollable"
                    scrollButtons="on"
                  >
                    <Tab label="Geral" value="1" />
                    <Tab label="Tamanhos" value="2" />
                    <Tab label="Adicionais" value="3" />
                    <Tab label="Opções" value="4" />
                    <Tab label="Sugestões" value="5" />
                  </TabList>
                </AppBar>
                <TabPanel value="1">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        required
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        label="Nome"
                        fullWidth
                        autoComplete="nome_produto"
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        label="Descrição"
                        fullWidth
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        required
                        id="standard-select-currency"
                        select
                        fullWidth
                        label="Categoria"
                        value={idCategoria}
                        onChange={(e) => {
                          setIdCategoria(e.target.value);
                          handleBuscaSubcategoria(e.target.value);
                        }}
                        helperText="Selecione uma categoria"
                        variant="standard"
                        size="small"
                      >
                        {categorias &&
                          categorias.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.descricao}
                            </MenuItem>
                          ))}
                      </TextFieldPurple>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        id="standard-select-currency"
                        select
                        fullWidth
                        label="Subcategoria"
                        value={idSubcategoria}
                        onChange={(e) => setIdSubcategoria(e.target.value)}
                        helperText="Selecione uma subcategoria"
                        variant="standard"
                        size="small"
                      >
                        {subcategorias &&
                          subcategorias.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.descricao}
                            </MenuItem>
                          ))}
                      </TextFieldPurple>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        fullWidth
                        size="small"
                        label="Valor"
                        value={valor}
                        onChange={(event) => {
                          setValor(event.target.value);
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        fullWidth
                        size="small"
                        label="Valor Custo"
                        value={valorCusto}
                        onChange={(event) => {
                          setValorCusto(event.target.value);
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        value={codigoProduto}
                        onChange={(e) => setCodigoProduto(e.target.value)}
                        label="Código Produto(Caso Tenha)"
                        fullWidth
                        autoComplete="codigo_produto"
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControlLabel
                        value="start"
                        control={(
                          <SwitchPurple
                            checked={ativoBool === true}
                            onChange={handleAtivo}
                            value={ativo}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        )}
                        label="Ativo:"
                        labelPlacement="start"
                      />
                      <small>
                        {' '}
                        Quando marcado seu produto estará visível ao público
                        geral.
                      </small>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControlLabel
                        value="start"
                        control={(
                          <SwitchPurple
                            checked={exibeDescImpressaoBool === true}
                            onChange={handleExibeDescImp}
                            value={exibeDescImpressao}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        )}
                        label="Exibe descrição na comanda:"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <label htmlFor="contained-button-file">
                          <Fab component="span" className={classes.button}>
                            <AddPhotoAlternateIcon />
                          </Fab>
                        </label>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleImageChange}
                        />
                      </Grid>
                      <img src={imagemUrl} alt="" width={100} height={100} />
                      <div>
                        <Button
                          variant="outlined"
                          component="label"
                          color="secondary"
                          onClick={() => {
                            setImagemUrl('');
                            setData(new FormData());
                          }}
                        >
                          Remover imagem
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={3}>
                    {tamanhos &&
                      tamanhos.map((tamanho) => (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <CheckboxPurple
                            color="primary"
                            value={tamanho.id}
                            checked={tamanho.checked}
                            onChange={handleTamanhos}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />{' '}
                          {tamanho.descricao}
                          <Box mt={1}>
                            <TextFieldPurple
                              fullWidth
                              size="small"
                              label="Valor"
                              value={tamanho?.valor_padrao}
                              onChange={(e) =>
                                handleValorPersonalizado(
                                  e.target.value,
                                  tamanho.id,
                                )}
                              InputProps={{
                                inputComponent: NumberFormatCustom as any,
                              }}
                            />
                          </Box>
                        </Grid>
                      ))}{' '}
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  <Grid container spacing={3}>
                    {adicionais &&
                      adicionais.map((adicional) => (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <CheckboxPurple
                            value={adicional.id}
                            checked={adicional.checked}
                            onChange={handleAdicionais}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />{' '}
                          {adicional.descricao}{' '}
                          {adicional.valor_padrao > 0
                            ? `( R$ ${String(adicional.valor_padrao).replace(
                                '.',
                                ',',
                              )} )`
                            : ''}
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel value="4">
                  <Grid container spacing={3} style={{ display: 'block' }}>
                    <List>
                      {opcoes &&
                        opcoes.map((opcao) => {
                          const labelId = `checkbox-list-label-${opcao.id}`;
                          return (
                            <div
                              style={{
                                marginBottom: '10px',
                                boxShadow: '#81259D 0px 0px 15px',
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{ padding: '15px' }}
                              >
                                <h4>{opcao.descricao}</h4>
                                {opcao.opcao.map((opcaoItem) => (
                                  <ListItem
                                    key={opcaoItem.id}
                                    role={undefined}
                                    dense
                                    button
                                    onClick={handleToggle(opcaoItem)}
                                  >
                                    <ListItemIcon>
                                      <CheckboxPurple
                                        edge="start"
                                        checked={
                                          opcoesProd.findIndex(
                                            (element) =>
                                              element.idOpcao === opcaoItem.id,
                                          ) >= 0
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={labelId}
                                      primary={opcaoItem.descricao}
                                    />
                                    <ListItemSecondaryAction>
                                      <CurrencyTextField
                                        variant="standard"
                                        fullWidth
                                        size="small"
                                        value={
                                          opcoesProd.find(
                                            (element) =>
                                              element.idOpcao === opcaoItem.id,
                                          )?.valor
                                        }
                                        label="Valor"
                                        currencySymbol="R$"
                                        outputFormat="string"
                                        onChange={(event) => {
                                          opcaoItem.valor = event.target.value;
                                          handleAtualizaValorOpcao(
                                            opcaoItem.id,
                                            event.target.value,
                                          );
                                        }}
                                        maximumValue={999999.99}
                                      />
                                      {/* <TextFieldPurple
                                        fullWidth
                                        size="small"
                                        label="Valor"
                                        value={
                                          opcoesProd.find(
                                            (element) =>
                                              element.idOpcao === opcaoItem.id,
                                          )?.valor
                                        }
                                        onChange={(event) => {
                                          opcaoItem.valor = event.target.value;
                                          handleAtualizaValorOpcao(
                                            opcaoItem.id,
                                            event.target.value,
                                          );
                                        }}
                                        InputProps={{
                                          inputComponent: NumberFormatCustom as any,
                                        }}
                                      /> */}
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                ))}
                              </Grid>
                            </div>
                          );
                        })}
                    </List>
                  </Grid>
                </TabPanel>
                <TabPanel value="5">
                  <Grid container spacing={3}>
                    {sugestoes &&
                      sugestoes.map((sugestao) => (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <CheckboxPurple
                            value={sugestao.id}
                            checked={sugestao.checked}
                            onChange={handleSugestoes}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />{' '}
                          {sugestao.descricao}{' '}
                          {sugestao.valor_padrao > 0
                            ? `( R$ ${String(sugestao.valor_padrao).replace(
                                '.',
                                ',',
                              )} )`
                            : ''}
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
              </TabContext>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={handleCloseEditar} variant="contained">
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Salvar
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      {/* FIM EDITAR PRODUTO */}

      {/* INICIO DESEJA EXCLUIR */}
      {exibeMsg && (
        <Dialog
          open={exibeMsg}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setExibeMsg(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja excluir esse produto?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExibeMsg(false)}
              color="secondary"
              variant="contained"
            >
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleExcluir}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Sim
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/* FIM DESEJA EXCLUIR */}

      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
