import api from '../../../services/api';

export const loadCategories = async (idEstablishment, token) => {
  try {
    const response = await api.get(`/categorias/${idEstablishment}/grid`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const loadCategoriesSelect = async (idEstablishment, token) => {
  try {
    const response = await api.get(`/categorias/${idEstablishment}/select`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const newCategory = async (data, idEstablishment, token) => {
  try {
    const response = await api.post(`/categorias/${idEstablishment}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const editCategory = async (data, idCategory, token) => {
  try {
    const response = await api.put(`/categorias/${idCategory}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const uploadImage = async (idCategory, data, idEstablishment, token) => {
  try {
    const response = await api.post(
      `/categorias/${idCategory}/estabelecimento/${idEstablishment}/uploadimage`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const deleteImage = async (idCategory, token) => {
  try {
    const response = await api.delete(`/categorias/${idCategory}/uploadimage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200 && response.status !== 204)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const getByIdCategory = async (idCategory, token) => {
  try {
    const response = await api.get(`/categorias/edit/${idCategory}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const deleteCategory = async (id, token) => {
  try {
    const response = await api.delete(`/categorias/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200 && response.status !== 204)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};
