import api from '../../services/api';

export const loadCustomers = async (idEstabelecimento, token) => {
  try {
    const response = await api.get(`/clientes/${idEstabelecimento}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const getByIdCustomers = async (idCustomer, token) => {
  try {
    const response = await api.get(`/clientes/edit/${idCustomer}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const deleteCustomer = async (idCustomer, token) => {
  try {
    const response = await api.delete(`/clientes/${idCustomer}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200 && response.status !== 204)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const newCustomer = async (data, idEstabelecimento, token) => {
  try {
    const response = await api.post(`/clientes/${idEstabelecimento}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const editCustomer = async (data, id, token) => {
  try {
    const response = await api.put(`/clientes/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const loadAddresses = async (idCustomer: number, token) => {
  try {
    const response = await api.get(`/enderecos/${idCustomer}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};
