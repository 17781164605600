import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import LinearProgress from '@material-ui/core/LinearProgress';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

export const ButtonPurple = withStyles((theme: Theme) => ({
  root: {
    color: '#81259D',
    backgroundColor: 'transparent',
    borderColor: '#81259D',
    borderStyle: 'solid',
    borderWidth: '1px',
    '&:hover': {
      backgroundColor: '#81259D !important',
      color: '#fff !important',
    },
  },
}))(Button);

export const ButtonNew = withStyles((theme: Theme) => ({
  root: {
    color: '#fff',
    backgroundColor: '#81259D',
    borderColor: '#81259D',
    borderStyle: 'solid',
    borderWidth: '1px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#81259D',
      borderColor: '#81259D',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
  },
}))(Button);

export const TextFieldPurple = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#81259D',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#81259D',
    },
    '&:hover': {
      borderBottomColor: '#81259D',
    },
  },
})(TextField);

export const SwitchPurple = withStyles({
  switchBase: {
    color: '#81259D',
    '&$checked': {
      color: '#81259D',
    },
    '&$checked + $track': {
      backgroundColor: '#81259D',
    },
  },
  checked: {},
  track: {},
})(Switch);

export const FormControlPurple = withStyles((theme: Theme) => ({
  root: {
    '& label.Mui-focused': {
      color: '#81259D',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#81259D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#81259D',
      },
    },
  },
}))(FormControl);

export const KeyboardDatePickerPurple = withStyles((theme: Theme) => ({
  root: {
    '& label.Mui-focused': {
      color: '#81259D',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#81259D',
    },
    '&:hover': {
      borderBottomColor: '#81259D',
    },
  },
}))(KeyboardDatePicker);

export const CheckboxPurple = withStyles({
  root: {
    color: '#81259D',
    '&$checked': {
      color: '#81259D',
    },
  },
  checked: {},
})(Checkbox);

export const RadioPurple = withStyles({
  root: {
    color: '#81259D',
    '&$checked': {
      color: '#81259D',
    },
  },
  checked: {},
})(Radio);

export const LinearProgressPurple = withStyles((theme: Theme) => ({
  root: {
    height: 4,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: '#81259D',
  },
}))(LinearProgress);
