import React, { useState, useEffect } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Form } from '@unform/web';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import {
  CheckboxPurple,
  SwitchPurple,
  TextFieldPurple,
} from '../../components/Global';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
      '& .ant-empty-img-1': {
        fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
      },
      '& .ant-empty-img-2': {
        fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
      },
      '& .ant-empty-img-3': {
        fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
      },
      '& .ant-empty-img-4': {
        fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
      },
      '& .ant-empty-img-5': {
        fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
        fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },

    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    button: {
      // color: blue[900],
      margin: 10,
    },
    input: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    headerDialog: {
      backgroundColor: '#81259D',
      color: '#fff',
    },
  }),
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface IDeliveryTypes {
  id: number;
  codigo: string;
  descricao: string;
}

interface IDeliveryTypesChecked {
  codeType: string;
}

export const Settings = () => {
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [endereco, setEndereco] = useState('');
  const [tempoEntrega, setTempoEntrega] = useState('');
  const [email, setEmail] = useState('');
  const [imagemUrl, setImagemUrl] = useState();
  const [ativo, setAtivo] = useState('');
  const [ativoBool, setAtivoBool] = useState(false);
  const [link, setLink] = useState('');
  const [load, setLoad] = useState(true);
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');
  const [deliveryTypes, setDeliveryTypes] = useState<IDeliveryTypes[]>([]);
  const [codes, setCodes] = useState<IDeliveryTypesChecked[]>([]);

  const classes = useStyles();

  const token = localStorage.getItem('@PecaAquiDashboard:token');

  const { usuario } = useAuth();

  useEffect(() => {
    setLoad(true);

    async function getInformations() {
      try {
        const responseEstablishment = await api.get(
          `/estabelecimentos/byid/${usuario.idEstabelecimento}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setId(responseEstablishment.data.id);
        setNome(responseEstablishment.data.nome);
        setTelefone(responseEstablishment.data.telefone);
        setCelular(responseEstablishment.data.celular);
        setEndereco(responseEstablishment.data.endereco);
        setEmail(responseEstablishment.data.email);
        setImagemUrl(responseEstablishment.data.imagem_url);
        setLink(responseEstablishment.data.link);
        setAtivo(responseEstablishment.data.aberto);

        if (responseEstablishment.data.aberto === 'S') {
          setAtivoBool(true);
        } else {
          setAtivoBool(false);
        }

        const responseDeliveryTypes = await api.get(
          `/delivery-types-establishment`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setDeliveryTypes(responseDeliveryTypes.data);

        const responseDeliveryTypesEstablishment = await api.get(
          `/delivery-types-establishment/establishment=${usuario.idEstabelecimento}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        responseDeliveryTypesEstablishment.data.forEach((element) => {
          setCodes((old) => [
            ...old,
            {
              codeType: element.codigoTipoEntrega,
            },
          ]);
        });

        setLoad(false);
      } catch (error) {
        setLoad(false);
        setSnackBarErro(true);
        setMsg('Erro ao buscar os dados do estabelecimento. Tente novamente.');
      }
    }
    getInformations();
  }, [token]);

  const handleLimpaCampos = (): void => {
    setId('');
    setNome('');
    setTempoEntrega('');
    setEndereco('');
    setTelefone('');
    setCelular('');
    setEmail('');
    setImagemUrl('');
    setLink('');
    setAtivo('');
    setAtivoBool(false);
  };

  const handleAtualizar = () => {
    setLoad(true);
    handleLimpaCampos();
    api
      .get(`/estabelecimentos/byid/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setId(response.data.id);
        setNome(response.data.nome);
        setTelefone(response.data.telefone);
        setCelular(response.data.celular);
        setEndereco(response.data.endereco);
        setEmail(response.data.email);
        setImagemUrl(response.data.imagem_url);
        setLink(response.data.link);
        setAtivo(response.data.aberto);

        if (response.data.aberto === 'S') {
          setAtivoBool(true);
        } else {
          setAtivoBool(false);
        }

        setLoad(false);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  async function handleSalvar() {
    setLoad(true);

    try {
      const estabelecimento = {
        nome,
        tempo_entrega: tempoEntrega,
        endereco,
        telefone,
        celular,
        email,
        aberto: ativo,
      };

      await api.put(`/estabelecimentos/${id}`, estabelecimento, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = {
        codes,
      };

      await api.post(
        `/delivery-types-establishment/establishment=${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setLoad(false);
      setMsg('Cadastro editado com sucesso!');
      setSnackBarSucesso(true);
      handleAtualizar();
    } catch (error) {
      setLoad(false);
      setMsg(error.response.data.message);
      setSnackBarErro(true);
    }
  }

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarSucesso(false);
    setSnackBarErro(false);
  };

  async function handleImageChange(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.files) {
      const reader = new FileReader();
      const selectedFile = e.target.files[0];

      reader.onloadend = () => {
        setImagemUrl(reader.result);
      };

      reader.readAsDataURL(selectedFile);

      const data = new FormData();

      data.append('file', selectedFile);

      api
        .post(`/estabelecimentos/${id}/uploadimage`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {})
        .catch((error) => {
          setSnackBarErro(true);
          setMsg(error.response.data.message);
        });
    }
  }

  async function handleDeliveryTypes(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.checked) {
      setCodes((old) => [
        ...old,
        {
          codeType: e.target.value,
        },
      ]);
    } else {
      const code = e.target.value;
      const index = codes.findIndex((e) => e.codeType === code);
      codes.splice(index, 1);
      setCodes([...codes]);
    }
  }

  async function handleAtivo(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.value === 'S') {
      setAtivo('N');
      setAtivoBool(false);
    } else {
      setAtivo('S');
      setAtivoBool(true);
    }
  }

  return (
    <>
      {/* TELA */}

      {load ? (
        <CircularProgress
          size={40}
          style={{ marginLeft: '50%', marginTop: '10%', color: '#81259D' }}
        />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography component="h5" variant="h5" noWrap>
                Configurações
              </Typography>
            </Grid>
          </Grid>
          <Form onSubmit={handleSalvar}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div
                  style={{
                    maxHeight: 1000,
                    width: '100%',
                    background: '#fff',
                    borderRadius: '4px',
                    boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
                    marginBottom: '9px',
                    marginTop: '24px',
                  }}
                >
                  <div style={{ padding: '10px 40px 10px 40px' }}>
                    Dados do Estabelecimento
                  </div>
                  <div style={{ padding: '15px 40px 10px 40px' }}>
                    <FormGroup aria-label="position" row>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextFieldPurple
                            required
                            disabled
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            label="Nome"
                            fullWidth
                            variant="standard"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <TextFieldPurple
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            label="E-mail"
                            fullWidth
                            variant="standard"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <TextFieldPurple
                            value={telefone}
                            onChange={(e) => setTelefone(e.target.value)}
                            label="Telefone"
                            fullWidth
                            variant="standard"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <TextFieldPurple
                            required
                            value={celular}
                            onChange={(e) => setCelular(e.target.value)}
                            label="Celular"
                            fullWidth
                            variant="standard"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <TextFieldPurple
                            value={endereco}
                            onChange={(e) => setEndereco(e.target.value)}
                            label="Endereço"
                            fullWidth
                            variant="standard"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <TextFieldPurple
                            value={tempoEntrega}
                            onChange={(e) => setTempoEntrega(e.target.value)}
                            label="Tempo de Entrega"
                            fullWidth
                            variant="standard"
                            size="small"
                          />
                          <small>
                            Ex: Delivery: 30min à 45min | Retirar: 15min à 20min
                          </small>
                        </Grid>
                        {link ? (
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            Link Cardápio: https://cardapiopecaaqui.app.br/
                            {link}
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <label htmlFor="contained-button-file">
                            <Fab component="span" className={classes.button}>
                              <AddPhotoAlternateIcon />
                            </Fab>
                          </label>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={handleImageChange}
                          />
                          {imagemUrl && (
                            <img src={imagemUrl} width={100} height={50} />
                          )}
                          <div>
                            <Button
                              variant="outlined"
                              component="label"
                              color="secondary"
                              onClick={() => {
                                setImagemUrl('');
                                api
                                  .delete(
                                    `/estabelecimentos/${id}/uploadimage`,
                                    {
                                      headers: {
                                        Authorization: `Bearer ${token}`,
                                      },
                                    },
                                  )
                                  .then(() => {})
                                  .catch((error) => {
                                    setSnackBarErro(true);
                                    setMsg(error.response.data.message);
                                  });
                              }}
                            >
                              Remover logo
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <FormControlLabel
                            value="start"
                            control={(
                              <SwitchPurple
                                checked={ativoBool === true}
                                onChange={handleAtivo}
                                value={ativo}
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                              />
                            )}
                            label="Ativo:"
                            labelPlacement="start"
                          />
                          <small>
                            {' '}
                            Quando marcado seu site estará no ar para o público.
                          </small>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <div
                  style={{
                    height: 500,
                    width: '100%',
                    background: '#fff',
                    borderRadius: '4px',
                    boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
                    marginBottom: '9px',
                  }}
                >
                  <div style={{ padding: '10px 40px 10px 40px' }}>
                    Tipos de Entrega
                  </div>
                  <div style={{ padding: '10px 40px 10px 40px' }}>
                    <FormGroup aria-label="position" row>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {deliveryTypes &&
                            deliveryTypes.map((deliveryType) => (
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <CheckboxPurple
                                  value={deliveryType.codigo}
                                  checked={
                                    codes.findIndex(
                                      (e) => e.codeType === deliveryType.codigo,
                                    ) >= 0
                                  }
                                  onChange={handleDeliveryTypes}
                                />
                                {deliveryType.descricao}
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={load}
              style={{
                backgroundColor: '#4caf50',
                marginTop: '6px',
              }}
            >
              Salvar
            </Button>
          </Form>
        </>
      )}

      {/* FIM TELA */}

      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
