import React, { useEffect, useState } from 'react';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { TextFieldPurple } from '../../../../components/Global';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  headerDialog: {
    backgroundColor: '#81259D',
    color: '#fff',
  },
}));

interface ICustomers {
  id: number;
  nome: string;
  telefone: string;
}

interface IAddress {
  id: number;
  idCidade: number;
  idBairro: number;
  rua: string;
  numero: number;
  complemento: string;
  ponto_referencia: string;
  cidade: {
    nome: string;
  };
  bairro: {
    nome: string;
    valor_entrega: string;
  };
}
interface ItemDialogProps {
  open: boolean;
  customerData: ICustomers | null | undefined;
  addressData: IAddress | null | undefined;
  handleClose(
    customer: ICustomers | null | undefined,
    address: IAddress | null | undefined,
    obsEntrega: string,
    valorEntrega: string,
  ): void;
}

export const DialogCustomers: React.FC<ItemDialogProps> = ({
  open,
  customerData,
  addressData,
  handleClose,
}) => {
  const classes = useStyles();
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');
  const [customers, setCustomers] = useState<ICustomers[]>([]);
  const [customer, setCustomer] = useState<ICustomers | null>();
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [address, setAddress] = useState<IAddress | null>();
  const [observacaoEntrega, setObservacaoEntrega] = useState('');
  const [valorEntrega, setValorEntrega] = useState('');

  const token = localStorage.getItem('@PecaAquiDashboard:token');
  const { usuario } = useAuth();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setCustomer(customerData);
    setAddress(addressData);
    if (addressData) {
      setValorEntrega(addressData?.bairro.valor_entrega);
      setObservacaoEntrega(
        `${addressData.cidade.nome}, ${addressData.bairro.nome}, ${addressData.rua}, ${addressData.numero}, ${addressData.complemento}, ${addressData.ponto_referencia}`,
      );
    }

    if (customerData) {
      api
        .get(`/enderecos/${customerData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAddresses(response.data);
        })
        .catch((error) => {
          setSnackBarErro(true);
          setMsg(error.response.data.message);
        });
    }

    api
      .get(`clientes/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  }, [token, usuario.idEstabelecimento, customerData, addressData]);

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarErro(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() =>
        handleClose(customer, address, observacaoEntrega, valorEntrega)
      }
      aria-labelledby="form-dialog-title"
      fullWidth
      disableEscapeKeyDown
      disableBackdropClick
    >
      <DialogTitle className={classes.headerDialog}>
        Selecionar Cliente
      </DialogTitle>
      <FormGroup aria-label="position" row>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box p={1} fontSize={16}>
                Clientes:
                <Autocomplete
                  options={customers as ICustomers[]}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  value={customer}
                  onChange={(event: any, newValue: ICustomers | null) => {
                    setCustomer(newValue);
                    setAddresses([]);
                    setAddress(null);
                    setObservacaoEntrega('');
                    if (newValue) {
                      api
                        .get(`/enderecos/${newValue?.id}`, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        })
                        .then((response) => {
                          setAddresses(response.data);
                        })
                        .catch((error) => {
                          setSnackBarErro(true);
                          setMsg(error.response.data.message);
                        });
                    }
                  }}
                  noOptionsText="Nenhum cliente cadastrado"
                  getOptionLabel={(option) =>
                    `${option.nome} - ${option.telefone}`
                  }
                  renderOption={(option) => (
                    <>
                      {option.nome} - {option.telefone}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextFieldPurple
                      {...params}
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box p={1} fontSize={16}>
                Endereços:
                <Autocomplete
                  options={addresses as IAddress[]}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  clearOnBlur
                  value={address}
                  onChange={(event: any, newValue: IAddress | null) => {
                    setAddress(newValue);
                    setObservacaoEntrega('');
                    setValorEntrega('');
                    if (newValue) {
                      setObservacaoEntrega(
                        `${newValue.cidade.nome}, ${newValue.bairro.nome}, ${newValue.rua}, ${newValue.numero}, ${newValue.complemento}, ${newValue.ponto_referencia}`,
                      );
                      setValorEntrega(newValue?.bairro.valor_entrega);
                    }
                  }}
                  getOptionLabel={(option) =>
                    `${option.rua} - ${option.numero}`
                  }
                  renderOption={(option) => (
                    <>
                      {option.rua} - {option.numero}
                    </>
                  )}
                  noOptionsText="Nenhum endereço cadastrado para o cliente selecionado acima"
                  renderInput={(params) => (
                    <TextFieldPurple
                      {...params}
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </FormGroup>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            type="button"
            autoFocus
            variant="contained"
            color="primary"
            style={{
              backgroundColor: '#4caf50',
            }}
            onClick={() =>
              handleClose(customer, address, observacaoEntrega, valorEntrega)
            }
          >
            Ok
          </Button>
        </div>
      </DialogActions>
      <Snackbar
        open={snackBarErro}
        autoHideDuration={4000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
