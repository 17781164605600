import React, { useEffect, useState } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import List from '@material-ui/core/List';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import StorageIcon from '@material-ui/icons/Storage';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import MapIcon from '@material-ui/icons/Map';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import PaymentIcon from '@material-ui/icons/Payment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoomIcon from '@material-ui/icons/Room';
import AlbumIcon from '@material-ui/icons/Album';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import PeopleIcon from '@material-ui/icons/People';
import Collapse from '@material-ui/core/Collapse';
import TocIcon from '@material-ui/icons/Toc';
import Box from '@material-ui/core/Box';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Footer from '../TheFooter';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';
import Content from '../TheContent';
import { useStyles } from './styles';

import logoImg from '../../assets/favicon.jpg';

const Layout: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const token = localStorage.getItem('@PecaAquiDashboard:token');
  const [openSpeed, setOpenSpeed] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElCardapio, setAnchorElCardapio] = useState(false);
  const [anchorElEntrega, setAnchorElEntrega] = useState(false);
  const [plano, setPlano] = useState(0);

  const { signOut, usuario } = useAuth();
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleIrMenu = (menu: string) => {
    history.push(menu);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuCardapio = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCardapio(!anchorElCardapio);
  };

  const handleClickMenuEntrega = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElEntrega(!anchorElEntrega);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = () => {
    setOpenSpeed(true);
  };

  const handleCloseSpeed = () => {
    setOpenSpeed(false);
  };

  useEffect(() => {
    async function init() {
      await Notification.requestPermission();
      try {
        const responseEstablishment = await api.get(
          `/estabelecimentos/${usuario.idEstabelecimento}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setPlano(responseEstablishment.data.plano);
      } catch (error) {}
    }

    init();
  }, [usuario?.idEstabelecimento, token]);

  useEffect(() => {
    if (usuario && usuario.idEstabelecimento) {
      setInterval(async () => {
        try {
          const responseOrder = await api.get(
            `/pedidovendas/${usuario.idEstabelecimento}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          const novosPedidosCount = responseOrder.data.filter(
            (e) => e.status === 'P',
          )?.length;

          if (novosPedidosCount > 0) {
            document.title = `(${novosPedidosCount}) PeçaAqui - Admin`;

            if (Notification.permission === 'granted') {
              const notification = new Notification('Peça Aqui', {
                body: 'Há pedidos para serem aprovados',
                vibrate: [200, 100, 200],
                icon: logoImg,
              });

              notification.onclick = (e) => {
                e.preventDefault();
                window.focus();
                notification.close();
              };
            }
          } else {
            document.title = 'PeçaAqui - Admin';
          }
        } catch (error) {}
      }, 30000);
    }
  }, [token, usuario, usuario?.idEstabelecimento]);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
          style={{ backgroundColor: '#81259D' }}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <img
                alt=""
                src={usuario?.estabelecimento?.imagem_url}
                style={{
                  maxHeight: '55px',
                  maxWidth: '72px',
                }}
              />
            </Typography>

            <IconButton color="inherit">
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="text"
                color="inherit"
                onClick={handleClick}
              >
                <PersonIcon />
                {usuario?.nome}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    handleIrMenu('/settings/neighborhoods');
                  }}
                >
                  <ListItemIcon>
                    <RoomIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Bairros" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    handleIrMenu('/settings/cities');
                  }}
                >
                  <ListItemIcon>
                    <LocationCityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Cidades" />
                </MenuItem>

                {usuario?.administrador === 'S' && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      handleIrMenu('/settings');
                    }}
                  >
                    <ListItemIcon>
                      <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Configurações" />
                  </MenuItem>
                )}
                {plano === 2 && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      handleIrMenu('/settings/schedules');
                    }}
                  >
                    <ListItemIcon>
                      <WatchLaterIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Horários" />
                  </MenuItem>
                )}
                <Divider />
                <MenuItem
                  onClick={() => {
                    signOut();
                    handleIrMenu('/signin');
                  }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Sair" />
                </MenuItem>
              </Menu>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <List>
            {usuario?.administrador === 'S' ? (
              <>
                <ListItem button onClick={() => handleIrMenu('/')}>
                  <ListItemIcon>
                    <DashboardIcon style={{ color: '#ff5722' }} />
                  </ListItemIcon>
                  <ListItemText>Dashboard</ListItemText>
                </ListItem>
              </>
            ) : (
              ''
            )}
            <ListItem button onClick={() => handleIrMenu('/orders')}>
              <ListItemIcon>
                <AssignmentIcon style={{ color: '#e91e63' }} />
              </ListItemIcon>
              <ListItemText>Pedidos</ListItemText>
            </ListItem>
            {usuario?.administrador === 'S' ? (
              <>
                <ListItem button onClick={() => handleIrMenu('/cash-flow')}>
                  <ListItemIcon>
                    <MonetizationOnIcon style={{ color: '#9c27b0' }} />
                  </ListItemIcon>
                  <ListItemText>Fluxo de Caixa</ListItemText>
                </ListItem>
              </>
            ) : (
              ''
            )}
            <ListItem button onClick={handleClickMenuCardapio}>
              <ListItemIcon>
                <MapIcon style={{ color: '#673ab7' }} />
              </ListItemIcon>
              <ListItemText>Cardápio </ListItemText>
              {anchorElCardapio ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={anchorElCardapio} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => handleIrMenu('/menu/additional')}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <AddBoxIcon style={{ color: '#673ab7' }} />
                  </ListItemIcon>
                  <ListItemText>Adicionais</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleIrMenu('/menu/edges')}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <AlbumIcon style={{ color: '#673ab7' }} />
                  </ListItemIcon>
                  <ListItemText>Bordas</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleIrMenu('/menu/categories')}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <StorageIcon style={{ color: '#673ab7' }} />
                  </ListItemIcon>
                  <ListItemText>Categorias</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleIrMenu('/menu/options')}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <TocIcon style={{ color: '#673ab7' }} />
                  </ListItemIcon>
                  <ListItemText>Opções</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleIrMenu('/menu/products')}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <FastfoodIcon style={{ color: '#673ab7' }} />
                  </ListItemIcon>
                  <ListItemText>Produtos</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleIrMenu('/menu/subcategories')}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <AccountTreeIcon style={{ color: '#673ab7' }} />
                  </ListItemIcon>
                  <ListItemText>SubCategorias</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleIrMenu('/menu/sizes')}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <FormatSizeIcon style={{ color: '#673ab7' }} />
                  </ListItemIcon>
                  <ListItemText>Tamanhos</ListItemText>
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={() => handleIrMenu('/customers')}>
              <ListItemIcon>
                <AssignmentIndIcon style={{ color: '#3f51b5' }} />
              </ListItemIcon>
              <ListItemText>Clientes</ListItemText>
            </ListItem>
            {plano === 2 && (
              <>
                <ListItem
                  button
                  onClick={() => handleIrMenu('/promotional-coupon')}
                >
                  <ListItemIcon>
                    <LocalOfferIcon style={{ color: '#2196f3' }} />
                  </ListItemIcon>
                  <ListItemText>Cupom Promocional</ListItemText>
                </ListItem>
                <ListItem button onClick={() => handleIrMenu('/rewards')}>
                  <ListItemIcon>
                    <EmojiEventsIcon style={{ color: '#4caf50' }} />
                  </ListItemIcon>
                  <ListItemText>Recompensas</ListItemText>
                </ListItem>
              </>
            )}
            <ListItem button onClick={() => handleIrMenu('/types-of-payments')}>
              <ListItemIcon>
                <PaymentIcon style={{ color: '#009688' }} />
              </ListItemIcon>
              <ListItemText>Tipos de Pagtos</ListItemText>
            </ListItem>
            {usuario?.administrador === 'S' && (
              <>
                <ListItem button onClick={() => handleIrMenu('/users')}>
                  <ListItemIcon>
                    <PeopleIcon style={{ color: 'Salmon' }} />
                  </ListItemIcon>
                  <ListItemText>Usuários</ListItemText>
                </ListItem>
              </>
            )}

            {plano === 2 && (
              <>
                <ListItem button onClick={handleClickMenuEntrega}>
                  <ListItemIcon>
                    <MotorcycleIcon style={{ color: 'Indigo' }} />
                  </ListItemIcon>
                  <ListItemText>Entrega </ListItemText>
                  {anchorElEntrega ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={anchorElEntrega} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => handleIrMenu('/delivery/motoboys')}
                      className={classes.nested}
                    >
                      <ListItemIcon>
                        <SportsMotorsportsIcon style={{ color: 'Indigo' }} />
                      </ListItemIcon>
                      <ListItemText>Motoboys</ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() =>
                        handleIrMenu('/delivery/total-by-motoboys')}
                      className={classes.nested}
                    >
                      <ListItemIcon>
                        <MonetizationOnIcon style={{ color: 'Indigo' }} />
                      </ListItemIcon>
                      <ListItemText>Total por Motoboy</ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => handleIrMenu('/delivery/motoboys-day')}
                      className={classes.nested}
                    >
                      <ListItemIcon>
                        <AssignmentTurnedInIcon style={{ color: 'Indigo' }} />
                      </ListItemIcon>
                      <ListItemText>Motoboys Dia</ListItemText>
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.container}>
            <Content />
            <Box mt={5}>
              <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className={classes.speedDial}
                icon={<ContactSupportIcon />}
                onClose={handleCloseSpeed}
                onOpen={handleOpen}
                open={openSpeed}
              >
                <SpeedDialAction
                  icon={<WhatsAppIcon style={{ color: '#25d366' }} />}
                  tooltipTitle="Suporte"
                  onClick={() => {
                    window.open('https://whatsfacil.com/11060d');
                    handleCloseSpeed();
                  }}
                />
              </SpeedDial>
              <Footer />
            </Box>
          </div>
        </main>
      </div>
    </>
  );
};

export default Layout;
