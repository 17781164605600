import api from '../../../services/api';

export const loadCities = async (idEstabelecimento, token) => {
  try {
    const response = await api.get(`/cidades/${idEstabelecimento}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};
