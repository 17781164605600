import React, { useState, useEffect } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  DataGrid,
  ColDef,
  ValueFormatterParams,
  CellValue,
} from '@material-ui/data-grid';

import { useAuth } from '../../hooks/auth';
import { telefoneMask } from '../../hooks/mask';
import { useStyles } from './styles';
import { NoRows } from '../../components/NoRows';
import { loadCustomers, deleteCustomer } from './actions';
import CustomerNew from './components/Add';
import CustomerEdit from './components/Edit';
import { ButtonNew } from '../../components/Global/index';
import { LoadGrid } from '../../components/LoadGrid';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomNoRowsOverlay() {
  return <NoRows />;
}

export const Customers = () => {
  const [clientes, setClientes] = useState([]);
  const [id, setId] = useState(0);

  const [load, setLoad] = useState(true);
  const [displayMsg, setDisplayMsg] = useState(false);
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');
  const [loadButton, setLoadButton] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const classes = useStyles();
  const { usuario } = useAuth();
  const token = localStorage.getItem('@PecaAquiDashboard:token');

  useEffect(() => {
    setLoad(true);

    async function init() {
      try {
        const response = await loadCustomers(usuario.idEstabelecimento, token);
        if (response?.data) {
          setClientes(response.data);
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
        setMsg('Algum erro ocorreu, tente novamente mais tarde!');
        setSnackBarErro(true);
      }
    }

    init();
  }, [usuario.idEstabelecimento, token]);

  async function handleShowEdit() {
    setShowEdit(true);
  }

  const handleShowMsg = (idCliente: string | undefined | CellValue): void => {
    if (idCliente) {
      setId(Number(idCliente));
      setDisplayMsg(true);
    }
  };

  const handleSearchClient = async (
    idCustomer: string | undefined | CellValue,
  ) => {
    setId(Number(idCustomer));
    handleShowEdit();
  };

  const handleUpdate = async () => {
    setLoad(true);
    setClientes([]);
    try {
      const response = await loadCustomers(usuario.idEstabelecimento, token);
      if (response?.data) {
        setClientes(response.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleShowNew = async (): Promise<void> => {
    setShowNew(true);
  };

  const handleCloseNew = (save: boolean): void => {
    setShowNew(false);

    if (save) {
      setSnackBarSucesso(true);
      setMsg('Cadastro realizado com sucesso!');
      handleUpdate();
    }
  };

  const handleCloseEdit = (save: boolean): void => {
    setShowEdit(false);
    if (save) {
      setSnackBarSucesso(true);
      setMsg('Cadastro editado com sucesso!');
      handleUpdate();
    }
  };

  const handleDelete = async () => {
    setLoadButton(true);

    try {
      await deleteCustomer(id, token);
      setLoadButton(false);
      setDisplayMsg(false);
      setSnackBarSucesso(true);
      setMsg('Cliente excluido com sucesso!');
      handleUpdate();
    } catch (error) {
      setLoadButton(false);
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarSucesso(false);
    setSnackBarErro(false);
  };

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Ações',
      width: 150,
      sortable: false,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Button
            size="small"
            onClick={() => handleSearchClient(params?.getValue('id'))}
          >
            <EditIcon />
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={() => handleShowMsg(params?.getValue('id'))}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
    { field: 'nome', headerName: 'Nome', width: 230 },
    {
      field: 'telefone',
      headerName: 'Celular',
      width: 140,
      valueFormatter: (params: ValueFormatterParams) =>
        `${params?.value && telefoneMask(params?.value.toString())}`,
    },
  ];

  return (
    <>
      {/* TELA */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Clientes
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridRight}>
          <ButtonNew
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleShowNew}
          >
            Novo
          </ButtonNew>
        </Grid>
      </Grid>
      <div className={classes.dataGrid}>
        <DataGrid
          rows={clientes}
          columns={columns}
          loading={load}
          components={{
            loadingOverlay: LoadGrid,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </div>
      {/* FIM TELA */}

      {showNew && (
        <CustomerNew
          open={showNew}
          handleClose={handleCloseNew}
          idEstablishment={usuario.idEstabelecimento}
        />
      )}

      {showEdit && (
        <CustomerEdit
          open={showEdit}
          handleClose={handleCloseEdit}
          idEstablishment={usuario.idEstabelecimento}
          idCustomer={id}
        />
      )}

      {/* INICIO DESEJA EXCLUIR */}
      {displayMsg && (
        <Dialog
          open={displayMsg}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setDisplayMsg(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja excluir esse cliente?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDisplayMsg(false)}
              color="secondary"
              variant="contained"
            >
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleDelete}
                color="primary"
                variant="contained"
                className={classes.buttonSuccess}
                disabled={loadButton}
              >
                Sim
              </Button>
              {loadButton && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/* FIM DESEJA EXCLUIR */}

      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
