import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  DataGrid,
  ColDef,
  GridOverlay,
  ValueFormatterParams,
  CellValue,
} from '@material-ui/data-grid';

import { Form } from '@unform/web';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import {
  ButtonNew,
  TextFieldPurple,
  SwitchPurple,
} from '../../components/Global';
import { LoadGrid } from '../../components/LoadGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
      '& .ant-empty-img-1': {
        fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
      },
      '& .ant-empty-img-2': {
        fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
      },
      '& .ant-empty-img-3': {
        fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
      },
      '& .ant-empty-img-4': {
        fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
      },
      '& .ant-empty-img-5': {
        fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
        fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },

    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    button: {
      // color: blue[900],
      margin: 10,
    },
    input: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    headerDialog: {
      backgroundColor: '#81259D',
      color: '#fff',
    },
  }),
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>Não foi encontrado dados</div>
    </GridOverlay>
  );
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      prefix="%"
    />
  );
}

export const PromotionalCoupon = () => {
  const [cupomPromocional, setCupomPromocional] = useState([]);
  const [showNovo, setShowNovo] = useState(false);
  const [showEditar, setShowEditar] = useState(false);
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [desconto, setDesconto] = useState('');
  const [ativoBool, setAtivoBool] = useState(true);
  const [ativo, setAtivo] = useState('S');
  const [exibeMsg, setExibeMsg] = useState(false);
  const [load, setLoad] = useState(false);
  const [carregandoBotao, setCarregandoBotao] = useState(false);
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const token = localStorage.getItem('@PecaAquiDashboard:token');
  const { usuario } = useAuth();

  const history = useHistory();

  useEffect(() => {
    setLoad(true);
    api
      .get(`/cupompromocional/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCupomPromocional(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  }, [token]);

  const handleAtualizar = () => {
    setCupomPromocional([]);
    setLoad(true);
    api
      .get(`/cupompromocional/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCupomPromocional(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleShowEditar = (cupomPromocional): void => {
    setShowEditar(true);
    setId(cupomPromocional.id);
    setNome(cupomPromocional.nome);
    setDesconto(cupomPromocional.desconto);
    setAtivo(cupomPromocional.ativo);

    if (cupomPromocional.ativo === 'S') {
      setAtivoBool(true);
    } else {
      setAtivoBool(false);
    }
  };

  const handleCloseEditar = (): void => {
    setShowEditar(false);
    setId('');
    setNome('');
    setDesconto('');
    setAtivo('S');
  };

  const handleEditar = () => {
    setCarregandoBotao(true);
    const cupomPromocionalData = {
      nome: nome.toUpperCase(),
      desconto: desconto.replace(',', '.'),
      ativo,
    };

    api
      .put(`/cupompromocional/${id}`, cupomPromocionalData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setCarregandoBotao(false);
        setSnackBarSucesso(true);
        setMsg('Cadastro editado com sucesso!');
        handleCloseEditar();
        handleAtualizar();
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleShowNovo = (): void => {
    setShowNovo(true);
  };

  const handleCloseNovo = (): void => {
    setShowNovo(false);
  };
  const handleNovo = () => {
    setCarregandoBotao(true);
    const cupomPromocionalData = {
      nome: nome.toUpperCase(),
      desconto: desconto.replace(',', '.'),
      ativo,
    };

    api
      .post(
        `/cupompromocional/${usuario.idEstabelecimento}`,
        cupomPromocionalData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        setCarregandoBotao(false);
        setSnackBarSucesso(true);
        setMsg('Cadastro realizado com sucesso!');
        handleCloseNovo();
        handleAtualizar();
      })
      .catch((error) => {
        setCarregandoBotao(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleExcluir = (): void => {
    api
      .delete(`/cupompromocional/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setSnackBarSucesso(true);
        setMsg('Cupom Promocional excluido com sucesso!');
        setCarregandoBotao(false);
        setExibeMsg(false);
        handleAtualizar();
      })
      .catch((error) => {
        setCarregandoBotao(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleShowMsg = (
    idCupomPromocional: string | undefined | CellValue,
  ): void => {
    if (idCupomPromocional) {
      setId(idCupomPromocional?.toString());
      setExibeMsg(true);
    }
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarSucesso(false);
    setSnackBarErro(false);
  };

  const handleBuscaCupomPromocional = (
    idCupomPromocional: string | undefined | CellValue,
  ): void => {
    api
      .get(`/cupompromocional/edit/${idCupomPromocional}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const cupomPromocionalEdit = {
          id: response.data.id,
          nome: response.data.nome,
          desconto: response.data.desconto,
          ativo: response.data.ativo,
        };
        handleShowEditar(cupomPromocionalEdit);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  async function handleAtivo(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.value === 'S') {
      setAtivo('N');
      setAtivoBool(false);
    } else {
      setAtivo('S');
      setAtivoBool(true);
    }
  }

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Ações',
      width: 150,
      sortable: false,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Button
            size="small"
            onClick={() => handleBuscaCupomPromocional(params?.getValue('id'))}
          >
            <EditIcon />
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={() => handleShowMsg(params?.getValue('id'))}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
    { field: 'nome', headerName: 'Nome', width: 300 },
    {
      field: 'desconto',
      headerName: 'Desconto',
      width: 100,
      valueFormatter: (params: ValueFormatterParams) =>
        `% ${params.value?.toString().replace('.', ',')}`,
    },
    {
      field: 'ativo',
      headerName: 'Ativo',
      width: 70,
      valueFormatter: (params: ValueFormatterParams) =>
        params.value === 'S' ? 'Sim' : 'Não',
    },
  ];

  return (
    <>
      {/* TELA */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Cupom Promocional
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'right' }}>
          <ButtonNew
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleShowNovo}
          >
            Novo
          </ButtonNew>
        </Grid>
      </Grid>

      <div
        style={{
          height: 500,
          width: '100%',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
          marginTop: '24px',
        }}
      >
        <DataGrid
          rows={cupomPromocional}
          columns={columns}
          loading={load}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: LoadGrid,
          }}
        />
      </div>
      {/* FIM TELA */}

      {/* NOVO CUPOM PROMOCIONAL */}
      <Dialog
        fullScreen={fullScreen}
        open={showNovo}
        onClose={handleCloseNovo}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleNovo}>
          <DialogTitle className={classes.headerDialog}>
            Novo Cupom Promocional
          </DialogTitle>
          <FormGroup aria-label="position" row>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    required
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    label="Nome"
                    fullWidth
                    autoComplete="nome_cupom"
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    fullWidth
                    required
                    label="Desconto"
                    value={desconto}
                    onChange={(event) => {
                      setDesconto(event.target.value);
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControlLabel
                    value="start"
                    control={(
                      <SwitchPurple
                        checked={ativoBool === true}
                        onChange={handleAtivo}
                        color="primary"
                        value={ativo}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    )}
                    label="Ativo:"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={handleCloseNovo} variant="contained" autoFocus>
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Salvar
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      {/* FIM NOVO CUPOM PROMOCIONAL */}

      {/* EDITAR CUPOM PROMOCIONAL */}
      <Dialog
        fullScreen={fullScreen}
        open={showEditar}
        onClose={handleCloseEditar}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleEditar}>
          <DialogTitle className={classes.headerDialog}>
            Editar Cupom Promocional
          </DialogTitle>
          <FormGroup aria-label="position" row>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    required
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    label="Nome"
                    fullWidth
                    autoComplete="nome_cupom"
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    fullWidth
                    required
                    label="Desconto"
                    value={desconto}
                    onChange={(event) => {
                      setDesconto(event.target.value);
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControlLabel
                    value="start"
                    control={(
                      <SwitchPurple
                        checked={ativoBool === true}
                        onChange={handleAtivo}
                        color="primary"
                        value={ativo}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    )}
                    label="Ativo:"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={handleCloseEditar} variant="contained">
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Salvar
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      {/* FIM EDITAR CUOPOM PROMOCIONAL */}

      {/* INICIO DESEJA EXCLUIR */}
      {exibeMsg && (
        <Dialog
          open={exibeMsg}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setExibeMsg(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja excluir esse cupom?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExibeMsg(false)}
              color="secondary"
              variant="contained"
            >
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleExcluir}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Sim
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/* FIM DESEJA EXCLUIR */}

      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
