import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useAuth } from '../../hooks/auth';

import logoImg from '../../assets/logo.png';

import { ButtonPurple, TextFieldPurple } from '../../components/Global/index';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '.5rem',
    boxShadow: '0 0 1.8rem rgba(0,0,0,.15)',
    padding: '30px',
    marginBottom: '10px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -12,
    color: '#81259D',
  },
  div: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: '#81259D',
    backgroundSize: 'cover',
    textAlign: 'center',
  },
  img: {
    width: '95px',
    margin: '20px',
  },
  h1: {
    color: '#000',
  },
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SignIn = () => {
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');

  const [msg, setMsg] = useState('');
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [carregandoBotao, setCarregandoBotao] = useState(false);

  const classes = useStyles();
  const { signIn } = useAuth();
  const history = useHistory();

  async function handleLogin(): Promise<void> {
    setCarregandoBotao(true);
    try {
      await signIn({
        login,
        senha,
      });

      history.push('/orders');
      setCarregandoBotao(false);
    } catch (error) {
      setCarregandoBotao(false);
      setMsg(error.response.data.message);
      setSnackBarErro(true);
    }
  }

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarErro(false);
  };

  return (
    <>
      <div className={classes.div}>
        <img src={logoImg} className={classes.img} />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5" className={classes.h1}>
              Faça seu login
            </Typography>
            <Form className={classes.form} onSubmit={handleLogin} noValidate>
              <TextFieldPurple
                margin="normal"
                required
                fullWidth
                label="Usuário"
                autoComplete="usuario"
                autoFocus
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
              <TextFieldPurple
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />

              <div className={classes.wrapper}>
                <ButtonPurple
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={carregandoBotao}
                >
                  Entrar
                </ButtonPurple>
                {carregandoBotao && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Form>
          </div>
        </Container>

        {/* INICIO ALERT ERRO */}
        <Snackbar
          open={snackBarErro}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert onClose={handleCloseSnackBar} severity="error">
            {msg}
          </Alert>
        </Snackbar>
        {/* FIM ALERT ERRO */}
      </div>
    </>
  );
};
