import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Box, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Toolbar from '@material-ui/core/Toolbar';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { KeyboardDatePickerPurple } from '../../components/Global';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  filtro: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(1) },
  rootLoader: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    marginLeft: '50%',
  },
  tableContainer: {
    overflowY: 'auto',
    height: '300px',
  },
  cabecalho: {
    textAlign: 'center',
    backgroundColor: 'rgb(129, 37, 157)',
    color: 'white',
    padding: '3px',
    fontSize: '14px',
  },
  list: {
    width: '100%',
    overflowY: 'auto',
    maxHeight: '300px',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flex: '1 1 100%',
  },
}));

interface ICustomersDash {
  nomeCliente: string;
  valor: string;
  qtd: string;
}

interface IProductsDash {
  nomeProduto: string;
  qtd: string;
}

export const Dashboard = () => {
  const data = new Date();
  const [load, setLoad] = useState(true);
  const classes = useStyles();
  const [valorTotal, setValorTotal] = useState('0');
  const [pedidos, setPedidos] = useState('0');
  const [ticketMedio, setTicketMedio] = useState('0');
  const [customers, setCustomers] = useState<ICustomersDash[]>([]);
  const [products, setProducts] = useState<IProductsDash[]>([]);
  const [dataInicio, setDataInicio] = useState(
    new Date(data.getFullYear(), data.getMonth(), 1),
  );
  const [dataFim, setDataFim] = useState(new Date());
  const token = localStorage.getItem('@PecaAquiDashboard:token');
  const { usuario } = useAuth();

  useEffect(() => {
    setLoad(true);

    async function init() {
      try {
        const response = await api.get(
          `/pedidovendas/${
            usuario.idEstabelecimento
          }/dashboard/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setValorTotal(response.data.valortotal ? response.data.valortotal : 0);
        setPedidos(response.data.pedidos);
        setTicketMedio(
          response.data.ticketmedio ? response.data.ticketmedio : 0,
        );

        const responseCustomers = await api.get(
          `/pedidovendas/${
            usuario.idEstabelecimento
          }/dashboard/customers/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setCustomers(responseCustomers?.data);

        const responseProducts = await api.get(
          `/pedidovendas/${
            usuario.idEstabelecimento
          }/dashboard/products/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setProducts(responseProducts?.data);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    }
    init();
  }, [token, usuario.idEstabelecimento, dataFim, dataInicio]);

  const handleDateChangeInicio = (date) => {
    setDataInicio(date);
  };

  const handleDateChangeFim = (date) => {
    setDataFim(date);
  };

  return (
    <>
      {load ? (
        <div className={classes.rootLoader}>
          <CircularProgress style={{ color: '#81259D' }} />
        </div>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography component="h5" variant="h5" noWrap>
                Dashboard
              </Typography>
            </Grid>
          </Grid>
          <Toolbar
            className={clsx(classes.filtro)}
            style={{
              marginTop: '24px',
              background: '#fff',
              borderRadius: '4px',
              boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
              marginBottom: '10px',
            }}
          >
            <div className={classes.title} style={{ display: 'contents' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePickerPurple
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Início"
                  value={dataInicio}
                  onChange={handleDateChangeInicio}
                  size="small"
                  style={{ width: '25%', marginRight: '10px' }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePickerPurple
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Fim"
                  value={dataFim}
                  onChange={handleDateChangeFim}
                  size="small"
                  style={{ width: '25%', marginLeft: '10px' }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Toolbar>
          <Grid container spacing={1} style={{ marginTop: '5px' }}>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Card>
                <div style={{ textAlign: 'center' }}>
                  <Box fontSize={26} style={{ color: 'DEEPSKYBLUE' }}>
                    {pedidos}
                  </Box>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Box fontSize={16} style={{ color: 'gray' }}>
                    PEDIDOS
                  </Box>
                </div>
              </Card>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Card>
                <div style={{ textAlign: 'center' }}>
                  <Box fontSize={26} style={{ color: 'palevioletred' }}>
                    {parseFloat(valorTotal).toFixed(2).replace('.', ',')}
                  </Box>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Box fontSize={16} style={{ color: 'gray' }}>
                    FATURAMENTO
                  </Box>
                </div>
              </Card>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Card>
                <div style={{ textAlign: 'center' }}>
                  <Box fontSize={26} style={{ color: 'mediumpurple' }}>
                    {parseFloat(ticketMedio).toFixed(2).replace('.', ',')}
                  </Box>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Box fontSize={16} style={{ color: 'gray' }}>
                    TICKET MÉDIO
                  </Box>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div className={classes.cabecalho}>
                Quantidade de venda de produtos
              </div>
              <List className={classes.list}>
                {products &&
                  products.map((product) => (
                    <>
                      <ListItem>
                        <ListItemText primary={product.nomeProduto} />
                        <ListItemSecondaryAction>
                          {product.qtd}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className={classes.cabecalho}>Pedidos realizados</div>
              <List className={classes.list}>
                {customers &&
                  customers.map((customer) => (
                    <>
                      <ListItem>
                        <ListItemText
                          primary={customer.nomeCliente}
                          secondary={`Total: R$ ${customer.valor}`}
                        />
                        <ListItemSecondaryAction>
                          {customer.qtd}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
              </List>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
