import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import moment from 'moment'
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Toolbar from '@material-ui/core/Toolbar';
import RefreshIcon from '@material-ui/icons/Refresh';
import Grid from '@material-ui/core/Grid';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import 'date-fns';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  DataGrid,
  ColDef,
  GridOverlay,
  ValueFormatterParams,
  CellValue,
  SortDirection,
} from '@material-ui/data-grid';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import ImpressãoPedido from '../../components/ImpressãoPedido';
import {
  ButtonNew,
  KeyboardDatePickerPurple,
  TextFieldPurple,
} from '../../components/Global';
import { LoadGrid } from '../../components/LoadGrid';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtro: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(1) },
    root: {
      flexDirection: 'column',
      '& .ant-empty-img-1': {
        fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
      },
      '& .ant-empty-img-2': {
        fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
      },
      '& .ant-empty-img-3': {
        fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
      },
      '& .ant-empty-img-4': {
        fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
      },
      '& .ant-empty-img-5': {
        fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
        fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },

    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    button: {
      // color: blue[900],
      // margin: 10,
    },
    input: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    title: {
      flex: '1 1 100%',
    },
    table: {
      minWidth: 650,
    },
  }),
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>Não foi encontrado dados</div>
    </GridOverlay>
  );
}

interface IUsuarios {
  id: number;
  nome: string;
}

interface IPedidoVenda {
  id: number;
  id_pedido_venda_externo: number;
  idCliente: number;
  idEndereco: number;
  idTipoPagamento: number;
  idMotoboy: number;
  nome: string;
  observacao: string;
  idCupomPromocional: number;
  idRecompensa: number;
  status: string;
  origem: string;
  data: Date;
  tipo_entrega: string;
  valor_entrega: string;
  valor_total: string;
  valor_total_itens: string;
  valor_desconto: string;
  valor_acrescimo: string;
  valor_troco: string;
  pr_desconto: string;
  observacao_entrega: string;
  impresso: string;
  cliente: {
    nome: string;
    telefone: string;
  };
  usuario: {
    nome: string;
  };
  tipoPagamento: {
    descricao: string;
  };
  cupomPromocional: {
    nome: string;
  };
  recompensa: {
    descricao: string;
  };
  endereco: {
    rua: string;
    numero: number;
    complemento: string;
    ponto_referencia: string;
    idBairro: string;
    bairro_nome: string;
    cidade: {
      nome: string;
      valor_entrega: string;
    };
    bairro: {
      nome: string;
      valor_entrega: string;
    };
  };
  pedidoVendaItem: Array<IPedidoVendaItem>;
  pedidoVendaPizza: [
    {
      nomePizza: string;
      id: number;
      idBorda: number;
      idCarrinho: number;
      idTamanho: number;
      observacao: string;
      nomeBorda: string;
      valor: string;
      valor_borda: string;
      tamanho: { id: number; descricao: string; qt_sabor: number };
      borda?: { id: number; descricao: string };
      pedidoVendaItem: IPedidoVendaItem[];
    },
  ];
}

interface IPedidoVendaItemSugestao {
  idProduto: number;
  idAdicional: number;
  valor: string;
  adicional: {
    descricao: string;
  };
}

interface IPedidoVendaItem {
  id: number;
  idProduto: number;
  observacao: string;
  quantidade: number;
  nomeProduto: string;
  valor: string;
  idPedidoVendaPizza: number;
  produto: IProdutoItem;
  pedidoVendaItemAdicional: Array<IPedidoVendaItemAdicional> | undefined;
  pedidoVendaItemOpcaoGrupo: IPedidoVendaItemOpcaoGrupo[] | undefined;
  pedidoVendaItemOpcao: IPedidoVendaItemOpcao[] | undefined;
  pedidoVendaItemSugestao: IPedidoVendaItemSugestao[] | undefined;
}

interface IProdutoItem {
  nome: string;
  descricao: string;
  valor: string;
  codigoProduto: string;
  exibe_descricao_impressao: string;
}

interface IPedidoVendaItemAdicional {
  idProduto: number;
  idAdicional: number;
  valor: string;
  adicional: {
    descricao: string;
  };
  nomeAdicional: string;
}

interface IPedidoVendaItemOpcao {
  opcao: {
    descricao: string;
  };
  valor: string;
}

interface IPedidoVendaItemOpcaoGrupo {
  idOpcaoGrupo: number;
  idProduto: number;
}

export const Orders = () => {
  const { usuario } = useAuth();

  const [load, setLoad] = useState(true);
  const [novosPedidos, setNovosPedidos] = useState(false);
  const [pedidoVendas, setPedidoVendas] = useState<Array<IPedidoVenda>>([]);
  const [idPedidoFiltro, setIdPedidoFiltro] = useState('');
  const [usuarios, setUsuarios] = useState<Array<IUsuarios>>([]);
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');
  const [idPedido, setIdPedido] = useState('');
  const [exibeMsgImprimir1Via, setExibeMsgImprimir1Via] = useState(false);
  const [exibeMsgImprimir2Via, setExibeMsgImprimir2Via] = useState(false);
  const [imprimePedido, setImprimePedido] = useState(false);
  const [pedidoImpressao, setPedidoImpressao] = useState<IPedidoVenda>();
  const classes = useStyles();
  const [vias, setVias] = useState(0);
  const [usuarioSelect, setUsuarioSelect] = useState(0);

  const history = useHistory();

  let valor;
  valor = 0;

  const token = localStorage.getItem('@PecaAquiDashboard:token');

  useEffect(() => {
    setLoad(true);
    api
      .get(
        `/pedidovendas/${
          usuario.idEstabelecimento
        }/${moment(dataInicio).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(true)}&${moment(dataFim).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59).toISOString(true)}/usuario/${usuarioSelect}/pedido/${
          idPedidoFiltro.trim() || 0
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        response.data.forEach((element) => {
          if (element.status !== 'C') {
            valor += Number(element.valor_total);
          }
        });
        setPedidoVendas(response.data);
        handleCounter();

        api
          .get(`/usuarios/${usuario.idEstabelecimento}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((responseUsuario) => {
            // setUsuarioSelect(Number(usuario.id));
            setUsuarios(responseUsuario.data);
            setLoad(false);
          })
          .catch((error) => {
            setLoad(false);
            setSnackBarErro(true);
            setMsg(error.responseUsuario.data.message);
          });
      })
      .catch((error) => {
        setLoad(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  }, [token, dataInicio, dataFim, usuarioSelect, idPedidoFiltro]);

  const handleCounter = () => {
    setInterval(() => {
      api
        .get(`/pedidovendas/${usuario.idEstabelecimento}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const novosPedidos = response.data.filter((e) => e.status === 'P')
            ?.length;

          if (novosPedidos > 0) {
            setNovosPedidos(true);
            document.title = `(${novosPedidos}) PeçaAqui - Admin`;
          } else {
            setNovosPedidos(false);
            document.title = 'PeçaAqui - Admin';
          }
        })
        .catch((error) => {
          setSnackBarErro(true);
          setMsg(error.response.data.message);
        });
    }, 30000);
  };

  const handleAtualizar = () => {
    setLoad(true);
    setPedidoVendas([]);
    api
      .get(
        `/pedidovendas/${
          usuario.idEstabelecimento
        }/${moment(dataInicio).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(true)}&${moment(dataFim).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 0).toISOString(true)}/usuario/${usuarioSelect}/pedido/${
          idPedidoFiltro.trim() || 0
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        response.data.forEach((element) => {
          if (element.status !== 'C') {
            valor += Number(element.valor_total);
          }
        });

        setPedidoVendas(response.data);
        handleCounter();

        api
          .get(`/usuarios/${usuario.idEstabelecimento}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((responseUsuario) => {
            // setUsuarioSelect(Number(usuario.id));
            setUsuarios(responseUsuario.data);
          })
          .catch((error) => {
            setLoad(false);
            setSnackBarErro(true);
            setMsg(error.responseUsuario.data.message);
          });
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  async function imprimir(
    idPedido: string | undefined | CellValue,
  ): Promise<void> {
    api
      .get(`/usuarios/edit/${usuario.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (idPedido) {
          setIdPedido(idPedido?.toString());
        }
        if (response.data.imprime_comanda === 'S') {
          setExibeMsgImprimir2Via(true);
        } else {
          setExibeMsgImprimir1Via(true);
        }
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  }

  async function imprimirPedido(via: number): Promise<void> {
    setVias(via);

    const data = {
      impresso: 'S',
    };

    api.put(`/pedidovendas/alterarimpresso/${idPedido}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      const response = await api.get(`/pedidovendas/edit/${idPedido}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setExibeMsgImprimir2Via(false);

      setImprimePedido(true);
      setPedidoImpressao(response.data);

      setImprimePedido(false);
      setPedidoImpressao(undefined);

      handleAtualizar();
    } catch (error) {
      setMsg(error.response.data.message);
      setSnackBarErro(true);
    }
  }

  const handleAlteraStatus = (
    idPedido: string | undefined | CellValue,
    status: string,
  ) => {
    const atualizaStatus = {
      status,
    };
    api
      .put(`/pedidovendas/alterastatus/${idPedido}`, atualizaStatus, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((responsePedido) => {
        setSnackBarSucesso(true);
        setMsg('Status atualizado com sucesso!');
        handleAtualizar();
      });
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarSucesso(false);
    setSnackBarErro(false);
  };

  const optionsOrigem = [
    { value: 'S', label: 'Site' },
    { value: 'A', label: 'Admin' },
  ];

  const isFinished = (params: ValueFormatterParams): boolean => {
    return params?.getValue('status') === 'F';
  };

  const handleEditPedido = (idPedido: string | undefined | CellValue) => {
    history.push({
      pathname: `/orders/edit/${idPedido}`,
    });
  };

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Ações',
      width: 300,
      sortable: false,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Tooltip title="Editar">
            <Button
              size="small"
              onClick={() => handleEditPedido(params?.getValue('id'))}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Imprimir">
            {params?.getValue('impresso') === 'S' ? (
              <Button
                size="small"
                color="primary"
                onClick={() => imprimir(params?.getValue('id'))}
              >
                <PrintIcon />
              </Button>
            ) : (
              <Button
                color="secondary"
                onClick={() => imprimir(params?.getValue('id'))}
              >
                <PrintIcon />
              </Button>
            )}
          </Tooltip>
          <Tooltip title="Finalizar">
            <Button
              style={
                isFinished(params) ? { color: '#D3D3D3' } : { color: '#4caf50' }
              }
              size="small"
              onClick={() => handleAlteraStatus(params?.getValue('id'), 'F')}
              disabled={isFinished(params)}
            >
              <DoneIcon />
            </Button>
          </Tooltip>
          {params?.getValue('status') === 'A' &&
            params?.getValue('origem') === 'S' && (
              <Tooltip
                title={
                  params?.getValue('tipo_entrega') === 'REL'
                    ? 'Está Pronto'
                    : 'Em Rota de Entrega'
                }
              >
                <Button
                  style={{ color: '#81259D' }}
                  size="small"
                  onClick={() => {
                    handleAlteraStatus(
                      params?.getValue('id'),
                      params?.getValue('tipo_entrega') === 'REL' ? 'E' : 'R',
                    );
                  }}
                >
                  <MotorcycleIcon />
                </Button>
              </Tooltip>
            )}
        </>
      ),
    },
    {
      field: 'id_pedido_venda_externo',
      headerName: '#',
      width: 70,
    },
    {
      field: 'nome',
      headerName: 'Cliente',
      width: 100,
    },
    {
      field: 'data',
      headerName: 'Data Hora',
      width: 170,
      valueFormatter: (params: ValueFormatterParams) =>
        `${
          params.value?.toString() !== undefined
            ? `${moment(params.value?.toString()).utc().format('DD/MM/yyyy HH:mm:ss')}`
            : ''
        }`,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params: ValueFormatterParams) => (
        <span>
          {params.value?.toString() === 'P' ? (
            <span
              style={{
                background: '#ffc107',
                display: 'inline',
                padding: '.2em .6em .3em',
                fontWeight: 'bold',
                lineHeight: '1',
                color: '#fff',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '.25em',
              }}
            >
              Pendente
            </span>
          ) : params.value?.toString() === 'A' ? (
            <span
              style={{
                background: '#007bff',
                display: 'inline',
                padding: '.2em .6em .3em',
                fontWeight: 'bold',
                lineHeight: '1',
                color: '#fff',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '.25em',
              }}
            >
              Em Produção
            </span>
          ) : params.value?.toString() === 'R' ? (
            <span
              style={{
                background: 'purple',
                display: 'inline',
                padding: '.2em .6em .3em',
                fontWeight: 'bold',
                lineHeight: '1',
                color: '#fff',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '.25em',
              }}
            >
              Rota de Entrega
            </span>
          ) : params.value?.toString() === 'E' ? (
            <span
              style={{
                background: 'purple',
                display: 'inline',
                padding: '.2em .6em .3em',
                fontWeight: 'bold',
                lineHeight: '1',
                color: '#fff',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '.25em',
              }}
            >
              Está Pronto
            </span>
          ) : params.value?.toString() === 'F' ? (
            <span
              style={{
                background: '#28a745',
                display: 'inline',
                padding: '.2em .6em .3em',
                fontWeight: 'bold',
                lineHeight: '1',
                color: '#fff',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '.25em',
              }}
            >
              Finalizado
            </span>
          ) : params.value?.toString() === 'C' ? (
            <span
              className="label label-success"
              style={{
                background: '#dc3545',
                display: 'inline',
                padding: '.2em .6em .3em',
                fontWeight: 'bold',
                lineHeight: '1',
                color: '#fff',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '.25em',
              }}
            >
              Cancelado
            </span>
          ) : (
            'Outros'
          )}
        </span>
      ),
    },
    {
      field: 'origem',
      headerName: 'Origem',
      width: 79,
      valueFormatter: (params: ValueFormatterParams) =>
        optionsOrigem &&
        optionsOrigem?.find((e) => e.value === params.value)?.label,
    },
    {
      field: 'valor_total',
      headerName: 'Valor',
      width: 84,
      valueFormatter: (params: ValueFormatterParams) =>
        `${params.value?.toString().replace('.', ',')}`,
    },
    {
      field: 'nomeUsuario',
      headerName: 'Usuário',
      width: 100,
    },
    { field: 'observacao', headerName: 'Observação', width: 250 },
    {
      field: 'observacao_entrega',
      headerName: 'Observação Entrega',
      width: 300,
    },
  ];

  const handleDateChangeInicio = (date) => {
    setDataInicio(date);
  };

  const handleDateChangeFim = (date) => {
    setDataFim(date);
  };

  const sortModel = [
    {
      field: 'id',
      sort: 'desc' as SortDirection,
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Pedidos
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="default"
            startIcon={<RefreshIcon />}
            onClick={handleAtualizar}
            style={{ marginRight: '2px' }}
          >
            ATUALIZAR
          </Button>
          <ButtonNew
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() =>
              history.push({
                pathname: '/orders/add',
              })
            }
          >
            NOVO
          </ButtonNew>
        </Grid>
      </Grid>
      {novosPedidos === true ? (
        <span
          style={{
            background: 'red',
            color: 'white',
            fontSize: '15px',
            display: 'flex',
            padding: '.2em .6em .3em',
            fontWeight: 'bold',
            lineHeight: '1',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'baseline',
            borderRadius: '.25em',
            justifyContent: 'center',
            marginTop: '24px',
          }}
        >
          Há pedidos para serem aprovados
        </span>
      ) : (
        ''
      )}
      <Toolbar
        className={clsx(classes.filtro)}
        style={{
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
          marginBottom: '10px',
          marginTop: '24px',
        }}
      >
        <div className={classes.title} style={{ padding: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <small>Nº Pedido: </small>
              <TextFieldPurple
                value={idPedidoFiltro}
                onChange={(e) => setIdPedidoFiltro(e.target.value)}
                fullWidth
                variant="standard"
                size="small"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <small>Usuário: </small>
              <TextFieldPurple
                select
                value={usuarioSelect}
                onChange={(e) => setUsuarioSelect(Number(e.target.value))}
                fullWidth
              >
                <MenuItem value="0">
                  <em>Todos</em>
                </MenuItem>
                {usuarios.map((usu) => (
                  <MenuItem value={usu.id}>{usu.nome}</MenuItem>
                ))}
              </TextFieldPurple>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePickerPurple
                  disableToolbar
                  fullWidth
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Início"
                  value={dataInicio}
                  onChange={handleDateChangeInicio}
                  size="small"
                  // style={{ marginRight: '10px' }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePickerPurple
                  disableToolbar
                  fullWidth
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Fim"
                  value={dataFim}
                  onChange={handleDateChangeFim}
                  size="small"
                  // style={{ marginLeft: '10px' }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </div>
      </Toolbar>
      <div
        style={{
          height: 500,
          width: '100%',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        }}
      >
        <DataGrid
          rows={pedidoVendas}
          columns={columns}
          sortModel={sortModel}
          loading={load}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: LoadGrid,
          }}
        />
      </div>
      {imprimePedido && pedidoImpressao && (
        <div style={{ marginTop: '200px' }}>
          <ImpressãoPedido vias={vias} pedido={pedidoImpressao} />
        </div>
      )}

      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}

      {/* INICIO DESEJA IMPRIMIR */}
      {exibeMsgImprimir1Via && (
        <Dialog
          open={exibeMsgImprimir1Via}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setExibeMsgImprimir1Via(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja imprimir?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setExibeMsgImprimir1Via(false);
              }}
              color="secondary"
              variant="contained"
            >
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={() => {
                  setExibeMsgImprimir1Via(false);
                  setExibeMsgImprimir2Via(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                }}
              >
                Sim
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}

      {/* INICIO DESEJA IMPRIMIR */}
      {exibeMsgImprimir2Via && (
        <Dialog
          open={exibeMsgImprimir2Via}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setExibeMsgImprimir1Via(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja imprimir a 2º via?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => imprimirPedido(1)} variant="contained">
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={() => imprimirPedido(2)}
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                }}
                variant="contained"
              >
                Sim
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
