import React, { useState, useEffect } from 'react';

import {
  Snackbar,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {
  DataGrid,
  ColDef,
  ValueFormatterParams,
  CellValue,
} from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAuth } from '../../hooks/auth';
import { useStyles } from './styles';
import { NoRows } from '../../components/NoRows';
import { deleteReward, loadRewards } from './actions';
import { ButtonNew } from '../../components/Global/index';
import { LoadGrid } from '../../components/LoadGrid';
import RewardNew from './components/Add';
import RewardEdit from './components/Edit';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomNoRowsOverlay() {
  return <NoRows />;
}

export const Reward = () => {
  const [id, setId] = useState(0);
  const [displayMsg, setDisplayMsg] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [load, setLoad] = useState(true);
  const [loadButton, setLoadButton] = useState(false);
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [msg, setMsg] = useState('');
  const classes = useStyles();
  const { usuario } = useAuth();
  const token = localStorage.getItem('@PecaAquiDashboard:token');

  useEffect(() => {
    setLoad(true);

    async function init() {
      try {
        const response = await loadRewards(usuario.idEstabelecimento, token);
        if (response?.data) {
          setRewards(response.data);
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
        setMsg('Algum erro ocorreu, tente novamente mais tarde!');
        setSnackBarErro(true);
      }
    }

    init();
  }, [usuario.idEstabelecimento, token]);

  const handleUpdate = async () => {
    setLoad(true);
    setRewards([]);
    try {
      const response = await loadRewards(usuario.idEstabelecimento, token);
      if (response?.data) {
        setRewards(response.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleShowNew = async (): Promise<void> => {
    setShowNew(true);
  };

  const handleCloseNew = (save: boolean): void => {
    setShowNew(false);

    if (save) {
      setSnackBarSucesso(true);
      setMsg('Cadastro realizado com sucesso!');
      handleUpdate();
    }
  };

  const handleShowEdit = async (): Promise<void> => {
    setShowEdit(true);
  };

  const handleCloseEdit = (save: boolean): void => {
    setShowEdit(false);
    if (save) {
      setSnackBarSucesso(true);
      setMsg('Cadastro editado com sucesso!');
      handleUpdate();
    }
  };

  const handleDelete = async () => {
    setLoadButton(true);

    try {
      await deleteReward(id, token);
      setLoadButton(false);
      setDisplayMsg(false);
      setSnackBarSucesso(true);
      setMsg('Recompensa excluida com sucesso!');
      handleUpdate();
    } catch (error) {
      setLoadButton(false);
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarSucesso(false);
    setSnackBarErro(false);
  };

  const optionsTypeReward = [
    { value: 'C', label: 'Vale Compra' },
    { value: 'D', label: 'Vale Desconto' },
  ];

  const handleShowMsg = (idReward: string | undefined | CellValue): void => {
    if (idReward) {
      setId(Number(idReward));
      setDisplayMsg(true);
    }
  };

  const handleSearchReward = async (
    idReward: string | undefined | CellValue,
  ) => {
    setId(Number(idReward));
    handleShowEdit();
  };

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Ações',
      width: 150,
      sortable: false,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Button
            size="small"
            onClick={() => handleSearchReward(params?.getValue('id'))}
          >
            <EditIcon />
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={() => handleShowMsg(params?.getValue('id'))}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 250,
    },
    {
      field: 'qt_pontos_atingir',
      headerName: 'Qtd. Pontos Atingir',
      width: 200,
    },
    {
      field: 'tipo_premio',
      headerName: 'Tipo Prêmio',
      width: 150,
      valueFormatter: (params: ValueFormatterParams) =>
        optionsTypeReward &&
        optionsTypeReward?.find((e) => e.value === params.value)?.label,
    },
  ];

  return (
    <>
      {/* TELA */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Recompensas
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridRight}>
          <ButtonNew
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleShowNew}
          >
            Novo
          </ButtonNew>
        </Grid>
      </Grid>
      <div className={classes.dataGrid}>
        <DataGrid
          rows={rewards}
          columns={columns}
          loading={load}
          components={{
            loadingOverlay: LoadGrid,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </div>
      {/* FIM TELA */}

      {showNew && (
        <RewardNew
          open={showNew}
          handleClose={handleCloseNew}
          idEstablishment={usuario.idEstabelecimento}
        />
      )}

      {showNew && (
        <RewardNew
          open={showNew}
          handleClose={handleCloseNew}
          idEstablishment={usuario.idEstabelecimento}
        />
      )}

      {showEdit && (
        <RewardEdit
          open={showEdit}
          handleClose={handleCloseEdit}
          idEstablishment={usuario.idEstabelecimento}
          idReward={id}
        />
      )}

      {/* INICIO DESEJA EXCLUIR */}
      {displayMsg && (
        <Dialog
          open={displayMsg}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setDisplayMsg(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja excluir essa recompensa?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDisplayMsg(false)}
              color="secondary"
              variant="contained"
            >
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleDelete}
                color="primary"
                variant="contained"
                className={classes.buttonSuccess}
                disabled={loadButton}
              >
                Sim
              </Button>
              {loadButton && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/* FIM DESEJA EXCLUIR */}
      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}
      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
