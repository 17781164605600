import React, { useState, useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@unform/web';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';

import { telefoneMask } from '../../../../hooks/mask';
import { loadCities } from '../../../Settings/Cities/actions';
import { editCustomer, getByIdCustomers, loadAddresses } from '../../actions';
import { getNeighborhoodsByIdCity } from '../../../Settings/Neighborhoods/actions';
import { ISelectCidades, IEnderecos, ISelectBairros } from '../../dtos';
import { useStyles } from '../../styles';
import { ButtonPurple, TextFieldPurple } from '../../../../components/Global';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface ItemDialogProps {
  open: boolean;
  handleClose(salvar: boolean): void;
  idEstablishment: number;
  idCustomer: number;
}

const Edit: React.FC<ItemDialogProps> = ({
  open,
  handleClose,
  idEstablishment,
  idCustomer,
}) => {
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');

  const [idCidade, setIdCidade] = useState('');
  const [nomeCidade, setNomeCidade] = useState('');
  const [idBairro, setIdBairro] = useState('');
  const [nomeBairro, setNomeBairro] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [pontoReferencia, setPontoReferencia] = useState('');

  const [cidades, setCidades] = useState<ISelectCidades[]>();
  const [enderecos, setEnderecos] = useState<IEnderecos[]>([]);
  const [bairros, setBairros] = useState<ISelectBairros[]>([]);

  const [msg, setMsg] = useState('');
  const [aba, setAba] = useState('1');
  const [loadButton, setLoadButton] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const token = localStorage.getItem('@PecaAquiDashboard:token');

  useEffect(() => {
    async function init() {
      try {
        const response = await getByIdCustomers(idCustomer, token);

        setId(response.data.id);
        setNome(response.data.nome);
        setTelefone(telefoneMask(response.data.telefone));

        const responseCities = await loadCities(idEstablishment, token);
        setCidades(responseCities.data);

        const responseAddress = await loadAddresses(response.data.id, token);
        setEnderecos(responseAddress.data);
      } catch (error) {
        setMsg('Algum erro ocorreu, tente novamente mais tarde!');
        setSnackBarErro(true);
      }
    }
    init();
  }, [idCustomer, idEstablishment, token]);

  const checkRequiredFields = async (): Promise<boolean> => {
    if (!nome) {
      setSnackBarErro(true);
      setMsg('Nome do cliente é obrigatório!');
      return false;
    }

    if (!telefone) {
      setSnackBarErro(true);
      setMsg('Celular do cliente é obrigatório!');
      return false;
    }

    return true;
  };

  const handleNew = async (): Promise<void> => {
    setLoadButton(true);

    const ret = await checkRequiredFields();

    if (!ret) {
      setLoadButton(false);
      return;
    }

    const data = {
      nome,
      telefone: telefone?.replace('(', '').replace(')', '').replace('-', ''),
      enderecos,
    };

    try {
      await editCustomer(data, id, token);

      setLoadButton(false);
      handleClose(true);
    } catch (error) {
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  async function handleCity(
    idCidadeHandle: string,
    nomeCidadeHandle: string,
  ): Promise<void> {
    setIdCidade(idCidadeHandle);
    setNomeCidade(nomeCidadeHandle);

    try {
      const response = await getNeighborhoodsByIdCity(idCidadeHandle, token);
      setBairros(response.data);
    } catch (error) {
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  }

  async function handleAddAddress(): Promise<void> {
    if (!idCidade) {
      setSnackBarErro(true);
      setMsg('Cidade é obrigatório!');
      return;
    }

    if (!idBairro) {
      setSnackBarErro(true);
      setMsg('Bairro é obrigatório!');
      return;
    }

    if (!rua) {
      setSnackBarErro(true);
      setMsg('Rua é obrigatório!');
      return;
    }

    if (!numero) {
      setSnackBarErro(true);
      setMsg('Número é obrigatório!');
      return;
    }

    setEnderecos((old) => [
      ...old,
      {
        idCidade,
        idBairro,
        rua,
        numero,
        complemento,
        ponto_referencia: pontoReferencia,
        cidade: {
          nome: nomeCidade,
        },
        bairro: {
          nome: nomeBairro,
        },
      },
    ]);

    setIdCidade('');
    setNomeCidade('');
    setIdBairro('');
    setNomeBairro('');
    setRua('');
    setNumero('');
    setComplemento('');
    setPontoReferencia('');
  }

  async function handleRemoveAddress(index: number): Promise<void> {
    enderecos.splice(index, 1);
    setEnderecos([...enderecos]);
  }

  const handleChangeAba = (event: React.ChangeEvent<{}>, newValue: string) => {
    setAba(newValue);
  };

  async function handleTelefone(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    setTelefone(telefoneMask(e.target.value));
  }

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarErro(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleNew}>
          <DialogTitle className={classes.headerDialog}>
            Editar Cliente
          </DialogTitle>

          <FormGroup aria-label="position" row>
            <DialogContent>
              <TabContext value={aba}>
                <AppBar position="static" color="default">
                  <TabList
                    onChange={handleChangeAba}
                    aria-label="simple tabs example"
                    centered
                  >
                    <Tab label="Geral" value="1" />
                    <Tab label="Endereços" value="2" />
                  </TabList>
                </AppBar>
                <TabPanel value="1">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextFieldPurple
                        required
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        label="Nome"
                        fullWidth
                        autoComplete="nome_cliente"
                        variant="standard"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextFieldPurple
                        required
                        value={telefone}
                        onChange={handleTelefone}
                        label="Celular"
                        fullWidth
                        autoComplete="telefone_cliente"
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        id="standard-select-currency"
                        select
                        fullWidth
                        label="Cidade"
                        value={idCidade}
                        onChange={(e) => {
                          handleCity(e.target.value, e.currentTarget.id);
                        }}
                        variant="standard"
                        size="small"
                      >
                        {cidades &&
                          cidades.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              id={option.nome}
                            >
                              {option.nome}
                            </MenuItem>
                          ))}
                      </TextFieldPurple>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        id="standard-select-currency"
                        select
                        fullWidth
                        label="Bairro"
                        value={idBairro}
                        onChange={(e) => {
                          setIdBairro(e.target.value);
                          setNomeBairro(e.currentTarget.id);
                        }}
                        variant="standard"
                        size="small"
                      >
                        {bairros &&
                          bairros.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              id={option.nome}
                            >
                              {option.nome}
                            </MenuItem>
                          ))}
                      </TextFieldPurple>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <TextFieldPurple
                        value={rua}
                        onChange={(e) => setRua(e.target.value)}
                        label="Rua"
                        fullWidth
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextFieldPurple
                        value={numero}
                        onChange={(e) => setNumero(e.target.value)}
                        label="Número"
                        fullWidth
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        value={complemento}
                        onChange={(e) => setComplemento(e.target.value)}
                        label="Complemento"
                        fullWidth
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextFieldPurple
                        value={pontoReferencia}
                        onChange={(e) => setPontoReferencia(e.target.value)}
                        label="Ponto de Referência"
                        fullWidth
                        variant="standard"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.divAdd}>
                    <ButtonPurple
                      variant="contained"
                      color="primary"
                      onClick={handleAddAddress}
                    >
                      Adicionar
                    </ButtonPurple>
                  </div>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Cidade</TableCell>
                          <TableCell>Bairro</TableCell>
                          <TableCell>Rua</TableCell>
                          <TableCell>Nº</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {enderecos &&
                          enderecos.map((endereco, index) => (
                            <TableRow key={index}>
                              <TableCell
                                style={{ width: '10%', maxWidth: '10%' }}
                              >
                                {endereco.cidade.nome}
                              </TableCell>
                              <TableCell
                                style={{ width: '35%', maxWidth: '35%' }}
                              >
                                {endereco.bairro.nome}
                              </TableCell>
                              <TableCell
                                style={{ width: '40%', maxWidth: '40%' }}
                              >
                                {endereco.rua}
                              </TableCell>
                              <TableCell
                                style={{ width: '10%', maxWidth: '10%' }}
                              >
                                {endereco.numero}
                              </TableCell>
                              <TableCell
                                style={{ width: '5%', maxWidth: '5%' }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleRemoveAddress(index)}
                                  className={classes.buttonDelete}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabContext>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={() => handleClose(false)} variant="contained">
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.buttonSuccess}
                disabled={loadButton}
              >
                Salvar
              </Button>
              {loadButton && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Edit;
