import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    font-family: "Roboto", Sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    margin: 0;
    font-family: "Roboto", Sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #687281;
    text-align: left;
    background-color: #F0F0F0;
  }

  input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

  button {
    cursor: pointer;
  }

`;
