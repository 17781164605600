import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    button: {
      margin: 10,
    },
    input: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      color: '#fff',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    gridRight: {
      textAlign: 'right',
    },
    dataGrid: {
      marginTop: '24px',
      height: 500,
      width: '100%',
      background: '#fff',
      borderRadius: '4px',
      boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
    },
    divAdd: {
      marginTop: '20px',
    },
    buttonDelete: {
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '2px',
    },
    buttonSuccess: {
      backgroundColor: '#4caf50 !important',
    },
    headerDialog: {
      backgroundColor: '#81259D',
      color: '#fff',
    },
  }),
);
