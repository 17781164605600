import React, { useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';

import { useAuth } from '../../hooks/auth';

interface ITotalMotoboy {
  count: number;
  id: number;
  idMotoboy: number;
  nome: string;
  valor_total: string;
  valor_entrega: string;
}

interface ITotalMotoboyImpresso {
  valorTotal: number;
  data: ITotalMotoboy[];
}

interface ImprimirFechamentoCaixaProps {
  totalMotoboy: ITotalMotoboyImpresso;
}

const ImpressaoFechamentoCaixa: React.FC<ImprimirFechamentoCaixaProps> = ({
  totalMotoboy,
}) => {
  const { usuario } = useAuth();

  useEffect(() => {
    const mywindow = window.open('', 'PRINT', 'width=200,height=100');

    mywindow?.document.write(
      `<div style="font-family:Arial;">
      ${document.getElementById('impressao')?.innerHTML} </div>`,
    );

    mywindow?.document.close(); // necessary for IE >= 10
    mywindow?.focus(); // necessary for IE >= 10*/
    mywindow?.print();
    mywindow?.close();
  });

  return (
    <div id="impressao">
      {usuario?.estabelecimento?.imagem_url ? (
        <>
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <img
              alt="logo"
              src={usuario?.estabelecimento?.imagem_url}
              style={{
                maxHeight: '64px',
                maxWidth: '72px',
              }}
            />
          </div>
          <br />
        </>
      ) : (
        ''
      )}
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '22px',
        }}
      >
        {usuario.estabelecimento.nome}
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '16px',
        }}
      >
        RELATÓRIO DE FECHAMENTO DE CAIXA
      </div>
      <hr />
      <Table
        aria-label="simple table"
        style={{ fontSize: '20px', width: '100%' }}
      >
        <TableHead>
          <TableRow>
            <td align="left" style={{ fontWeight: 'bold', width: '40%' }}>
              MOTOBOY
              <hr />
            </td>
            <td align="center" style={{ fontWeight: 'bold', width: '10%' }}>
              QTDE
              <hr />
            </td>
            <td align="right" style={{ fontWeight: 'bold', width: '25%' }}>
              ENTREGA (R$)
              <hr />
            </td>
            <td align="right" style={{ fontWeight: 'bold', width: '25%' }}>
              TOTAL (R$)
              <hr />
            </td>
          </TableRow>
        </TableHead>
        <TableBody>
          {totalMotoboy.data.map((totalMot) => (
            <tr>
              <td style={{ textAlign: 'left' }}>{totalMot.nome}</td>
              <td style={{ textAlign: 'center' }}>{totalMot.count}</td>
              <td style={{ textAlign: 'right' }}>
                {totalMot.valor_entrega.replace('.', ',')}
              </td>
              <td style={{ textAlign: 'right' }}>
                {totalMot.valor_total.replace('.', ',')}
              </td>
            </tr>
          ))}
        </TableBody>
        <TableFooter>
          <tr>
            <td align="left" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              <br />
              <hr />
              TOTAL
            </td>
            <td>
              <hr />
            </td>
            <td>
              <hr />
            </td>
            <td align="right" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              <br />
              <hr />
              {totalMotoboy.valorTotal.toFixed(2).replace('.', ',')}
            </td>
          </tr>
        </TableFooter>
      </Table>
    </div>
  );
};
export default ImpressaoFechamentoCaixa;
