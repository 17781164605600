import React, { useState, useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  DataGrid,
  ColDef,
  ValueFormatterParams,
  CellValue,
} from '@material-ui/data-grid';

import { Form } from '@unform/web';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import { NoRows } from '../../../components/NoRows';
import { useStyles } from './styles';
import { loadCities } from './actions';
import { ButtonNew, TextFieldPurple } from '../../../components/Global';
import { LoadGrid } from '../../../components/LoadGrid';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomNoRowsOverlay() {
  return <NoRows />;
}

export const Cities = () => {
  const [cidades, setCidades] = useState([]);
  const [showNovo, setShowNovo] = useState(false);
  const [showEditar, setShowEditar] = useState(false);
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [exibeMsg, setExibeMsg] = useState(false);
  const [load, setLoad] = useState(true);
  const [carregandoBotao, setCarregandoBotao] = useState(false);
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const token = localStorage.getItem('@PecaAquiDashboard:token');
  const { usuario } = useAuth();

  useEffect(() => {
    setLoad(true);

    async function init() {
      try {
        const response = await loadCities(usuario.idEstabelecimento, token);
        if (response?.data) {
          setCidades(response.data);
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
        setMsg('Algum erro ocorreu, tente novamente mais tarde!');
        setSnackBarErro(true);
      }
    }

    init();
  }, [token, usuario.idEstabelecimento]);

  const handleAtualizar = () => {
    setLoad(true);
    setCidades([]);
    api
      .get(`/cidades/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCidades(response.data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleShowEditar = (cidade): void => {
    setShowEditar(true);
    setId(cidade.id);
    setNome(cidade.nome);
  };

  const handleCloseEditar = (): void => {
    setShowEditar(false);
    setId('');
    setNome('');
  };

  const handleEditar = () => {
    setCarregandoBotao(true);
    const cidade = {
      nome,
    };

    api
      .put(`/cidades/${id}`, cidade, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setCarregandoBotao(false);
        handleCloseEditar();
        setSnackBarSucesso(true);
        setMsg('Cadastro editado com sucesso!');
        handleAtualizar();
      })
      .catch((error) => {
        setCarregandoBotao(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleShowNovo = (): void => {
    setShowNovo(true);
  };

  const handleCloseNovo = (): void => {
    setShowNovo(false);
    setId('');
    setNome('');
  };

  const handleNovo = () => {
    setCarregandoBotao(true);
    const cidade = {
      nome,
    };

    api
      .post(`/cidades/${usuario.idEstabelecimento}`, cidade, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setCarregandoBotao(false);
        handleCloseNovo();
        setSnackBarSucesso(true);
        setMsg('Cadastro realizado com sucesso!');
        handleAtualizar();
      })
      .catch((error) => {
        setCarregandoBotao(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleExcluir = (): void => {
    setCarregandoBotao(true);
    api
      .delete(`/cidades/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setCarregandoBotao(false);
        setExibeMsg(false);
        setSnackBarSucesso(true);
        setMsg('Cidade excluida com sucesso!');

        handleAtualizar();
      })
      .catch((error) => {
        setCarregandoBotao(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleShowMsg = (idCidade: string | undefined | CellValue): void => {
    if (idCidade) {
      setId(idCidade?.toString());
      setExibeMsg(true);
    }
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarSucesso(false);
    setSnackBarErro(false);
  };

  const handleBuscaCidade = (
    idCidade: string | undefined | CellValue,
  ): void => {
    api
      .get(`/cidades/edit/${idCidade}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const cidade = {
          id: response.data.id,
          nome: response.data.nome,
        };
        handleShowEditar(cidade);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Ações',
      width: 150,
      sortable: false,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Button
            size="small"
            onClick={() => handleBuscaCidade(params?.getValue('id'))}
          >
            <EditIcon />
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={() => handleShowMsg(params?.getValue('id'))}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
    { field: 'nome', headerName: 'Nome', width: 400 },
  ];

  return (
    <>
      {/* TELA */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Cidades
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'right' }}>
          <ButtonNew
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleShowNovo}
          >
            Novo
          </ButtonNew>
        </Grid>
      </Grid>

      <div
        style={{
          height: 500,
          width: '100%',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
          marginTop: '24px',
        }}
      >
        <DataGrid
          rows={cidades}
          columns={columns}
          loading={load}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: LoadGrid,
          }}
        />
      </div>
      {/* FIM TELA */}

      {/* NOVA CIDADE */}
      <Dialog
        fullScreen={fullScreen}
        open={showNovo}
        onClose={handleCloseNovo}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleNovo}>
          <DialogTitle className={classes.headerDialog}>
            Nova Cidade
          </DialogTitle>
          <FormGroup aria-label="position" row>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    required
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    label="Nome"
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={handleCloseNovo} variant="contained">
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Salvar
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      {/* FIM NOVA CIDADE */}

      {/* EDITAR CIDADE */}
      <Dialog
        fullScreen={fullScreen}
        open={showEditar}
        onClose={handleCloseEditar}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleEditar}>
          <DialogTitle className={classes.headerDialog}>
            Editar Cidade
          </DialogTitle>
          <FormGroup aria-label="position" row>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    required
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    label="Nome"
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={handleCloseEditar} variant="contained">
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Salvar
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      {/* FIM EDITAR CIDADE */}

      {/* INICIO DESEJA EXCLUIR */}
      {exibeMsg && (
        <Dialog
          open={exibeMsg}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setExibeMsg(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja excluir essa cidade?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExibeMsg(false)}
              color="secondary"
              variant="contained"
            >
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleExcluir}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: '#4caf50',
                }}
                disabled={carregandoBotao}
              >
                Sim
              </Button>
              {carregandoBotao && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/* FIM DESEJA EXCLUIR */}

      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
