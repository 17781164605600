import React from 'react';
import {
  Route,
  Switch,
  RouteProps,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { isAuthenticated } from '../../hooks/auth';

import { Dashboard } from '../../pages/Dashboard';
import { CashFlow } from '../../pages/CashFlow';
import { Customers } from '../../pages/Customers';
import { TypesOfPayments } from '../../pages/TypesOfPayments';
import { Users } from '../../pages/Users';
import { Additional } from '../../pages/Menu/Additional';
import { Categories } from '../../pages/Menu/Categories';
import { Edges } from '../../pages/Menu/Edges';
import { Options } from '../../pages/Menu/Options';
import { Products } from '../../pages/Menu/Products';
import { Sizes } from '../../pages/Menu/Sizes';
import { SubCategories } from '../../pages/Menu/SubCategories';
import { Orders } from '../../pages/Orders';
import { OrdersAdd } from '../../pages/Orders/Add';
import { OrdersEdit } from '../../pages/Orders/Edit';
import { Motoboys } from '../../pages/Delivery/Motoboys';
import { MotoboysDay } from '../../pages/Delivery/MotoboysDay';
import { TotalByMotoboys } from '../../pages/Delivery/TotalByMotoboys';
import { Cities } from '../../pages/Settings/Cities';
import { Settings } from '../../pages/Settings';
import { Neighborhoods } from '../../pages/Settings/Neighborhoods';
import { Schedules } from '../../pages/Settings/Schedules';
import { SignIn } from '../../pages/SignIn';
import { PromotionalCoupon } from '../../pages/PromotionalCoupon';
import { Reward } from '../../pages/Reward';

interface PrivateRouteProps extends RouteProps {
  redirect: string;
}

const PrivateRoute = ({
  children,
  redirect,
  path,
  ...props
}: PrivateRouteProps) => {
  return (
    <Route path={path} {...props}>
      {isAuthenticated() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: redirect,
            // search: `?${qs.stringify({
            //   redirectTo: path,
            // })}`,
          }}
        />
      )}
    </Route>
  );
};

const Content: React.FC = () => {
  const location = useLocation();

  return (
    <div>
      <Switch location={location}>
        <Route
          exact
          path="/signin"
          render={() =>
            isAuthenticated() ? (
              <Redirect to="/" />
            ) : (
              <div key="signin">
                <SignIn />
              </div>
            )}
        />
        <PrivateRoute exact path="/" redirect="/signin">
          <div key="dashboard">
            <Dashboard />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/cash-flow" redirect="/signin">
          <div key="cashflow">
            <CashFlow />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/customers" redirect="/signin">
          <div key="customers">
            <Customers />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/promotional-coupon" redirect="/signin">
          <div key="promotionalcoupon">
            <PromotionalCoupon />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/Rewards" redirect="/signin">
          <div key="reward">
            <Reward />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/types-of-payments" redirect="/signin">
          <div key="typesofpayments">
            <TypesOfPayments />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/users" redirect="/signin">
          <div key="users">
            <Users />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/delivery/motoboys" redirect="/signin">
          <div key="motoboys">
            <Motoboys />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/delivery/motoboys-day" redirect="/signin">
          <div key="motoboysday">
            <MotoboysDay />
          </div>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/delivery/total-by-motoboys"
          redirect="/signin"
        >
          <div key="totalbymotoboys">
            <TotalByMotoboys />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/menu/additional" redirect="/signin">
          <div key="additional">
            <Additional />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/menu/categories" redirect="/signin">
          <div key="categories">
            <Categories />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/menu/edges" redirect="/signin">
          <div key="edges">
            <Edges />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/menu/options" redirect="/signin">
          <div key="options">
            <Options />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/menu/products" redirect="/signin">
          <div key="products">
            <Products />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/menu/sizes" redirect="/signin">
          <div key="sizes">
            <Sizes />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/menu/subcategories" redirect="/signin">
          <div key="subcategories">
            <SubCategories />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/orders" redirect="/signin">
          <div key="orders">
            <Orders />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/orders/add" redirect="/signin">
          <div key="ordersadd">
            <OrdersAdd />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/orders/edit/:id" redirect="/signin">
          <div key="ordersedit">
            <OrdersEdit />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/settings/cities" redirect="/signin">
          <div key="cities">
            <Cities />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/settings" redirect="/signin">
          <div key="settings">
            <Settings />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/settings/neighborhoods" redirect="/signin">
          <div key="neighborhoods">
            <Neighborhoods />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/settings/schedules" redirect="/signin">
          <div key="schedules">
            <Schedules />
          </div>
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default Content;
