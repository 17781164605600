import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import DialogContentText from '@material-ui/core/DialogContentText';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import NumberFormat from 'react-number-format';
import Tab from '@material-ui/core/Tab';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { DebounceInput } from 'react-debounce-input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import StorageIcon from '@material-ui/icons/Storage';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

import ImpressãoPedido from '../../../components/ImpressãoPedido';
import { DialogCustomers } from '../Components/DialogCustomers';
import {
  ButtonNew,
  RadioPurple,
  TextFieldPurple,
} from '../../../components/Global';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootCategorias: {
    flexGrow: 1,
    width: '100%',
  },
  rootProdutos: {
    maxHeight: 118,
    height: 118,
  },
  rootLoader: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    marginLeft: '50%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  drawer: {
    width: '70%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '70%',
  },
  cardSelect: {
    backgroundColor: '#81259D',
    color: '#fff',
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      prefix="R$"
    />
  );
}

interface ICategorias {
  id: number;
  descricao: string;
  pizza: string;
}

interface ITamanhos {
  id: number;
  descricao: string;
  qt_sabor: number;
  qt_fatias: number;
  valor_a_partir_de: string;
}

interface IBordasTamanho {
  id: number;
  idBorda: number;
  idTamanho: number;
  valor: string;
  borda: {
    descricao: string;
  };
}

interface IProdutosTamanho {
  id: number;
  idProduto: number;
  idTamanho: number;
  valor: string;
  codigoProduto: string;
  nome_produto: string;
  descricao_produto: string;
}
interface IProdutos {
  id: number;
  nome: string;
  descricao: string;
  valor: string;
  codigoProduto: string;
  imagem: string;
}
interface IClientes {
  id: number;
  nome: string;
  telefone: string;
}
interface IEnderecos {
  id: number;
  idCidade: number;
  idBairro: number;
  rua: string;
  numero: number;
  complemento: string;
  ponto_referencia: string;
  cidade: {
    nome: string;
  };
  bairro: {
    nome: string;
    valor_entrega: string;
  };
}
interface ITiposPagamentos {
  id: number;
  descricao: string;
}
interface IAdicionaisProduto {
  id: number;
  idAdicional: number;
  idProduto: number;
  descricao: string;
  valor_padrao: string;
}
interface IPedidoVendaItemAdicionalChecked {
  idProduto: number;
  idAdicional: number;
  nomeAdicional: string;
  valor: string;
}
interface IPedidoVendaItemOpcaoGrupoChecked {
  idOpcaoGrupo: number;
  idProduto: number;
}
interface IPedidoVendaItemOpcaoChecked {
  idOpcaoGrupo: number;
  idOpcao: number;
  valor: string;
  descricaoOpcao: string;
}

interface IPedidoVendaItemSugestao {
  idProduto: number;
  idAdicional: number;
  valor: string;
  adicional: {
    descricao: string;
  };
}

interface IPedidoVendaItem {
  idProduto: number;
  nomeProduto: string;
  quantidade: number;
  valor: string;
  observacao: string;
  pedidoVendaItemAdicional: IPedidoVendaItemAdicionalChecked[] | undefined;
  pedidoVendaItemOpcaoGrupo: IPedidoVendaItemOpcaoGrupoChecked[] | undefined;
  pedidoVendaItemOpcao: IPedidoVendaItemOpcaoChecked[] | undefined;
  pedidoVendaItemSugestao: IPedidoVendaItemSugestao[] | undefined;
}
interface IPedidoVendaPizza {
  nomePizza: string;
  idTamanho: number;
  idBorda: number;
  nomeBorda: string;
  valor: string;
  valor_borda: string;
  observacao: string;
  pedidoVendaItem: IPedidoVendaItem[];
}
interface IPedidoVenda {
  id: number;
  id_pedido_venda_externo: number;
  idCliente: number;
  idEndereco: number;
  idTipoPagamento: number;
  idMotoboy: number;
  nome: string;
  observacao: string;
  idCupomPromocional: number;
  idRecompensa: number;
  status: string;
  origem: string;
  data: Date;
  tipo_entrega: string;
  valor_entrega: string;
  valor_total: string;
  valor_total_itens: string;
  valor_desconto: string;
  valor_acrescimo: string;
  valor_troco: string;
  pr_desconto: string;
  observacao_entrega: string;
  cliente: {
    nome: string;
    telefone: string;
  };
  tipoPagamento: {
    descricao: string;
  };
  cupomPromocional: {
    nome: string;
  };
  recompensa: {
    descricao: string;
  };
  endereco: {
    rua: string;
    numero: number;
    complemento: string;
    ponto_referencia: string;
    idBairro: string;
    bairro_nome: string;
    cidade: {
      nome: string;
      valor_entrega: string;
    };
    bairro: {
      nome: string;
      valor_entrega: string;
    };
  };
  pedidoVendaItem: Array<IPedidoVendaItemCad>;
  pedidoVendaPizza: [
    {
      id: number;
      idBorda: number;
      idCarrinho: number;
      idTamanho: number;
      observacao: string;
      valor: string;
      valor_borda: string;
      tamanho: { id: number; descricao: string; qt_sabor: number };
      borda?: { id: number; descricao: string };
    },
  ];
}
interface IPedidoVendaItemCad {
  id: number;
  idProduto: number;
  observacao: string;
  quantidade: number;
  valor: string;
  idPedidoVendaPizza: number;
  produto: {
    nome: string;
    descricao: string;
    valor: string;
    codigoProduto: string;
    exibe_descricao_impressao: string;
  };
  pedidoVendaItemAdicional: Array<IPedidoVendaItemAdicionalCad> | undefined;
  pedidoVendaItemOpcao: Array<IPedidoVendaItemOpcaoCad> | undefined;
  pedidoVendaItemSugestao: Array<IPedidoVendaItemSugestao> | undefined;
}
interface IPedidoVendaItemAdicionalCad {
  idProduto: number;
  idAdicional: number;
  valor: string;
  adicional: { descricao: string };
}
interface IPedidoVendaItemOpcaoCad {
  opcao: {
    descricao: string;
  };
  valor: string;
}
interface ISabores {
  idProduto: number;
  codigoProduto: string;
  nomeProduto: string;
  quantidade: number;
  valor: string;
  observacao: string;
}
interface IOpcoesGrupoProd {
  id: number;
  idProduto: number;
  idOpcaoGrupo: number;
  opcaoGrupo: {
    descricao: string;
  };
}
interface IOpcoesProd {
  idOpcao: number;
  valor: string;
  idOpcaoGrupo: number;
  opcao: {
    descricao: string;
  };
}

interface IDeliveryTypesEstablishment {
  id: number;
  codigoTipoEntrega: string;
  deliveryType: {
    descricao: string;
  };
}

interface ISelectCidades {
  id: string;
  nome: string;
}

interface ISelectBairros {
  id: string;
  nome: string;
  valor_entrega: string;
}

export const OrdersAdd = () => {
  const classes = useStyles();

  const [exibeMsgImprimir1Via, setExibeMsgImprimir1Via] = useState(false);
  const [exibeMsgImprimir2Via, setExibeMsgImprimir2Via] = useState(false);
  const [vias, setVias] = useState(0);
  const [loadProdutos, setLoadProdutos] = useState(false);
  const [loadPizzas, setLoadPizzas] = useState(false);
  const [loadTela, setLoadTela] = useState(true);
  const [anchor, setAnchor] = useState(false);
  const [anchorPizza, setAnchorPizza] = useState(false);
  const [pagtoEntrega, setPagtoEntrega] = useState(false);
  const [value, setValue] = useState(0);
  const [categorias, setCategorias] = useState<ICategorias[]>();
  const [tamanhos, setTamanhos] = useState<ITamanhos[]>();
  const [bordas, setBordas] = useState<IBordasTamanho[]>();
  const [produtos, setProdutos] = useState<IProdutos[]>();
  const [sabores, setSabores] = useState<IProdutosTamanho[]>();
  const [saboresCad, setSaboresCad] = useState<Array<ISabores>>([]);
  const [tiposPagamentos, setTiposPagamentos] = useState<ITiposPagamentos[]>();
  const [opcoesGrupoProd, setOpcoesGrupoProd] = useState<IOpcoesGrupoProd[]>(
    [],
  );
  const [opcoesProd, setOpcoesProd] = useState<IOpcoesProd[]>([]);
  const [
    pedidosVendaItemAdicionalChecked,
    setPedidosVendaItemAdicionalChecked,
  ] = useState<Array<IPedidoVendaItemAdicionalChecked>>([]);
  const [adicionaisProduto, setAdicionaisProduto] = useState<
    IAdicionaisProduto[]
  >();
  const [pedidosVendaItem, setPedidosVendaItem] = useState<
    Array<IPedidoVendaItem>
  >([]);
  const [pedidosVendaPizza, setPedidosVendaPizza] = useState<
    Array<IPedidoVendaPizza>
  >([]);
  const [
    pedidosVendaItemOpcaoGrupoChecked,
    setPedidosVendaItemOpcaoGrupoChecked,
  ] = useState<IPedidoVendaItemOpcaoGrupoChecked[]>([]);
  const [
    pedidosVendaItemOpcaoChecked,
    setPedidosVendaItemOpcaoChecked,
  ] = useState<IPedidoVendaItemOpcaoChecked[]>([]);
  const [cliente, setCliente] = useState<IClientes | null>();
  const [endereco, setEndereco] = useState<IEnderecos | null>();
  const [cidades, setCidades] = useState<ISelectCidades[]>();
  const [bairros, setBairros] = useState<ISelectBairros[]>([]);
  const [idCidade, setIdCidade] = useState('');
  const [nomeCidade, setNomeCidade] = useState('');
  const [nomeBairro, setNomeBairro] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [pontoReferencia, setPontoReferencia] = useState('');

  const [idTipoPagamento, setIdTipoPagamento] = useState('');
  const [idItem, setIdItem] = useState(0);
  const [nomeItem, setNomeItem] = useState('');
  const [observacao, setObservacao] = useState('');
  const [observacaoEntrega, setObservacaoEntrega] = useState('');
  const [tipoEntrega, setTipoEntrega] = useState('');
  const [valorTotal, setValorTotal] = useState('0.00');
  const [valorPizza, setValorPizza] = useState('0.00');
  const [valorTotalPizza, setValorTotalPizza] = useState('0.00');
  const [valorBorda, setValorBorda] = useState('0.00');
  const [qtdItem, setQtdItem] = useState(1);
  const [valorItem, setValorItem] = useState('');
  const [valorItemTotal, setValorItemTotal] = useState('');
  const [valorItemTotalGeral, setValorItemTotalGeral] = useState('');
  const [valorEntrega, setValorEntrega] = useState('');
  const [valorAcrescimo, setValorAcrescimo] = useState('');
  const [valorDesconto, setValorDesconto] = useState('');
  const [filtroProduto, setFiltroProduto] = useState('');
  const [filtroPizza, setFiltroPizza] = useState('');
  const [obsProduto, setObsProduto] = useState('');
  const [idTamanho, setIdTamanho] = useState(0);
  const [idPedido, setIdPedido] = useState('');
  const [idBorda, setIdBorda] = useState(0);
  const [nomeBorda, setNomeBorda] = useState('');
  const [nomeTamanho, setNomeTamanho] = useState('');
  const [carregandoBotao, setCarregandoBotao] = useState(false);
  const [showSelCliente, setShowSelCliente] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [imprimePedido, setImprimePedido] = useState(false);
  const [enableObsEntrega, setEnableObsEntrega] = useState(false);
  const [pedidoImpressao, setPedidoImpressao] = useState<IPedidoVenda>();
  const [msg, setMsg] = useState('');
  const [deliveryTypesEstablishment, setDeliveryTypesEstablishment] = useState<
    IDeliveryTypesEstablishment[]
  >([]);

  const history = useHistory();
  const token = localStorage.getItem('@PecaAquiDashboard:token');
  const { usuario } = useAuth();

  useEffect(() => {
    let endereco;
    endereco = nomeCidade;

    if (nomeBairro) {
      endereco += `, ${nomeBairro}`;
    }

    if (rua) {
      endereco += `, ${rua}`;
    }

    if (numero) {
      endereco += `, ${numero}`;
    }

    if (complemento) {
      endereco += `, ${complemento}`;
    }

    if (pontoReferencia) {
      endereco += `, ${pontoReferencia}`;
    }

    setObservacaoEntrega(endereco);
  }, [nomeCidade, nomeBairro, rua, numero, complemento, pontoReferencia]);

  useEffect(() => {
    setLoadTela(true);

    api
      .get(`/categorias/${usuario.idEstabelecimento}/venda`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((responseCategorias) => {
        setCategorias(responseCategorias.data);

        if (responseCategorias.data.length === 0) {
          setLoadTela(false);
          return;
        }

        if (responseCategorias.data[0].pizza === 'S') {
          api
            .get(`tamanhos/${usuario.idEstabelecimento}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((responseTamanho) => {
              setTamanhos(responseTamanho.data);
              setProdutos([]);
              setLoadTela(false);
            })
            .catch((error) => {
              setLoadTela(false);
              setSnackBarErro(true);
              setMsg(error.responseTamanho.data.message);
            });
        } else {
          api
            .get(`produtos/categoria/${responseCategorias.data[0].id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              setProdutos(response.data);
              setTamanhos([]);
              setLoadTela(false);
            })
            .catch((error) => {
              setLoadTela(false);
              setSnackBarErro(true);
              setMsg(error.response.data.message);
            });
        }
      })
      .catch((error) => {
        setLoadTela(false);
        setSnackBarErro(true);
        setMsg(error.responseCategorias.data.message);
      });

    async function getInformations() {
      try {
        const responseDeliveryTypesEstablishment = await api.get(
          `/delivery-types-establishment/establishment=${usuario.idEstabelecimento}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setDeliveryTypesEstablishment(responseDeliveryTypesEstablishment.data);
        setTipoEntrega(
          responseDeliveryTypesEstablishment.data[0].codigoTipoEntrega,
        );

        const responseCities = await api.get(
          `/cidades/${usuario.idEstabelecimento}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setCidades(responseCities.data);
      } catch (error) {
        setSnackBarErro(true);
        setMsg('Erro ao buscar os dados do estabelecimento. Tente novamente.');
      }
    }
    getInformations();
  }, [token, usuario.idEstabelecimento]);

  const handleFiltroPizza = (filtro: string) => {
    setLoadPizzas(true);

    setFiltroPizza(filtro);

    if (filtro === '') {
      api
        .get(`produtos/tamanhos/${idTamanho}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((responseSabores) => {
          setSabores(responseSabores.data);
          setLoadPizzas(false);
        })
        .catch((error) => {
          setLoadPizzas(false);
          setMsg(error.responseSabores.data.message);
          setSnackBarErro(true);
        });
    } else {
      api
        .get(`produtos/tamanhos/${idTamanho}/filtrovenda/${filtro}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((responseSabores) => {
          setSabores(responseSabores.data);
          setLoadPizzas(false);
        })
        .catch((error) => {
          setLoadPizzas(false);
          setMsg(error.responseSabores.data.message);
          setSnackBarErro(true);
        });
    }
  };

  const handleFiltroProduto = (filtro: string) => {
    setLoadProdutos(true);

    setFiltroProduto(filtro);

    if (filtro === '') {
      if (categorias) {
        if (categorias[value].pizza === 'S') {
          api
            .get(`tamanhos/${usuario.idEstabelecimento}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((responseTamanho) => {
              setTamanhos(responseTamanho.data);
              setProdutos([]);
              setLoadProdutos(false);
            })
            .catch((error) => {
              setLoadProdutos(false);
              setSnackBarErro(true);
              setMsg(error.responseTamanho.data.message);
            });
        } else {
          api
            .get(`produtos/categoria/${categorias[value].id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              setProdutos(response.data);
              setTamanhos([]);
              setLoadProdutos(false);
            })
            .catch((error) => {
              setLoadProdutos(false);
              setSnackBarErro(true);
              setMsg(error.response.data.message);
            });
        }
      }
    } else {
      api
        .get(`produtos/${usuario.idEstabelecimento}/filtrovenda/${filtro}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setProdutos(response.data);

          setLoadProdutos(false);
        })
        .catch((error) => {
          setLoadProdutos(false);
          setSnackBarErro(true);
          setMsg(error.response.data.message);
        });
    }
  };

  const handleAddCarrinho = (
    idProd: number,
    nomeProd: string,
    qtdProd: number,
    valor: string,
    obsProd: string,
  ) => {
    setLoadTela(true);
    // SOMA VALOR TOTAL
    if (valorTotal !== '0.00') {
      const calcValor = Number(valorTotal) + Number(valor);
      setValorTotal(calcValor.toFixed(2));
      const calcValorTotal = Number(valorEntrega) + Number(calcValor);
      setValorItemTotalGeral(calcValorTotal.toFixed(2));
      setValorItemTotal(calcValor.toFixed(2));
    } else {
      const calcValor = 0 + Number(valor);
      setValorTotal(calcValor.toFixed(2));
      const calcValorTotal = Number(valorEntrega) + Number(calcValor);
      setValorItemTotalGeral(calcValorTotal.toFixed(2));
      setValorItemTotal(calcValor.toFixed(2));
    }

    if (saboresCad.length > 0) {
      let pedidoItemAux: IPedidoVendaItem[];
      pedidoItemAux = [];

      saboresCad.forEach((element) => {
        pedidoItemAux.push({
          idProduto: element.idProduto,
          nomeProduto: element.nomeProduto,
          observacao: element.observacao,
          pedidoVendaItemAdicional: [],
          pedidoVendaItemOpcaoGrupo: [],
          pedidoVendaItemOpcao: [],
          quantidade: element.quantidade,
          valor: element.valor,
          pedidoVendaItemSugestao: [],
        });
      });

      setPedidosVendaPizza((old) => [
        ...old,
        {
          nomePizza: nomeProd,
          idTamanho,
          idBorda,
          observacao: obsProd,
          valor_borda: valorBorda,
          nomeBorda,
          valor,
          pedidoVendaItem: pedidoItemAux,
        },
      ]);

      setIdTamanho(0);
      setIdBorda(0);
      setNomeBorda('');
      setValorBorda('0');
      setValorTotalPizza('0');
      setValorPizza('0');
      setFiltroPizza('');
      setSaboresCad([]);
    } else {
      setPedidosVendaItem((old) => [
        ...old,
        {
          idProduto: idProd,
          nomeProduto: nomeProd,
          valor,
          observacao: obsProd,
          quantidade: qtdProd,
          pedidoVendaItemAdicional: pedidosVendaItemAdicionalChecked,
          pedidoVendaItemOpcaoGrupo: pedidosVendaItemOpcaoGrupoChecked,
          pedidoVendaItemOpcao: pedidosVendaItemOpcaoChecked,
          pedidoVendaItemSugestao: [],
        },
      ]);
    }

    handleFiltroProduto('');

    setFiltroProduto('');
    toggleDrawer();
    setLoadTela(false);
    setMsg('Item adicionado com sucesso!');
    setSnackBarSucesso(true);
  };

  const handleRemoveItem = (index: number, tipo: string) => {
    if (tipo === 'P') {
      // SUBTRAI VALOR TOTAL
      const valorApagar = pedidosVendaPizza[index].valor;
      const calcValor = Number(valorTotal) - Number(valorApagar);
      setValorTotal(calcValor.toFixed(2));
      const calcValorTotal = Number(valorItemTotalGeral) - Number(valorApagar);
      setValorItemTotalGeral(calcValorTotal.toFixed(2));

      pedidosVendaPizza.splice(index, 1);
      setPedidosVendaPizza([...pedidosVendaPizza]);
    } else {
      // SUBTRAI VALOR TOTAL
      const valorApagar = pedidosVendaItem[index].valor;
      const calcValor = Number(valorTotal) - Number(valorApagar);
      setValorTotal(calcValor.toFixed(2));
      const calcValorTotal = Number(valorItemTotalGeral) - Number(valorApagar);
      setValorItemTotalGeral(calcValorTotal.toFixed(2));

      pedidosVendaItem.splice(index, 1);
      setPedidosVendaItem([...pedidosVendaItem]);
    }
  };

  const handleChangeCategoria = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ) => {
    setLoadProdutos(true);
    setFiltroProduto('');
    setValue(newValue);

    if (categorias) {
      if (categorias[newValue].pizza === 'S') {
        api
          .get(`tamanhos/${usuario.idEstabelecimento}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((responseTamanho) => {
            setTamanhos(responseTamanho.data);
            setProdutos([]);
            setLoadProdutos(false);
          })
          .catch((error) => {
            setLoadProdutos(false);
            setSnackBarErro(true);
            setMsg(error.responseTamanho.data.message);
          });
      } else {
        api
          .get(`produtos/categoria/${categorias[newValue].id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setProdutos(response.data);
            setTamanhos([]);
            setLoadProdutos(false);
          })
          .catch((error) => {
            setLoadProdutos(false);
            setSnackBarErro(true);
            setMsg(error.response.data.message);
          });
      }
    }
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarErro(false);
    setSnackBarSucesso(false);
  };

  const handleCloseSelCliente = (
    customer: IClientes | null | undefined,
    address: IEnderecos | null | undefined,
    obsEntrega: string,
    taxaEntrega: string,
  ): void => {
    const valorCalc =
      Number(taxaEntrega) +
      Number(valorTotal) +
      Number(valorAcrescimo === '' ? 0 : valorAcrescimo) -
      Number(valorDesconto === '' ? 0 : valorDesconto);

    setValorItemTotalGeral(valorCalc.toFixed(2));
    setValorEntrega(taxaEntrega);
    setCliente(customer);
    setEndereco(address);
    setObservacaoEntrega(obsEntrega);
    setShowSelCliente(false);
  };

  const handleAbreAnchor = (
    idProd: number,
    nomeProd: string,
    valorProd: string,
  ) => {
    api
      .get(`produtos/${idProd}/adicionais`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAdicionaisProduto(response.data);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });

    api
      .get(`produtos/${idProd}/opcoesgrupo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        response.data.forEach(async (elementAdd) => {
          if (elementAdd.produtoOpcao.length > 0) {
            setOpcoesGrupoProd((old) => [
              ...old,
              {
                opcaoGrupo: { descricao: elementAdd.opcaoGrupo.descricao },
                idOpcaoGrupo: elementAdd.idOpcaoGrupo,
                idProduto: elementAdd.idProduto,
                id: elementAdd.id,
              },
            ]);

            elementAdd.produtoOpcao.forEach(async (elementItem) => {
              setOpcoesProd((old) => [
                ...old,
                {
                  idOpcao: elementItem.idOpcao,
                  valor: elementItem.valor,
                  idOpcaoGrupo: elementItem.idOpcaoGrupo,
                  opcao: { descricao: elementItem.opcao.descricao },
                },
              ]);
            });
          }
        });
        setAnchor(true);
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });

    setIdItem(idProd);
    setNomeItem(nomeProd);
    setValorItem(valorProd);
    setValorItemTotal(valorProd);
  };

  const toggleDrawer = () => {
    setAnchor(false);
    setAnchorPizza(false);

    setIdItem(0);
    setNomeItem('');
    setQtdItem(1);
    setValorItem('');
    setValorItemTotal('');
    setObsProduto('');
    setAdicionaisProduto([]);
    setOpcoesProd([]);
    setOpcoesGrupoProd([]);
    setSaboresCad([]);
    setPedidosVendaItemAdicionalChecked([]);
    setPedidosVendaItemOpcaoChecked([]);
    setPedidosVendaItemOpcaoGrupoChecked([]);
    setIdTamanho(0);
    setIdBorda(0);
    setValorBorda('');
    setValorTotalPizza('');
    setFiltroPizza('');
  };

  const handleAbreAnchorPizza = (
    idTamanhoPizza: number,
    nomeTamanhoPizza: string,
  ) => {
    setIdTamanho(idTamanhoPizza);
    setNomeTamanho(nomeTamanhoPizza);
    api
      .get(`bordas/tamanhos/${idTamanhoPizza}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((responseBordas) => {
        setBordas(responseBordas.data);
        api
          .get(`produtos/tamanhos/${idTamanhoPizza}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((responseSabores) => {
            setSabores(responseSabores.data);
            setAnchorPizza(true);
          })
          .catch((error) => {
            setSnackBarErro(true);
            setMsg(error.responseSabores.data.message);
          });
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.responseBordas.data.message);
      });
  };

  const calculaValorPizza = (valorPizzaCalc, valorBordaCalc) => {
    setValorTotalPizza('0.00');
    const calcValorTotalPizza = Number(valorPizzaCalc) + Number(valorBordaCalc);
    setValorTotalPizza(calcValorTotalPizza.toFixed(2));
  };

  async function handleValorItem(quantidade: number): Promise<void> {
    if (Number.isInteger(quantidade)) {
      setQtdItem(quantidade);
    }

    if (quantidade === 0) {
      setValorItemTotal('0,00');
      return;
    }
    let valorTotal;
    valorTotal = 0;

    if (valorItemTotal !== '0,00') {
      valorTotal = Number(valorItem) * quantidade;

      if (pedidosVendaItemAdicionalChecked) {
        pedidosVendaItemAdicionalChecked.forEach((element) => {
          valorTotal += Number(element.valor) * quantidade;
        });
      }

      if (pedidosVendaItemOpcaoChecked) {
        pedidosVendaItemOpcaoChecked.forEach((element) => {
          valorTotal += Number(element.valor) * quantidade;
        });
      }

      setValorItemTotal(String(valorTotal.toFixed(2)));
    }
  }

  async function handlePagtoEntrega(): Promise<void> {
    if (pedidosVendaItem.length <= 0 && pedidosVendaPizza.length <= 0) {
      setMsg('Nenhum item adicionado!');
      setSnackBarErro(true);
      return;
    }

    api
      .get(`tipospagamentos/${usuario.idEstabelecimento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTiposPagamentos(response.data);
      })
      .catch((error) => {
        setMsg(error.response.data.message);
        setSnackBarErro(true);
      });

    setPagtoEntrega(true);
  }

  async function handleMais(): Promise<void> {
    const somaQtde = qtdItem + 1;
    setQtdItem(somaQtde);
    handleValorItem(somaQtde);
  }

  async function handleMenos(): Promise<void> {
    if (qtdItem > 1) {
      const somaQtde = qtdItem - 1;
      setQtdItem(somaQtde);
      handleValorItem(somaQtde);
    }
  }

  async function handleAdicionais(
    idProd: number,
    idAdicional: number,
    nomeAdicional: string,
    valor: string,
  ): Promise<void> {
    const index = pedidosVendaItemAdicionalChecked.findIndex(
      (e) => e.idAdicional === idAdicional && e.idProduto === idProd,
    );
    let valorTotal;
    valorTotal = 0;

    if (index >= 0) {
      valorTotal = Number(valorItemTotal) - Number(valor) * qtdItem;
      setValorItemTotal(String(valorTotal.toFixed(2)));

      pedidosVendaItemAdicionalChecked.splice(index, 1);
      setPedidosVendaItemAdicionalChecked([
        ...pedidosVendaItemAdicionalChecked,
      ]);
    } else {
      valorTotal = Number(valorItemTotal) + Number(valor) * qtdItem;
      setValorItemTotal(String(valorTotal.toFixed(2)));

      setPedidosVendaItemAdicionalChecked((old) => [
        ...old,
        {
          idProduto: idProd,
          idAdicional,
          nomeAdicional,
          valor,
        },
      ]);
    }
  }

  async function handleOpcoesChecked(
    idProd: number,
    idOpcao: number,
    idOpcaoGrupo: number,
    descricaoOpcao: string,
    valor: string,
  ): Promise<void> {
    const newOpcoesGrupoProd = [...pedidosVendaItemOpcaoGrupoChecked];

    const currentIndexOpcaoGrupo = pedidosVendaItemOpcaoGrupoChecked.findIndex(
      (element) => element.idOpcaoGrupo === idOpcaoGrupo,
    );

    if (currentIndexOpcaoGrupo === -1) {
      newOpcoesGrupoProd.push({
        idOpcaoGrupo,
        idProduto: idProd,
      });
      setPedidosVendaItemOpcaoGrupoChecked(newOpcoesGrupoProd);
    }

    const newOpcoesProd = [...pedidosVendaItemOpcaoChecked];

    const currentIndexOpcao = pedidosVendaItemOpcaoChecked.findIndex(
      (element) => element.idOpcao === idOpcao,
    );

    let valorTotal;
    valorTotal = 0;

    if (currentIndexOpcao === -1) {
      const currentIndexOpcaoGrupoChecked = pedidosVendaItemOpcaoChecked.findIndex(
        (element) => element.idOpcaoGrupo === idOpcaoGrupo,
      );

      if (currentIndexOpcaoGrupoChecked === -1) {
        newOpcoesProd.push({
          idOpcao,
          valor,
          idOpcaoGrupo,
          descricaoOpcao,
        });

        valorTotal = Number(valorItemTotal) + Number(valor) * qtdItem;
        setValorItemTotal(String(valorTotal.toFixed(2)));
      } else {
        valorTotal =
          Number(valorItemTotal) -
          Number(
            pedidosVendaItemOpcaoChecked[currentIndexOpcaoGrupoChecked].valor,
          ) *
            qtdItem;

        newOpcoesProd.splice(currentIndexOpcaoGrupoChecked, 1);

        newOpcoesProd.push({
          idOpcao,
          valor,
          idOpcaoGrupo,
          descricaoOpcao,
        });

        valorTotal += Number(valor) * qtdItem;
        setValorItemTotal(String(valorTotal.toFixed(2)));
      }
    } else {
      valorTotal = Number(valorItemTotal) - Number(valor) * qtdItem;
      setValorItemTotal(String(valorTotal.toFixed(2)));

      newOpcoesProd.splice(currentIndexOpcao, 1);
    }
    setPedidosVendaItemOpcaoChecked(newOpcoesProd);
  }

  async function handleFinalizarPedido(): Promise<void> {
    // if (idTipoPagamento === '') {
    //   setErrorPagto(true);
    //   return;
    // }
    setCarregandoBotao(true);
    const pedidoVenda = {
      idTipoPagamento: idTipoPagamento === '' ? null : idTipoPagamento,
      idCliente: cliente?.id,
      idEndereco: endereco && endereco?.id,
      idUsuario: usuario.id,
      tipo_entrega: tipoEntrega,
      status: 'A',
      origem: 'A',
      valor_entrega: valorEntrega.replace(',', '.'),
      valor_total: valorItemTotalGeral.replace(',', '.'),
      observacao,
      impresso: 'S',
      valor_total_itens: Number(valorTotal.replace(',', '.')),
      valor_desconto: valorDesconto?.replace(',', '.'),
      valor_acrescimo: valorAcrescimo?.replace(',', '.'),
      observacao_entrega: observacaoEntrega,
      pedidoVendaItem: pedidosVendaItem,
      pedidoVendaPizza: pedidosVendaPizza,
    };

    api
      .post(`/pedidovendas/${usuario.idEstabelecimento}`, pedidoVenda, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (response) => {
        new Promise((resolve) => setTimeout(resolve, 1000));

        api
          .get(`/usuarios/edit/${usuario.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((responseUsuario) => {
            if (idPedido) {
              setIdPedido(idPedido?.toString());
            }
            if (responseUsuario.data.imprime_comanda === 'S') {
              setExibeMsgImprimir2Via(true);
            } else {
              const data = {
                impresso: 'N',
              };

              api
                .put(
                  `/pedidovendas/alterarimpresso/${response.data.id}`,
                  data,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  },
                )
                .then((response) => {
                  history.push('/orders');
                });
            }
          })
          .catch((error) => {
            setSnackBarErro(true);
            setMsg(error.responseUsuario.data.message);
          });

        setMsg('Pedido cadastrado com sucesso!');
        setSnackBarSucesso(true);

        setIdPedido(response.data.id);
      })
      .catch((error) => {
        setCarregandoBotao(false);
        setMsg(
          'Não foi possível cadastrar o pedido, tente novamente ou entre em contato conosco!',
        );
        setSnackBarErro(true);
      });
  }

  async function imprimirPedido(via: number): Promise<void> {
    setVias(via);

    try {
      const response = await api.get(`/pedidovendas/edit/${idPedido}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setExibeMsgImprimir2Via(false);

      setImprimePedido(true);
      setPedidoImpressao(response.data);

      setImprimePedido(false);
      setPedidoImpressao(undefined);

      setCarregandoBotao(false);
      history.push('/orders');
    } catch (error) {
      setMsg(error.response.data.message);
      setSnackBarErro(true);
    }
  }

  function handleTipoEntrega(event: React.ChangeEvent<HTMLInputElement>) {
    setTipoEntrega((event.target as HTMLInputElement).value);

    if ((event.target as HTMLInputElement).value !== 'DEL') {
      setObservacaoEntrega('');
      setIdCidade('');
      setNomeCidade('');
      setNomeBairro('');
      setRua('');
      setNumero('');
      setComplemento('');
      setPontoReferencia('');
    }
  }

  async function handleCidade(
    idCidadeHandle: string,
    nomeCidadeHandle: string,
  ): Promise<void> {
    setIdCidade(idCidadeHandle);
    setNomeCidade(nomeCidadeHandle);

    try {
      const response = await api.get(`/bairros/cidade/${idCidadeHandle}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBairros(response.data);
    } catch (error) {
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={anchor}
        onClose={() => toggleDrawer()}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ height: '100%' }} role="presentation">
          <Typography component="div">
            <Box p={2} fontWeight="fontWeight900" fontSize={19}>
              {opcoesGrupoProd &&
                opcoesGrupoProd.map((opcaoGrupoProd) => (
                  <>
                    <Box pt={1}>
                      OPÇÕES {opcaoGrupoProd.opcaoGrupo.descricao.toUpperCase()}{' '}
                      <Grid
                        container
                        style={{ paddingBottom: '8px', paddingTop: '8px' }}
                        spacing={1}
                      >
                        {opcoesProd?.map((opcaoProd, index) => (
                          <>
                            {opcaoProd.idOpcaoGrupo ===
                            opcaoGrupoProd.idOpcaoGrupo ? (
                              <Grid item xs={6} sm={6} md={3} lg={3}>
                                <Card
                                  style={{
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                                    textAlign: 'center',
                                    maxHeight: '45px',
                                    height: '45px',
                                  }}
                                  className={clsx(
                                    pedidosVendaItemOpcaoChecked &&
                                      pedidosVendaItemOpcaoChecked.findIndex(
                                        (e) => e.idOpcao === opcaoProd.idOpcao,
                                      ) >= 0
                                      ? classes.cardSelect
                                      : '',
                                  )}
                                >
                                  <CardActionArea
                                    onClick={() =>
                                      handleOpcoesChecked(
                                        opcaoGrupoProd.idProduto,
                                        opcaoProd.idOpcao,
                                        opcaoProd.idOpcaoGrupo,
                                        opcaoProd.opcao.descricao,
                                        opcaoProd.valor,
                                      )}
                                  >
                                    <CardContent style={{ padding: '1px' }}>
                                      <Typography component="div">
                                        <Box
                                          fontWeight="fontWeightBold"
                                          fontSize={14}
                                        >
                                          {opcaoProd.opcao.descricao}
                                        </Box>
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        R${' '}
                                        {opcaoProd.valor
                                          ? opcaoProd.valor.replace('.', ',')
                                          : '0,00'}
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </>
                        ))}
                      </Grid>
                    </Box>
                  </>
                ))}
            </Box>
            <Box p={2} fontWeight="fontWeight900" fontSize={19}>
              ADICIONAIS
              <Box pt={1}>
                {' '}
                <Grid container spacing={1}>
                  {adicionaisProduto?.map((adicionalProduto, index) => (
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <Card
                        style={{
                          boxShadow:
                            'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                          textAlign: 'center',
                          maxHeight: '45px',
                          height: '45px',
                        }}
                        className={clsx(
                          pedidosVendaItemAdicionalChecked &&
                            pedidosVendaItemAdicionalChecked.findIndex(
                              (e) =>
                                e.idAdicional ===
                                  adicionalProduto.idAdicional &&
                                e.idProduto === adicionalProduto.idProduto,
                            ) >= 0
                            ? classes.cardSelect
                            : '',
                        )}
                      >
                        <CardActionArea
                          onClick={() =>
                            handleAdicionais(
                              adicionalProduto.idProduto,
                              adicionalProduto.idAdicional,
                              adicionalProduto.descricao,
                              adicionalProduto.valor_padrao,
                            )}
                        >
                          <CardContent style={{ padding: '1px' }}>
                            <Typography component="div">
                              <Box fontWeight="fontWeightBold" fontSize={14}>
                                {adicionalProduto.descricao}
                              </Box>
                            </Typography>
                            <Typography variant="body2" component="p">
                              R${' '}
                              {adicionalProduto.valor_padrao.replace('.', ',')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <Box p={2} fontWeight="fontWeight900" fontSize={19}>
              OBSERVAÇÕES
              <Box pt={1}>
                <TextFieldPurple
                  size="small"
                  fullWidth
                  variant="standard"
                  value={obsProduto}
                  onChange={(e) => setObsProduto(e.target.value)}
                />
              </Box>
            </Box>
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    {nomeItem}
                  </span>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <IconButton
                    size="small"
                    onClick={handleMais}
                    style={{
                      boxShadow:
                        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                      borderRadius: '2px',
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  <TextFieldPurple
                    size="small"
                    variant="standard"
                    style={{ width: 50, textAlignLast: 'center' }}
                    value={qtdItem}
                    onChange={(e) => handleValorItem(Number(e.target.value))}
                  />
                  <IconButton
                    size="small"
                    onClick={handleMenos}
                    style={{
                      boxShadow:
                        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                      borderRadius: '2px',
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  style={{ textAlign: 'right' }}
                >
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    <TextFieldPurple
                      size="small"
                      value={valorItemTotal}
                      style={{ textAlignLast: 'right' }}
                      onChange={(event) => {
                        setValorItemTotal(event.target.value);
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                    />
                  </span>
                </Grid>
              </Grid>
            </Box>
            <Box pl={2} pr={2}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: 'left' }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: '1px' }}
                    onClick={() => toggleDrawer()}
                  >
                    VOLTAR
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: 'right' }}
                >
                  <ButtonNew
                    size="medium"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '1px' }}
                    onClick={() =>
                      handleAddCarrinho(
                        idItem,
                        nomeItem,
                        qtdItem,
                        valorItemTotal,
                        obsProduto,
                      )}
                  >
                    SALVAR
                  </ButtonNew>
                </Grid>
              </Grid>
            </Box>
          </Typography>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={anchorPizza}
        onClose={() => toggleDrawer()}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ height: '100%' }} role="presentation">
          <Typography component="div">
            <Box p={2} fontWeight="fontWeight900" fontSize={19}>
              BORDA
              <Box pt={1}>
                {' '}
                <Grid container spacing={1}>
                  {bordas?.map((borda, index) => (
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <Card
                        style={{
                          boxShadow:
                            'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                          textAlign: 'center',
                          maxHeight: '45px',
                          height: '45px',
                        }}
                        className={clsx(
                          idBorda === borda.idBorda ? classes.cardSelect : '',
                        )}
                      >
                        <CardActionArea
                          onClick={() => {
                            if (idBorda !== borda.idBorda) {
                              setIdBorda(borda.idBorda);
                              setNomeBorda(borda.borda.descricao);
                              setValorBorda(borda.valor);
                              calculaValorPizza(valorPizza, borda.valor);
                            } else {
                              setIdBorda(0);
                              setNomeBorda('');
                              setValorBorda('');
                              calculaValorPizza(valorPizza, '0');
                            }
                          }}
                        >
                          <CardContent style={{ padding: '1px' }}>
                            <Typography component="div">
                              <Box fontWeight="fontWeightBold" fontSize={14}>
                                {borda.borda.descricao}
                              </Box>
                            </Typography>
                            <Typography variant="body2" component="p">
                              R$ {borda.valor.replace('.', ',')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <Box p={2} fontWeight="fontWeight900" fontSize={19}>
              SABORES
              <Grid container spacing={1} style={{ marginBottom: '18px' }}>
                {saboresCad &&
                  saboresCad.map((sabor, index) => (
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <Card
                        style={{
                          boxShadow:
                            'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                          textAlign: 'center',
                          maxHeight: '45px',
                          height: '45px',
                        }}
                      >
                        <div>
                          <Box fontWeight="fontWeightBold" fontSize={14}>
                            {sabor.nomeProduto}
                          </Box>
                        </div>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              <Box pt={1}>
                {' '}
                <Grid container spacing={1}>
                  <DebounceInput
                    onChange={(e) => {
                      handleFiltroPizza(e.target.value as string);
                    }}
                    value={filtroPizza}
                    debounceTimeout={300}
                    minLength={1}
                    placeholder="Pesquise produtos pelo código ou nome"
                    style={{
                      marginBottom: '5px',
                      width: '100%',
                      height: '35px',
                      fontSize: '17px',
                    }}
                  />
                  {loadPizzas ? (
                    <div className={classes.rootLoader}>
                      <CircularProgress style={{ color: '#81259D' }} />
                    </div>
                  ) : (
                    <>
                      {sabores?.map((sabor, index) => (
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                          <Card
                            style={{
                              boxShadow:
                                'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                              textAlign: 'center',
                              maxHeight: '45px',
                              height: '45px',
                            }}
                            className={clsx(
                              saboresCad &&
                                saboresCad?.findIndex(
                                  (e) => e.idProduto === sabor.idProduto,
                                ) >= 0
                                ? classes.cardSelect
                                : '',
                            )}
                          >
                            <CardActionArea
                              onClick={() => {
                                if (filtroPizza !== '') {
                                  setFiltroPizza('');
                                  handleFiltroPizza('');
                                }
                                const indexSabores = saboresCad?.findIndex(
                                  (e) => e.idProduto === sabor.idProduto,
                                );
                                if (indexSabores >= 0) {
                                  let valor;
                                  valor = '0';
                                  saboresCad.splice(indexSabores, 1);
                                  setSaboresCad([...saboresCad]);

                                  if (saboresCad.length > 0) {
                                    saboresCad.forEach((element) => {
                                      if (
                                        Number(element.valor) > Number(valor)
                                      ) {
                                        valor = element.valor;
                                        setValorPizza(element.valor);
                                        calculaValorPizza(
                                          valorBorda,
                                          element.valor,
                                        );
                                      }
                                    });
                                  } else {
                                    calculaValorPizza(valorBorda, '0');
                                    setValorPizza('0');
                                  }
                                } else {
                                  setSaboresCad((old) => [
                                    ...old,
                                    {
                                      idProduto: sabor.idProduto,
                                      codigoProduto: sabor.codigoProduto,
                                      nomeProduto: sabor.nome_produto,
                                      quantidade: 1,
                                      valor: sabor.valor,
                                      observacao: '',
                                    },
                                  ]);

                                  if (
                                    Number(sabor.valor) > Number(valorPizza)
                                  ) {
                                    setValorPizza(sabor.valor);
                                    calculaValorPizza(valorBorda, sabor.valor);
                                  }
                                }
                              }}
                            >
                              <CardContent style={{ padding: '1px' }}>
                                <Typography component="div">
                                  <Box
                                    fontWeight="fontWeightBold"
                                    fontSize={14}
                                  >
                                    {sabor.nome_produto}
                                  </Box>
                                </Typography>
                                <Typography
                                  variant="body2"
                                  component="p"
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    padding: '0px 15px 0px 15px',
                                  }}
                                >
                                  {sabor.descricao_produto}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
            <Box p={2} fontWeight="fontWeight900" fontSize={19}>
              OBSERVAÇÕES
              <Box pt={1}>
                <TextFieldPurple
                  size="small"
                  fullWidth
                  variant="standard"
                  value={obsProduto}
                  onChange={(e) => setObsProduto(e.target.value)}
                />
              </Box>
            </Box>
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Pizza {nomeTamanho}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  style={{ textAlign: 'right' }}
                >
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    <TextFieldPurple
                      size="small"
                      value={valorTotalPizza}
                      style={{ textAlignLast: 'right' }}
                      onChange={(event) => {
                        setValorTotalPizza(event.target.value);
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                    />
                  </span>
                </Grid>
              </Grid>
            </Box>
            <Box pl={2} pr={2}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: 'left' }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: '1px' }}
                    onClick={() => toggleDrawer()}
                  >
                    VOLTAR
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: 'right' }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '1px' }}
                    onClick={() =>
                      handleAddCarrinho(
                        idItem,
                        `Pizza ${nomeTamanho}`,
                        qtdItem,
                        valorTotalPizza,
                        obsProduto,
                      )}
                  >
                    SALVAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Typography>
        </div>
      </Drawer>
      <Grid container spacing={1}>
        {loadTela === true ? (
          <div className={classes.rootLoader}>
            <CircularProgress style={{ color: '#81259D' }} />
          </div>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Typography
                component="div"
                style={{ backgroundColor: 'whitesmoke', borderRadius: '3px' }}
              >
                <Box p={4} fontWeight="fontWeightBold" fontSize="h4">
                  {!cliente ? (
                    <>Cliente não identificado</>
                  ) : (
                    <>{cliente?.nome}</>
                  )}
                  <Button
                    size="small"
                    onClick={() => setShowSelCliente(true)}
                    style={{
                      backgroundColor: '#fff',
                      marginLeft: '10px',
                      boxShadow:
                        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                    }}
                  >
                    <HowToRegIcon />
                  </Button>
                </Box>
                <Box pt={1} pl={1} pr={1} fontSize={13}>
                  Observação
                </Box>
                <Box pb={1} pl={1} pr={1}>
                  <TextFieldPurple
                    style={{ backgroundColor: 'papayawhip', fontSize: '13px' }}
                    fullWidth
                    variant="standard"
                    size="small"
                    value={observacao}
                    onChange={(e) => setObservacao(e.target.value)}
                  />
                </Box>
              </Typography>
              <Typography
                component="div"
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '3px',
                }}
              >
                <Box pt={3} pb={3} pl={2} pr={2} fontSize={16}>
                  {pedidosVendaPizza.length > 0 ||
                  pedidosVendaItem.length > 0 ? (
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Qtd</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pedidosVendaItem.map((item, index) => (
                            <TableRow>
                              <TableCell
                                style={{ width: '3%', maxWidth: '3%' }}
                              >
                                {item.quantidade}
                              </TableCell>
                              <TableCell
                                style={{ width: '85%', maxWidth: '85%' }}
                              >
                                {item.nomeProduto}
                                {item.pedidoVendaItemOpcao?.map((opcao) => (
                                  <p>
                                    <small>= {opcao.descricaoOpcao}</small>
                                  </p>
                                ))}
                                {item.pedidoVendaItemAdicional?.map(
                                  (adicional) => (
                                    <p>
                                      <small>+ {adicional.nomeAdicional}</small>
                                    </p>
                                  ),
                                )}
                                {item.observacao !== '' ? (
                                  <p>
                                    <small>Observação: {item.observacao}</small>
                                  </p>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              <TableCell
                                style={{ width: '5%', maxWidth: '5%' }}
                              >
                                {item.valor.replace('.', ',')}
                              </TableCell>
                              <TableCell
                                style={{ width: '7%', maxWidth: '7%' }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleRemoveItem(index, '')}
                                  style={{
                                    boxShadow:
                                      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                                    borderRadius: '2px',
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                          {pedidosVendaPizza.map((cabecalho, indexPizza) => (
                            <TableRow>
                              <TableCell
                                style={{ width: '3%', maxWidth: '3%' }}
                              >
                                1
                              </TableCell>
                              <TableCell
                                style={{ width: '85%', maxWidth: '85%' }}
                              >
                                {cabecalho.nomePizza}
                                {cabecalho.pedidoVendaItem?.map((item) => (
                                  <p>
                                    <small>{item.nomeProduto}</small>
                                  </p>
                                ))}
                                {cabecalho.idBorda ? (
                                  <p>
                                    <small>Borda: {cabecalho.nomeBorda}</small>
                                  </p>
                                ) : (
                                  ''
                                )}
                                {cabecalho.observacao !== '' ? (
                                  <p>
                                    <small>
                                      Observação: {cabecalho.observacao}
                                    </small>
                                  </p>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              <TableCell
                                style={{ width: '5%', maxWidth: '5%' }}
                              >
                                {cabecalho.valor.toString().replace('.', ',')}
                              </TableCell>
                              <TableCell
                                style={{ width: '7%', maxWidth: '7%' }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleRemoveItem(indexPizza, 'P')}
                                  style={{
                                    boxShadow:
                                      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                                    borderRadius: '2px',
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <>Nenhum item adicionado</>
                  )}
                </Box>
                <Box pl={1} pt={1} fontSize={14} fontWeight="fontWeightBold">
                  VALOR TOTAL: R$ {valorTotal.replace('.', ',')}
                </Box>
                <Box p={1}>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: '1px' }}
                    onClick={() => history.push('/orders')}
                  >
                    Voltar
                  </Button>
                  <ButtonNew
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '1px' }}
                    onClick={handlePagtoEntrega}
                    disabled={pagtoEntrega}
                  >
                    Pagamento e Entrega
                  </ButtonNew>
                </Box>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7}>
              {!pagtoEntrega ? (
                <>
                  {' '}
                  <DebounceInput
                    onChange={(e) => {
                      handleFiltroProduto(e.target.value as string);
                    }}
                    value={filtroProduto}
                    debounceTimeout={300}
                    minLength={1}
                    placeholder="Pesquise produtos pelo código ou nome"
                    style={{
                      marginBottom: '5px',
                      width: '100%',
                      height: '35px',
                    }}
                  />
                  <div className={classes.rootCategorias}>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={value}
                        onChange={handleChangeCategoria}
                        variant="scrollable"
                        scrollButtons="on"
                        centered
                        aria-label="scrollable auto tabs example"
                      >
                        {categorias &&
                          categorias.map((categoria, index) => (
                            <Tab label={categoria.descricao} />
                          ))}
                      </Tabs>
                    </AppBar>
                    <Grid container spacing={1} style={{ marginTop: '5px' }}>
                      {loadProdutos === true ? (
                        <div className={classes.rootLoader}>
                          <CircularProgress style={{ color: '#81259D' }} />
                        </div>
                      ) : (
                        <>
                          {filtroProduto === '' ? (
                            <>
                              {tamanhos &&
                                tamanhos.map((tamanho, index) => (
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Card
                                      className={classes.rootProdutos}
                                      style={{
                                        background: '#81259D',
                                        color: 'white',
                                        height: '49px',
                                      }}
                                    >
                                      <CardContent
                                        style={{
                                          padding: '10px',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div
                                          style={{
                                            overflow: 'hidden',
                                            flexGrow: 1,
                                          }}
                                        >
                                          <div
                                            style={{
                                              overflow: 'hidden',
                                              fontSize: '1rem',
                                              lineHeight: '24px',
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              {tamanho.descricao}
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              handleAbreAnchorPizza(
                                                tamanho.id,
                                                tamanho.descricao,
                                              )}
                                            className={classes.icon}
                                          >
                                            <StorageIcon fontSize="small" />
                                          </IconButton>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ))}
                            </>
                          ) : (
                            ''
                          )}
                          {produtos &&
                            produtos.map((produto, index) => (
                              <>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                  <Card
                                    className={classes.rootProdutos}
                                    style={{
                                      background: '#81259D',
                                      color: 'white',
                                      height: '68px',
                                    }}
                                  >
                                    <CardContent
                                      style={{
                                        padding: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          overflow: 'hidden',
                                          flexGrow: 1,
                                        }}
                                      >
                                        <div
                                          style={{
                                            overflow: 'hidden',
                                            fontSize: '1rem',
                                            lineHeight: '24px',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: '12px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {produto.nome}
                                          </span>
                                        </div>
                                        <span
                                          style={{
                                            overflow: 'hidden',
                                            fontSize: '12px',
                                            lineHeight: '24px',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          <p>
                                            {' '}
                                            R$ {produto.valor.replace('.', ',')}
                                          </p>
                                        </span>
                                      </div>
                                      <div>
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleAddCarrinho(
                                              produto.id,
                                              produto.nome,
                                              1,
                                              produto.valor,
                                              '',
                                            )}
                                          className={classes.icon}
                                        >
                                          <AddShoppingCartIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleAbreAnchor(
                                              produto.id,
                                              produto.nome,
                                              produto.valor,
                                            )}
                                          className={classes.icon}
                                        >
                                          <StorageIcon fontSize="small" />
                                        </IconButton>
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </>
                            ))}
                          {/* </GridList> */}
                        </>
                      )}
                    </Grid>
                  </div>
                </>
              ) : (
                <>
                  <Box
                    p={2}
                    style={{ backgroundColor: '#fff', borderRadius: '3px' }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <RadioGroup
                          row
                          aria-label="entrega"
                          name="entrega"
                          defaultValue="bottom"
                          value={tipoEntrega}
                          onChange={handleTipoEntrega}
                        >
                          {deliveryTypesEstablishment &&
                            deliveryTypesEstablishment.map(
                              (deliveryTypeEstablishment) => (
                                <FormControlLabel
                                  value={
                                    deliveryTypeEstablishment.codigoTipoEntrega
                                  }
                                  checked={
                                    tipoEntrega ===
                                    deliveryTypeEstablishment.codigoTipoEntrega
                                  }
                                  control={<RadioPurple />}
                                  label={
                                    deliveryTypeEstablishment.deliveryType
                                      .descricao
                                  }
                                  labelPlacement="bottom"
                                />
                              ),
                            )}
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {tipoEntrega === 'DEL' ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextFieldPurple
                            variant="standard"
                            select
                            size="small"
                            value={idCidade}
                            onChange={(e) => {
                              handleCidade(e.target.value, e.currentTarget.id);
                            }}
                            fullWidth
                            label="Cidade"
                          >
                            {cidades &&
                              cidades.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id}
                                  id={option.nome}
                                >
                                  {option.nome}
                                </MenuItem>
                              ))}
                          </TextFieldPurple>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextFieldPurple
                            select
                            fullWidth
                            label="Bairro"
                            variant="standard"
                            size="small"
                            value={nomeBairro}
                            onChange={(e) => {
                              setValorEntrega(e.currentTarget.id);
                              setNomeBairro(e.target.value);

                              const valorCalc =
                                Number(e.currentTarget.id) +
                                Number(valorTotal) +
                                Number(
                                  valorAcrescimo === '' ? 0 : valorAcrescimo,
                                ) -
                                Number(
                                  valorDesconto === '' ? 0 : valorDesconto,
                                );

                              setValorItemTotalGeral(valorCalc.toFixed(2));
                            }}
                          >
                            {bairros &&
                              bairros.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.nome}
                                  id={option.valor_entrega}
                                >
                                  {option.nome}
                                </MenuItem>
                              ))}
                          </TextFieldPurple>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <TextFieldPurple
                            variant="standard"
                            size="small"
                            fullWidth
                            label="Rua"
                            value={rua}
                            onChange={(e) => setRua(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          <TextFieldPurple
                            variant="standard"
                            size="small"
                            fullWidth
                            label="Número"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextFieldPurple
                            variant="standard"
                            size="small"
                            fullWidth
                            label="Complemento"
                            value={complemento}
                            onChange={(e) => setComplemento(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextFieldPurple
                            variant="standard"
                            size="small"
                            fullWidth
                            label="Ponto de referência"
                            value={pontoReferencia}
                            onChange={(e) => setPontoReferencia(e.target.value)}
                          />
                        </Grid>
                        <Box p={1} fontSize={16} style={{ width: '100%' }}>
                          Endereço de entrega:{' '}
                          <div style={{ display: 'flex' }}>
                            {enableObsEntrega ? (
                              <>
                                <TextFieldPurple
                                  size="small"
                                  fullWidth
                                  value={observacaoEntrega}
                                  onChange={(e) =>
                                    setObservacaoEntrega(e.target.value)}
                                />
                                <Button
                                  style={{ color: '#81259D' }}
                                  onClick={() => {
                                    setEnableObsEntrega(false);
                                  }}
                                >
                                  <CheckCircleIcon />
                                </Button>
                              </>
                            ) : (
                              <>
                                <span style={{ fontSize: '14px' }}>
                                  {observacaoEntrega}
                                </span>
                                <Button
                                  style={{ color: '#81259D' }}
                                  onClick={() => {
                                    setEnableObsEntrega(true);
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                              </>
                            )}
                          </div>
                        </Box>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box pt={2}>
                    <Box
                      p={2}
                      style={{ backgroundColor: '#fff', borderRadius: '3px' }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box p={1} fontSize={16}>
                            Pagamento
                          </Box>
                          <Box p={1} fontSize={14} style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }}>Total itens:</div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              R$ {valorTotal.replace('.', ',')}
                            </div>
                          </Box>
                          <Box p={1} fontSize={14} style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1, width: '100%' }}>
                              Forma de pagto:
                            </div>
                            <div
                              style={{
                                justifyContent: 'flex-end',
                                width: '100%',
                              }}
                            >
                              <TextFieldPurple
                                select
                                fullWidth
                                value={idTipoPagamento}
                                onChange={(e) =>
                                  setIdTipoPagamento(e.target.value)}
                                size="small"
                              >
                                {tiposPagamentos &&
                                  tiposPagamentos.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.descricao}
                                    </MenuItem>
                                  ))}
                              </TextFieldPurple>
                            </div>
                          </Box>
                          <Box p={1} fontSize={14} style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }}>Entrega:</div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <TextFieldPurple
                                size="small"
                                style={{ textAlignLast: 'right' }}
                                value={valorEntrega}
                                onChange={(e) => {
                                  const valorCalc =
                                    Number(e.target.value) +
                                    Number(valorTotal) +
                                    Number(
                                      valorAcrescimo === ''
                                        ? 0
                                        : valorAcrescimo,
                                    ) -
                                    Number(
                                      valorDesconto === '' ? 0 : valorDesconto,
                                    );

                                  setValorItemTotalGeral(valorCalc.toFixed(2));
                                  setValorEntrega(e.target.value);
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom as any,
                                }}
                              />
                            </div>
                          </Box>
                          <Box p={1} fontSize={14} style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }}>Acréscimo:</div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <TextFieldPurple
                                size="small"
                                style={{ textAlignLast: 'right' }}
                                value={valorAcrescimo}
                                onChange={(e) => {
                                  const valorCalc =
                                    Number(
                                      valorEntrega === '' ? 0 : valorEntrega,
                                    ) +
                                    Number(valorTotal) +
                                    Number(e.target.value) -
                                    Number(
                                      valorDesconto === '' ? 0 : valorDesconto,
                                    );

                                  setValorItemTotalGeral(valorCalc.toFixed(2));
                                  setValorAcrescimo(e.target.value);
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom as any,
                                }}
                              />
                            </div>
                          </Box>
                          <Box p={1} fontSize={14} style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }}>Desconto:</div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <TextFieldPurple
                                size="small"
                                style={{ textAlignLast: 'right' }}
                                value={valorDesconto}
                                onChange={(e) => {
                                  const valorCalc =
                                    Number(
                                      valorEntrega === '' ? 0 : valorEntrega,
                                    ) +
                                    Number(valorTotal) +
                                    Number(
                                      valorAcrescimo === ''
                                        ? 0
                                        : valorAcrescimo,
                                    ) -
                                    Number(e.target.value);

                                  setValorItemTotalGeral(valorCalc.toFixed(2));
                                  setValorDesconto(e.target.value);
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom as any,
                                }}
                              />
                            </div>
                          </Box>
                          <Box
                            pl={1}
                            pt={1}
                            fontWeight="fontWeightBold"
                            style={{ display: 'flex' }}
                          >
                            <div style={{ flexGrow: 1, fontSize: '14px' }}>
                              VALOR TOTAL:
                            </div>
                            <div
                              style={{
                                justifyContent: 'flex-end',
                                fontSize: '16px',
                              }}
                            >
                              R$ {valorItemTotalGeral.replace('.', ',')}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <Box pt={1}>
                    <div className={classes.wrapper}>
                      <Button
                        size="small"
                        variant="contained"
                        style={{
                          backgroundColor: '#4caf50',
                          color: '#fff',
                          width: '100%',
                        }}
                        onClick={handleFinalizarPedido}
                        disabled={carregandoBotao}
                      >
                        Finalizar pedido
                      </Button>
                      {carregandoBotao && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <div className={classes.wrapper}>
                      <Button
                        size="small"
                        variant="contained"
                        color="inherit"
                        style={{ width: '100%' }}
                        onClick={() => {
                          setPagtoEntrega(false);
                        }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </Box>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>

      {/* INICIO SELECT CLIENTE */}
      {showSelCliente && (
        <DialogCustomers
          open={showSelCliente}
          handleClose={handleCloseSelCliente}
          customerData={cliente}
          addressData={endereco}
        />
      )}

      {/* FIM SELECT CLIENTE */}

      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={4000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}

      {imprimePedido && pedidoImpressao && (
        <div style={{ marginTop: '200px' }}>
          <ImpressãoPedido vias={vias} pedido={pedidoImpressao} />
        </div>
      )}

      {/* INICIO DESEJA IMPRIMIR */}
      {exibeMsgImprimir1Via && (
        <Dialog
          open={exibeMsgImprimir1Via}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setExibeMsgImprimir1Via(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja imprimir?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setExibeMsgImprimir1Via(false);
                setMsg('Pedido cadastrado com sucesso!');
                setSnackBarSucesso(true);
                setCarregandoBotao(false);
                history.push('/orders');
              }}
              variant="contained"
            >
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={() => {
                  setExibeMsgImprimir1Via(false);
                  setExibeMsgImprimir2Via(true);
                }}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: '#4caf50',
                }}
              >
                Sim
              </Button>
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}

      {/* INICIO DESEJA IMPRIMIR */}
      {exibeMsgImprimir2Via && (
        <Dialog
          open={exibeMsgImprimir2Via}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setExibeMsgImprimir1Via(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja imprimir a 2º via?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => imprimirPedido(1)} variant="contained">
              Não
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={() => imprimirPedido(2)}
                style={{
                  backgroundColor: '#4caf50',
                }}
                variant="contained"
              >
                Sim
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
