import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const Footer: React.FC = () => {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.pecaaquidelivery.com">
          PeçaAqui
        </Link>{' '}
        {new Date().getFullYear()}.
      </Typography>
    </>
  );
};

export default Footer;
