import React, { useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';

import { newCategory, uploadImage } from '../../actions';
import { useStyles } from '../../styles';
import {
  SwitchPurple,
  TextFieldPurple,
} from '../../../../../components/Global';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface ItemDialogProps {
  open: boolean;
  handleClose(salvar: boolean): void;
  idEstablishment: number;
}

const Add: React.FC<ItemDialogProps> = ({
  open,
  handleClose,
  idEstablishment,
}) => {
  const [descricao, setDescricao] = useState('');
  const [ordem, setOrdem] = useState('');
  const [ativo, setAtivo] = useState('');
  const [ativoBool, setAtivoBool] = useState(false);
  const [imagemUrl, setImagemUrl] = useState();
  const [pizza, setPizza] = useState('N');
  const [qtPontos, setQtPontos] = useState('');
  const [pizzaBool, setPizzaBool] = useState(false);
  const [msg, setMsg] = useState('');
  const [loadButton, setLoadButton] = useState(false);
  const [data, setData] = useState(new FormData());

  const [snackBarErro, setSnackBarErro] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const token = localStorage.getItem('@PecaAquiDashboard:token');

  const handleNew = async (): Promise<void> => {
    setLoadButton(true);

    const categoria = {
      descricao,
      ordem,
      pizza,
      qt_pontos: qtPontos,
      ativo,
    };

    try {
      const response = await newCategory(categoria, idEstablishment, token);

      if (imagemUrl) {
        await uploadImage(response.data.id, data, idEstablishment, token);
      }

      setLoadButton(false);
      handleClose(true);
    } catch (error) {
      setLoadButton(false);
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  async function handlePizza(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.value === 'S') {
      setPizza('N');
      setPizzaBool(false);
    } else {
      setPizza('S');
      setPizzaBool(true);
    }
  }

  async function handleAtivo(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.value === 'S') {
      setAtivo('N');
      setAtivoBool(false);
    } else {
      setAtivo('S');
      setAtivoBool(true);
    }
  }

  async function handleImageChange(
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    if (e.target.files) {
      const reader = new FileReader();
      const selectedFile = e.target.files[0];

      reader.onloadend = () => {
        setImagemUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      data.delete('file');
      data.append('file', selectedFile);
    }
  }

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarErro(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleNew}>
          <DialogTitle className={classes.headerDialog}>
            Nova Categoria
          </DialogTitle>
          <FormGroup aria-label="position" row>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    required
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    label="Descrição"
                    fullWidth
                    autoComplete="descricao_categoria"
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldPurple
                    value={ordem}
                    onChange={(e) => setOrdem(e.target.value)}
                    label="Ordem"
                    type="number"
                    fullWidth
                    autoComplete="ordem_categoria"
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldPurple
                    value={qtPontos}
                    onChange={(e) => setQtPontos(e.target.value)}
                    label="Qt. Pontos"
                    type="number"
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControlLabel
                    value="start"
                    control={(
                      <SwitchPurple
                        checked={pizzaBool === true}
                        onChange={handlePizza}
                        color="primary"
                        value={pizza}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    )}
                    label="Pizza:"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    value="start"
                    control={(
                      <SwitchPurple
                        checked={ativoBool === true}
                        onChange={handleAtivo}
                        color="primary"
                        value={ativo}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    )}
                    label="Ativo:"
                    labelPlacement="start"
                  />
                  <small>
                    {' '}
                    Quando marcado sua categoria estará visível ao público
                    geral.
                  </small>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="contained-button-file">
                      <Fab component="span" className={classes.button}>
                        <AddPhotoAlternateIcon />
                      </Fab>
                    </label>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleImageChange}
                    />
                  </Grid>
                  <img src={imagemUrl} alt="" width={100} height={100} />
                  <div>
                    <Button
                      variant="outlined"
                      component="label"
                      color="secondary"
                      onClick={() => {
                        setImagemUrl('');
                        setData(new FormData());
                      }}
                    >
                      Remover imagem
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={() => handleClose(false)} variant="contained">
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: '#4caf50',
                }}
                color="primary"
                disabled={loadButton}
              >
                Salvar
              </Button>
              {loadButton && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Add;
