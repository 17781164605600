import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import Global from './styles/global';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <App />
    <Global />
  </Provider>,
  document.getElementById('root'),
);
