import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormGroup from '@material-ui/core/FormGroup';
import Slide from '@material-ui/core/Slide';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { TransitionProps } from '@material-ui/core/transitions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ClearIcon from '@material-ui/icons/Clear';

import 'date-fns';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  DataGrid,
  ColDef,
  GridOverlay,
  SortDirection,
  RowId,
  CellClassParams,
  ValueFormatterParams,
} from '@material-ui/data-grid';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import {
  KeyboardDatePickerPurple,
  TextFieldPurple,
} from '../../../components/Global';
import { LoadGrid } from '../../../components/LoadGrid';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtro: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(1) },
    root: {
      flexDirection: 'column',
      '& .ant-empty-img-1': {
        fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
      },
      '& .ant-empty-img-2': {
        fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
      },
      '& .ant-empty-img-3': {
        fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
      },
      '& .ant-empty-img-4': {
        fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
      },
      '& .ant-empty-img-5': {
        fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
        fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },
    table: {
      minWidth: 650,
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    button: {
      // color: blue[900],
      margin: 10,
    },
    input: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    title: {
      flex: '1 1 100%',
    },
    rowGrid: {
      '& .super-app.negative': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
        fontWeight: '600',
      },
    },
  }),
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>Não foi encontrado dados</div>
    </GridOverlay>
  );
}

interface ISelectMotoboy {
  id: number;
  nome: string;
}

export const MotoboysDay = () => {
  const [motoboyDia, setMotoboyDia] = useState([]);
  const [motoboys, setMotoboys] = useState<ISelectMotoboy[]>();
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idMotoboy, setIdMotoboy] = useState('');

  const [load, setLoad] = useState(true);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarInfo, setSnackBarInfo] = useState(false);
  const [msg, setMsg] = useState('');
  const [selection, setSelection] = React.useState<RowId[]>([]);
  const [carregandoBotao, setCarregandoBotao] = useState(false);
  const [showSelMotoboy, setShowSelMotoboy] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const token = localStorage.getItem('@PecaAquiDashboard:token');
  const { usuario } = useAuth();

  const history = useHistory();

  useEffect(() => {
    setLoad(true);

    api
      .get(
        `/pedidovendas/${
          usuario.idEstabelecimento
        }/motoboydia/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setMotoboyDia(response.data);
        setLoad(false);

        api
          .get(`/motoboys/${usuario.idEstabelecimento}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((responseMotoboy) => {
            setMotoboys(responseMotoboy.data);
            setLoad(false);
          })
          .catch((error) => {
            setLoad(false);
            setSnackBarErro(true);
            setMsg(error.responseMotoboy.data.message);
          });
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  }, [token, dataInicio, dataFim, usuario.idEstabelecimento]);

  const handleAtualizar = () => {
    setLoad(true);
    setMotoboyDia([]);
    api
      .get(
        `/pedidovendas/${
          usuario.idEstabelecimento
        }/motoboydia/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setMotoboyDia(response.data);
        setLoad(false);

        api
          .get(`/motoboys/${usuario.idEstabelecimento}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((responseMotoboy) => {
            setMotoboys(responseMotoboy.data);
            setLoad(false);
          })
          .catch((error) => {
            setLoad(false);
            setSnackBarErro(true);
            setMsg(error.responseMotoboy.data.message);
          });
      })
      .catch((error) => {
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleCloseSelMotoboy = (): void => {
    setShowSelMotoboy(false);
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarErro(false);
    setSnackBarSucesso(false);
    setSnackBarInfo(false);
  };

  const optionsSituacao = [
    { value: 'P', label: 'Pendente' },
    { value: 'A', label: 'Em Produção' },
    { value: 'E', label: 'Está Pronto' },
    { value: 'R', label: 'Rota de Entrega' },
    { value: 'F', label: 'Finalizado' },
    { value: 'C', label: 'Cancelado' },
  ];

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: '#',
      width: 80,
      cellClassName: (params: CellClassParams) =>
        clsx('super-app', {
          negative: Number(params?.getValue('idMotoboy')) === 0,
        }),
    },
    {
      field: 'data',
      headerName: 'Data Hora',
      width: 170,
      valueFormatter: (params: ValueFormatterParams) =>
        `${
          params.value?.toString() !== undefined
            ? `${moment(params.value?.toString()).utc().format('DD/MM/yyyy HH:mm:ss')}`
            : ''
        }`,
      cellClassName: (params: CellClassParams) =>
        clsx('super-app', {
          negative: Number(params?.getValue('idMotoboy')) === 0,
        }),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110,
      valueFormatter: (params: ValueFormatterParams) =>
        optionsSituacao &&
        optionsSituacao?.find((e) => e.value === params.value)?.label,
      cellClassName: (params: CellClassParams) =>
        clsx('super-app', {
          negative: Number(params?.getValue('idMotoboy')) === 0,
        }),
    },
    {
      field: 'nome',
      headerName: 'Motoboy',
      width: 150,
      cellClassName: (params: CellClassParams) =>
        clsx('super-app', {
          negative: Number(params?.getValue('idMotoboy')) === 0,
        }),
    },
    {
      field: 'observacao_entrega',
      headerName: 'Observação Entrega',
      width: 300,
      cellClassName: (params: CellClassParams) =>
        clsx('super-app', {
          negative: Number(params?.getValue('idMotoboy')) === 0,
        }),
    },
    {
      field: 'observacao',
      headerName: 'Observação',
      width: 250,
      cellClassName: (params: CellClassParams) =>
        clsx('super-app', {
          negative: Number(params?.getValue('idMotoboy')) === 0,
        }),
    },
  ];

  const handleDateChangeInicio = (date) => {
    setDataInicio(date);
  };

  const handleDateChangeFim = (date) => {
    setDataFim(date);
  };

  const sortModel = [
    {
      field: 'id',
      sort: 'desc' as SortDirection,
    },
  ];

  async function handleAtualizarPedido() {
    setCarregandoBotao(true);

    const atualizaPedidoVenda = {
      status: 'A',
      idMotoboy: idMotoboy === '' ? null : idMotoboy,
    };

    await selection.forEach(async (element) => {
      try {
        const response = await api.put(
          `/pedidovendas/detalhe/${element.toString()}`,
          atualizaPedidoVenda,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      } catch (error) {
        setCarregandoBotao(false);
        setMsg(error.response.data.message);
        setSnackBarErro(true);
      }

      setSelection([]);
      setIdMotoboy('');
      setSnackBarSucesso(true);
      setMsg('Pedido atualizado com sucesso!');
      handleAtualizar();
      handleCloseSelMotoboy();
      setCarregandoBotao(false);
    });
  }

  return (
    <>
      {/* TELA */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Motoboy Dia
          </Typography>
        </Grid>
      </Grid>
      <Toolbar
        className={clsx(classes.filtro)}
        style={{
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
          marginBottom: '10px',
          marginTop: '24px',
        }}
      >
        <Typography className={classes.title} variant="h6" component="div">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePickerPurple
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              label="Data Início"
              value={dataInicio}
              onChange={handleDateChangeInicio}
              size="small"
              style={{ width: '45%', marginRight: '10px' }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePickerPurple
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              label="Data Fim"
              value={dataFim}
              onChange={handleDateChangeFim}
              size="small"
              style={{ width: '45%', marginLeft: '10px' }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Typography>

        <Button
          variant="contained"
          startIcon={<HowToRegIcon />}
          style={{
            width: '30%',
            backgroundColor: '#81259D',
            color: '#fff',
          }}
          onClick={() => {
            if (selection.length > 0) {
              setShowSelMotoboy(true);
            } else {
              setSnackBarInfo(true);
              setMsg('Selecione ao menos 1 pedido!');
            }
          }}
        >
          Motoboy
        </Button>
        <Button
          variant="contained"
          startIcon={<ClearIcon />}
          disabled={carregandoBotao}
          style={{
            width: '30%',
            marginLeft: '10px',
          }}
          onClick={() => {
            if (selection.length > 0) {
              handleAtualizarPedido();
            } else {
              setSnackBarInfo(true);
              setMsg('Selecione ao menos 1 pedido!');
            }
          }}
        >
          Limpar
        </Button>
      </Toolbar>
      <div
        className={classes.rowGrid}
        style={{
          height: 500,
          width: '100%',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        }}
      >
        <DataGrid
          rows={motoboyDia}
          columns={columns}
          loading={load}
          sortModel={sortModel}
          checkboxSelection
          onSelectionChange={(newSelection) => {
            setSelection(newSelection.rowIds);
          }}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: LoadGrid,
          }}
        />
      </div>
      {/* FIM TELA */}

      {/* INICIO SELECIONAR MOTOBOY */}
      <Dialog
        fullScreen={fullScreen}
        open={showSelMotoboy}
        onClose={handleCloseSelMotoboy}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Selecionar Motoboy</DialogTitle>
        <FormGroup aria-label="position" row>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextFieldPurple
                  required
                  id="standard-select-currency"
                  select
                  fullWidth
                  label="Motoboy"
                  value={idMotoboy}
                  onChange={(e) => setIdMotoboy(e.target.value)}
                  helperText="Selecione um motoboy"
                  variant="standard"
                  size="small"
                >
                  {motoboys &&
                    motoboys.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                </TextFieldPurple>
              </Grid>
            </Grid>
          </DialogContent>
        </FormGroup>
        <DialogActions>
          <Button
            onClick={handleCloseSelMotoboy}
            variant="contained"
            color="secondary"
          >
            Fechar
          </Button>
          <div className={classes.wrapper}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={carregandoBotao}
              style={{
                backgroundColor: '#4caf50',
              }}
              onClick={handleAtualizarPedido}
            >
              Salvar
            </Button>
            {carregandoBotao && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
      {/* FIM SELECIONAR MOTOBOY */}

      {/* INICIO ALERT INFO */}
      <Snackbar
        open={snackBarInfo}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="info">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT INFO */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
