import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface Usuario {
  id: string;
  nome: string;
  login: string;
  idEstabelecimento: number;
  administrador: string;
  estabelecimento: {
    nome: string;
    imagem_url: string;
    endereco: string;
    celular: string;
  };
}

interface AuthState {
  token: string;
  usuario: Usuario;
}

interface SignInCredencials {
  login: string;
  senha: string;
}

interface AuthContextData {
  usuario: Usuario;
  signIn(credencials: SignInCredencials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const isAuthenticated = () =>
  localStorage.getItem('@PecaAquiDashboard:token') !== null;

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@PecaAquiDashboard:token');
    const usuario = localStorage.getItem('@PecaAquiDashboard:usuario');

    if (token && usuario) {
      return { token, usuario: JSON.parse(usuario) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ login, senha }) => {
    const response = await api.post('sessionsUser', {
      login,
      senha,
    });

    const { token, usuario } = response.data;

    localStorage.setItem('@PecaAquiDashboard:token', token);
    localStorage.setItem('@PecaAquiDashboard:usuario', JSON.stringify(usuario));

    setData({ token, usuario });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@PecaAquiDashboard:token');
    localStorage.removeItem('@PecaAquiDashboard:usuario');

    setData({} as AuthState);
  }, []);

  return (
    <>
      <AuthContext.Provider value={{ usuario: data.usuario, signIn, signOut }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
