import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Toolbar from '@material-ui/core/Toolbar';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  DataGrid,
  ColDef,
  GridOverlay,
  ValueFormatterParams,
  SortDirection,
} from '@material-ui/data-grid';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { LoadGrid } from '../../components/LoadGrid';
import { KeyboardDatePickerPurple } from '../../components/Global';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtro: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(1) },
    root: {
      flexDirection: 'column',
      '& .ant-empty-img-1': {
        fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
      },
      '& .ant-empty-img-2': {
        fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
      },
      '& .ant-empty-img-3': {
        fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
      },
      '& .ant-empty-img-4': {
        fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
      },
      '& .ant-empty-img-5': {
        fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
        fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },

    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    button: {
      // color: blue[900],
      // margin: 10,
    },
    input: {
      display: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    title: {
      flex: '1 1 100%',
    },
    table: {
      minWidth: 650,
    },
  }),
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>Não foi encontrado dados</div>
    </GridOverlay>
  );
}

interface IPedidoVenda {
  id: number;
  id_pedido_venda_externo: number;
  idCliente: number;
  idEndereco: number;
  idTipoPagamento: number;
  idMotoboy: number;
  nome: string;
  descricaoPagto: string;
  observacao: string;
  idCupomPromocional: number;
  idRecompensa: number;
  status: string;
  origem: string;
  data: Date;
  tipo_entrega: string;
  valor_entrega: string;
  valor_total: string;
  valor_total_itens: string;
  valor_desconto: string;
  valor_acrescimo: string;
  pr_desconto: string;
  observacao_entrega: string;
  impresso: string;
  cliente: {
    nome: string;
    telefone: string;
  };
  usuario: {
    nome: string;
  };
  tipoPagamento: {
    descricao: string;
  };
  cupomPromocional: {
    nome: string;
  };
  recompensa: {
    descricao: string;
  };
  endereco: {
    rua: string;
    numero: number;
    complemento: string;
    ponto_referencia: string;
    idBairro: string;
    bairro_nome: string;
    cidade: {
      nome: string;
      valor_entrega: string;
    };
    bairro: {
      nome: string;
      valor_entrega: string;
    };
  };
  pedidoVendaItem: Array<IPedidoVendaItem>;
  pedidoVendaPizza: [
    {
      nomePizza: string;
      id: number;
      idBorda: number;
      idCarrinho: number;
      idTamanho: number;
      observacao: string;
      nomeBorda: string;
      valor: string;
      valor_borda: string;
      tamanho: { id: number; descricao: string; qt_sabor: number };
      borda?: { id: number; descricao: string };
      pedidoVendaItem: IPedidoVendaItem[];
    },
  ];
}

interface IPedidoVendaItem {
  id: number;
  idProduto: number;
  observacao: string;
  quantidade: number;
  nomeProduto: string;
  valor: string;
  idPedidoVendaPizza: number;
  produto: IProdutoItem;
  pedidoVendaItemAdicional: Array<IPedidoVendaItemAdicional> | undefined;
}

interface IProdutoItem {
  nome: string;
  valor: string;
  codigoProduto: string;
}

interface IPedidoVendaItemAdicional {
  idProduto: number;
  idAdicional: number;
  valor: string;
  adicional: {
    descricao: string;
  };
  nomeAdicional: string;
}

export const CashFlow = () => {
  const { usuario } = useAuth();

  const [load, setLoad] = useState(true);
  const [pedidoVendas, setPedidoVendas] = useState<Array<IPedidoVenda>>([]);
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [snackBarSucesso, setSnackBarSucesso] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [valorTotal, setValorTotal] = useState(0);
  const [msg, setMsg] = useState('');

  const classes = useStyles();

  const token = localStorage.getItem('@PecaAquiDashboard:token');

  useEffect(() => {
    setLoad(true);
    api
      .get(
        `/pedidovendas/${
          usuario.idEstabelecimento
        }/fluxocaixa/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setPedidoVendas(response.data);

        api
          .get(
            `/pedidovendas/${
              usuario.idEstabelecimento
            }/fluxocaixatotal/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((responseTotal) => {
            setValorTotal(Number(responseTotal.data.valor_total));
            setLoad(false);
          })
          .catch((error) => {
            setLoad(false);
            setSnackBarErro(true);
            setMsg(error.responseTotal.data.message);
          });
      })
      .catch((error) => {
        setLoad(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  }, [token, dataInicio, dataFim, usuario.idEstabelecimento]);

  const handleAtualizar = () => {
    setLoad(true);
    setPedidoVendas([]);
    api
      .get(
        `/pedidovendas/${
          usuario.idEstabelecimento
        }/fluxocaixa/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setPedidoVendas(response.data);

        api
          .get(
            `/pedidovendas/${
              usuario.idEstabelecimento
            }/fluxocaixatotal/${moment(dataInicio).utc().toISOString()}&${moment(dataFim).utc().toISOString()}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((responseTotal) => {
            setValorTotal(Number(responseTotal.data.valor_total));
            setLoad(false);
          })
          .catch((error) => {
            setLoad(false);
            setSnackBarErro(true);
            setMsg(error.responseTotal.data.message);
          });
      })
      .catch((error) => {
        setLoad(false);
        setSnackBarErro(true);
        setMsg(error.response.data.message);
      });
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarSucesso(false);
    setSnackBarErro(false);
  };

  const columns: ColDef[] = [
    {
      field: 'id_pedido_venda_externo',
      headerName: '#',
      width: 70,
    },
    {
      field: 'nome',
      headerName: 'Cliente',
      width: 150,
    },
    {
      field: 'data',
      headerName: 'Data Hora',
      width: 170,
      valueFormatter: (params: ValueFormatterParams) =>
        `${
          params.value?.toString() !== undefined
            ? `${moment(params.value?.toString()).utc().format('DD/MM/yyyy HH:mm:ss')}`
            : ''
        }`,
    },
    { field: 'descricaoPagto', headerName: 'Pagamento', width: 150 },
    {
      field: 'valor_total',
      headerName: 'Valor',
      width: 84,
      valueFormatter: (params: ValueFormatterParams) =>
        `${params.value?.toString().replace('.', ',')}`,
    },
    {
      field: 'nomeUsuario',
      headerName: 'Usuário',
      width: 150,
    },
  ];

  const handleDateChangeInicio = (date) => {
    setDataInicio(date);
  };

  const handleDateChangeFim = (date) => {
    setDataFim(date);
  };

  const sortModel = [
    {
      field: 'id_pedido_venda_externo',
      sort: 'desc' as SortDirection,
    },
  ];

  function CustomFooter() {
    return (
      <div
        style={{
          backgroundColor: '#81259D',
          color: 'white',
          borderRadius: '2px',
          textAlign: 'center',
        }}
      >
        <Typography>
          <Box fontSize={19}>
            Total: R${valorTotal.toFixed(2).replace('.', ',')}
          </Box>
        </Typography>
      </div>
    );
  }

  return (
    <>
      {/* TELA */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component="h5" variant="h5" noWrap>
            Fluxo de Caixa
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="default"
            startIcon={<RefreshIcon />}
            onClick={handleAtualizar}
            style={{ marginRight: '2px' }}
          >
            ATUALIZAR
          </Button>
        </Grid>
      </Grid>
      <Toolbar
        className={clsx(classes.filtro)}
        style={{
          marginTop: '24px',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
          marginBottom: '10px',
        }}
      >
        <div className={classes.title} style={{ display: 'contents' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePickerPurple
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              label="Data Início"
              value={dataInicio}
              onChange={handleDateChangeInicio}
              size="small"
              style={{ width: '25%', marginRight: '10px' }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePickerPurple
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              label="Data Fim"
              value={dataFim}
              onChange={handleDateChangeFim}
              size="small"
              style={{ width: '25%', marginLeft: '10px' }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Toolbar>
      <div
        style={{
          height: 500,
          width: '100%',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
        }}
      >
        <DataGrid
          rows={pedidoVendas}
          columns={columns}
          loading={load}
          sortModel={sortModel}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
            footer: CustomFooter,
            loadingOverlay: LoadGrid,
          }}
        />
      </div>
      {/* FIM TELA */}

      {/* INICIO ALERT SUCCESS */}
      <Snackbar
        open={snackBarSucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT SUCCESS */}

      {/* INICIO ALERT ERRO */}
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
      {/* FIM ALERT ERRO */}
    </>
  );
};
