import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';

import { FaSpinner } from 'react-icons/fa';
import Layout from './containers/TheLayout';
import { SignIn } from './pages/SignIn';

import AppProvider from './hooks/index';

const loading = (
  <div className="pt-3 text-center">
    <FaSpinner />
  </div>
);

class App extends React.PureComponent {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <AppProvider>
            <Switch>
              <Route exact path="/signin" render={(props) => <SignIn />} />

              <Route path="/" render={(props) => <Layout {...props} />} />
            </Switch>
          </AppProvider>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
