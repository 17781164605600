import api from '../../services/api';

export const loadRewards = async (idEstabelecimento, token) => {
  try {
    const response = await api.get(`/recompensas/${idEstabelecimento}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const getByIdReward = async (idReward, token) => {
  try {
    const response = await api.get(`/recompensas/edit/${idReward}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const deleteReward = async (idReward, token) => {
  try {
    const response = await api.delete(`/recompensas/${idReward}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200 && response.status !== 204)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const newReward = async (data, idEstabelecimento, token) => {
  try {
    const response = await api.post(`/recompensas/${idEstabelecimento}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};

export const editReward = async (data, id, token) => {
  try {
    const response = await api.put(`/recompensas/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
    return response;
  } catch (error) {
    throw new Error('Algum erro ocorreu, tente novamente mais tarde!');
  }
};
