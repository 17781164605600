import React, { useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import {
  Snackbar,
  MenuItem,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Button,
  FormGroup,
  CircularProgress,
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Form } from '@unform/web';
import NumberFormat from 'react-number-format';

import { useStyles } from '../../styles';
import { TextFieldPurple } from '../../../../components/Global/index';
import { newReward } from '../../actions';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface ItemDialogProps {
  open: boolean;
  handleClose(salvar: boolean): void;
  idEstablishment: number;
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

const Add: React.FC<ItemDialogProps> = ({
  open,
  handleClose,
  idEstablishment,
}) => {
  const [descricao, setDescricao] = useState('');
  const [qtPontosAtingir, setQtPontosAtingir] = useState(0);
  const [tipoPremio, setTipoPremio] = useState('');
  const [valorValeCompra, setValorValeCompra] = useState('');
  const [percValeCompra, setPercValeCompra] = useState('');

  const [loadButton, setLoadButton] = useState(false);
  const [snackBarErro, setSnackBarErro] = useState(false);
  const [msg, setMsg] = useState('');

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const token = localStorage.getItem('@PecaAquiDashboard:token');

  const handleNew = async (): Promise<void> => {
    setLoadButton(true);
    const recompensa = {
      descricao,
      qt_pontos_atingir: qtPontosAtingir,
      tipo_premio: tipoPremio,
      valor_vale_compra: valorValeCompra,
      perc_vale_compra: percValeCompra,
    };
    try {
      await newReward(recompensa, idEstablishment, token);
      setLoadButton(false);
      handleClose(true);
    } catch (error) {
      setMsg('Algum erro ocorreu, tente novamente mais tarde!');
      setSnackBarErro(true);
    }
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarErro(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Form onSubmit={handleNew}>
          <DialogTitle className={classes.headerDialog}>
            Nova Recompensa
          </DialogTitle>

          <FormGroup aria-label="position" row>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    required
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    label="Descrição"
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    value={qtPontosAtingir}
                    onChange={(e) => setQtPontosAtingir(Number(e.target.value))}
                    label="Qtde. Pontos Atingir"
                    fullWidth
                    autoComplete="qtd_pontos_atingir_recompensa"
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextFieldPurple
                    required
                    id="standard-select-currency"
                    select
                    fullWidth
                    label="Tipos de prêmio"
                    value={tipoPremio}
                    onChange={(e) => setTipoPremio(e.target.value)}
                    helperText="Selecione o tipo do prêmio"
                    variant="standard"
                    size="small"
                  >
                    <MenuItem value="C">Vale Compra</MenuItem>
                    <MenuItem value="D">Vale Desconto</MenuItem>
                  </TextFieldPurple>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {tipoPremio === 'C' && (
                    <TextFieldPurple
                      fullWidth
                      size="small"
                      label="Vale Compra"
                      value={valorValeCompra}
                      onChange={(event) => {
                        setValorValeCompra(event.target.value);
                        setPercValeCompra('');
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                    />
                  )}
                  {tipoPremio === 'D' && (
                    <TextFieldPurple
                      fullWidth
                      size="small"
                      label="Vale Desconto"
                      value={percValeCompra}
                      onChange={(event) => {
                        setPercValeCompra(event.target.value);
                        setValorValeCompra('');
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </FormGroup>
          <DialogActions>
            <div>
              <small>
                Todos os campos com <strong>*</strong> são obrigatórios.
              </small>
            </div>
            <Button onClick={() => handleClose(false)} variant="contained">
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.buttonSuccess}
                disabled={loadButton}
              >
                Salvar
              </Button>
              {loadButton && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      <Snackbar
        open={snackBarErro}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Add;
